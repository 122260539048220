import {
    GET_DEALS,
    DEAL_ERROR,
    DELETE_DEAL,
    ADD_DEAL,
    GET_DEAL,
    GET_USERS_DEAL,
    DEALS_SUCCESS,
    UPDATE_DEAL,
    UPDATE_DEAL_ERROR,
  } from '../actions/types';
  
  const initialState = {
    deals: [],
    deal: null,
    loading: true,
    error: {}
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case GET_DEALS:
        return {
          ...state,
          deals: payload,
          loading: false
        };
      case GET_DEAL:
        return {
          ...state,
          deal: payload,
          loading: false
        };
        case GET_USERS_DEAL:
          return {
            ...state,
            users_deals: payload,
            loading: false
          };
      case ADD_DEAL:
        return {
          ...state,
          deals: [payload, ...state.deals],
          loading: false
        };
      case DELETE_DEAL:
        return {
          ...state,
          deals: state.deals.filter(deal => deal._id !== payload),
          loading: false
        };
      case DEAL_ERROR:
        return {
          ...state,
          error: payload,
          loading: false
        };
        case UPDATE_DEAL:
                return {
                    ...state,
                    ...payload,
                    loading: false,
                    updated_deal: payload
                };
                case UPDATE_DEAL_ERROR:
                return {
                    ...state,
                    error: payload,
                    loading: false
                };

            case DEALS_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: false,
                deals: payload
            };
      default:
        return state;
    }
  }
  