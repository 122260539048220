import React, { Fragment, useState, useEffect, useRef } from 'react';
import { addMessage, getMessages } from '../../actions/chat';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import userImage from '../../img/user.png';
import { format, register } from "timeago.js";
import fr from 'timeago.js/lib/lang/fr';
import it from 'timeago.js/lib/lang/it';
import { Button } from 'react-bootstrap';


const ChatBox = ({ addMessage, getMessages, auth: { user, theme }, currentChat, currentUser, currentUsername, setSendMessage,  receivedMessage, chat: { chats, messages }, sponsorship: { sponsorships } }) => {

    const { t } = useTranslation();
    register('fr', fr);
    register('it', it);

  //  const [userData, setUserData] = useState(user);
    const [allMessages, setAllMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");



 /* useEffect(() => {
    const userId = currentChat?.members?.find((id)=>id!==currentUser)
  const childWithId = sponsorships.find((child) => child.id === userId);

 if(childWithId) {
    setUserData(childWithId);
 }
  else {
    setUserData({"id": user.parent_id, "name": user.parent_username})
   }
  
    }, [sponsorships, currentUser, currentChat, user]);*/


  const [discussionName, setDiscussionName] = useState("");

  useEffect(() => {
    setDiscussionName(currentChat?.membersUsername?.filter(element => element !== user.username))
      }, [sponsorships, user, currentChat]);
  
    const handleChange = (event)=> {
      setNewMessage(event.target.value)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && newMessage !== "") {
          handleSend(e);
        }
      };
  
    // fetch messages
    useEffect(() => {
      if (currentChat !== null) {
        getMessages(currentChat._id);
        setAllMessages(messages);
    }
        }, [currentChat, getMessages, messages]);
  
        const scroll = useRef();
    // Always scroll to last Message
   /* useEffect(()=> {
        if (scroll.current)
      scroll.current.scrollIntoView({ behavior: "smooth" });

    },[allMessages])*/
  



    const ref =  useRef();
    useEffect(() => {
      if (allMessages.length) {
        ref.current.scrollIntoView({
          behavior: "smooth",
       //   block: "end",
        });
      }
    }, [allMessages.length]);
  
  
    // Send Message
    const handleSend = async(e)=> {
        if (newMessage !== "") {
      e.preventDefault()
      const message = {
        senderId : currentUser,
        senderUsername : currentUsername,
        senderAdminUsername : "",
        text: newMessage,
        chatId: currentChat._id,
    }
    const receiverId = sponsorships.find(child => child.id !== currentUser)?.id;
    // send message to socket server
    setSendMessage({...message, receiverId})
    // send message to database
      const { data } = addMessage(message);
      setAllMessages([...allMessages, data]);
      setNewMessage("");
    }
  }
  
  // Receive Message from parent component
  useEffect(()=> {
    console.log("Message Arrived: ", receivedMessage)
    if (receivedMessage !== null && receivedMessage.chatId === currentChat._id) {
        setAllMessages([...allMessages, receivedMessage]);
    }
  
  },[receivedMessage, allMessages, currentChat])
  


  return (
    <Fragment>
    <div className="ChatBox-container">
        {currentChat ? (
          <>
            {/* chat-header */}
            <div className={`chat-header border-bottom p-3 ${theme === 'light' ? 'chat-header-light' : 'chat-header-dark'}`}>
              <div className="follower" style={{
   display: "flex",
   alignItems: "center"
 }}>
                  <img
                     src={userImage}
                     alt="User_image"
                    className="followerImage rounded-circle"
                    style={{ width: "50px", height: "50px" }}
                  />
                  <div className="name ml-2" style={{ fontSize: "0.9rem" }}>
                    <span>
                      {discussionName}
                    </span>
                  </div>
              </div>
            </div>
            {/* chat-body */}
      <div className={`chat-body border p-3 ${theme === 'light' ? 'chat-body-light' : 'chat-body-dark'}`}>
              {allMessages.map((message, index) => (
                <>
                  <div ref={scroll}
                   key={index}
                   className={`message-bubble ${message?.senderId !== currentUser ? 'other-message' : 'own-message'}`}
                  >
                    <p className="message-text">{message?.text}</p>
                    <span className="message-time">{t('Language.Lang') === "Français" ? format(message?.createdAt, 'fr') :
                    t('Language.Lang') === "Italiano" ? format(message?.createdAt, 'it')
                    : format(message?.createdAt)
                    }</span>
                    <span className="sender-name">{message?.senderUsername}</span>
                  </div>
                </>
              ))}
              <div ref={ref} />
            </div>
            {/* chat-sender */}
            <div className={`chat-sender border-top p-3 d-flex align-items-center justify-content-between ${theme === 'light' ? 'chat-sender-light' : 'chat-sender-dark'}`}>
    <input
      type="text"
      placeholder={t('Messaging.Type_message') + "..."}
      value={newMessage}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      className="chat-input form-control me-2"
      style={{
        border: theme === "light" ? "1px solid #ccc" : "1px solid #444"
      }}
    />
    <Button className="send-button button primary" onClick={e => handleSend(e)} disabled={newMessage === "" ? true : false}>
    {t('Messaging.Send')}
    </Button>
            </div>
          </>
        ) : chats && chats.length === 0? (
            <span className="chatbox-empty-message">
              {t('Messaging.Empty_chat') + "..."}
            </span>
          )
           : (
          <span className="chatbox-empty-message">
            {t('Messaging.Tap_chat') + "..."}
          </span>
        )}
      </div>
    </Fragment>
    ) ;
}


ChatBox.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  chat: state.chat,
  sponsorship: state.sponsorship
});

export default connect(
  mapStateToProps,
  { addMessage, getMessages }
)(ChatBox);
