import React, { Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Navbar from './components/layout/Navbar';
import Footer from './components/layout/Footer';
import Notification from './components/layout/Notification';
import Login from './components/auth/Login';
//import Landing from './components/layout/Landing';
import Routes from './components/routing/Routes';
import SpinnerApi from './components/layout/SpinnerApi';



// Redux
import { Provider } from 'react-redux';
import { persistor, store } from './store';
import { loadUser } from './actions/auth';
import setAuthToken from './utils/setAuthToken';
import IdleTimerContainer from './components/layout/IdleTimerContainer';

import "./components/layout/FontawesomeIcons"

import { PersistGate } from 'redux-persist/integration/react';

import './App.css';
import './Custom.scss';
import GlobalTheme from './components/layout/GlobalTheme';


import ReactGA from 'react-ga';

// Stripe
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
//const stripePromise = loadStripe('pk_test_51A5FpeKTYy7HKZ7C1irdrovNckojiZYIOHmuNp4r2hH6sVIMkJ9I7CaJegHkmGDe6AkjSIVXMCieIA6WeWv7rUwl00FJSCKe0g');
const stripePromise = loadStripe('pk_live_51A5FpeKTYy7HKZ7CDpbCbds496Bv8OMiQ8QhDiC1hUA4E7EYcq4A5wByQ5fRMMLs3FkhdImCcZbl9PDeXEJg0Ewg00CPcBPqBl');

const App = () => {

  useEffect(() => {
    setAuthToken(localStorage.token);
    store.dispatch(loadUser());


    ReactGA.initialize('G-XRKJZWRZVK');
    // To Report Page View 
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);



  
  //document.body.style = 'background: #faf7f2'/*'background: #D3D3D3'*/; //#DCDCDC
  //console.log = () => {};



  return (   
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
      <GlobalTheme />
      <Elements stripe={stripePromise}>
      <Router>
        <Fragment>
          <Navbar />
          <Notification />
          <IdleTimerContainer />
          <Switch>
            <Route exact path="/" component={Login} />
            <Route component={Routes} />
          </Switch>
          <Footer />
          <SpinnerApi />
        </Fragment>
      </Router>
      </Elements>
      </PersistGate>
    </Provider>
  );
};

export default App;
