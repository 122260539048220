import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const TermsConditions = ({
  auth: { user }
}) => {

  const { t } = useTranslation();

  return (
    <Fragment>
    <section className="container">
    <h1 className='large text-primary'>{t('TermsConditions.Title')}</h1>
  <details>
    <summary>I. {t('TermsConditions.Subtitle1')}</summary>
    <p>
    <strong>1. </strong>
      {t('TermsConditions.Paragraph1')} < br/>
    <strong>2. </strong>
      {t('TermsConditions.Paragraph2')} < br/>
    <strong>3. </strong>
      {t('TermsConditions.Paragraph3')} < br/>
    <strong>4. </strong>
      {t('TermsConditions.Paragraph4')}
    </p>
  </details>

  <details>
    <summary>II. {t('TermsConditions.Subtitle2')}</summary>
    <p>
    <strong>1. </strong>
    {t('TermsConditions.Paragraph5')}<br />
    <strong>2. </strong>
    {t('TermsConditions.Paragraph6')}<br />
    {t('TermsConditions.Paragraph7')}
    </p>
  </details>

  <details>
    <summary>III. {t('TermsConditions.Subtitle3')}</summary>
    <p>
    {t('TermsConditions.Paragraph8')}<br />
    <div style={{marginLeft : "2em"}}>
    <strong>1. {t('TermsConditions.Paragraph9')}</strong>< br/>
      {t('TermsConditions.Paragraph10')} < br/>
      <strong>2. {t('TermsConditions.Paragraph11')}</strong>< br/>
      {t('TermsConditions.Paragraph12')} < br/>
    <strong>3. {t('TermsConditions.Paragraph13')}</strong>< br/>
      {t('TermsConditions.Paragraph14')} < br/>
    <strong>4. {t('TermsConditions.Paragraph15')}</strong>< br/>
      {t('TermsConditions.Paragraph16')} < br/>
    <strong>5. {t('TermsConditions.Paragraph17')}</strong>< br/>
      {t('TermsConditions.Paragraph18')}
      </div>
    </p>
  </details>

  <details>
    <summary>IV. {t('TermsConditions.Subtitle4')}</summary>
    <p>
    <strong>1. </strong>
      {t('TermsConditions.Paragraph19')} < br/>
    <strong>2. </strong>
      {t('TermsConditions.Paragraph20')} < br/>
    <strong>3. </strong>
      {t('TermsConditions.Paragraph21')} < br/>
    <strong>4. </strong>
      {t('TermsConditions.Paragraph22')} < br/>
      {t('TermsConditions.Paragraph23')} < br/>
    <strong>5. </strong>
      {t('TermsConditions.Paragraph24')} < br/>
    <strong>6. </strong>
      {t('TermsConditions.Paragraph25')} < br/>
    <strong>7. </strong>
      {t('TermsConditions.Paragraph26')} < br/>
    <strong>8. </strong>
      {t('TermsConditions.Paragraph27')} < br/>
    <strong>9. </strong>
      {t('TermsConditions.Paragraph28')} < br/>
    <strong>10. </strong>
      {t('TermsConditions.Paragraph29')} < br/>
    <strong>11. </strong>
      {t('TermsConditions.Paragraph30')} < br/>
    <strong>12. </strong>
      {t('TermsConditions.Paragraph31')}
    </p>
  </details>

  <details>
    <summary>V. {t('TermsConditions.Subtitle5')}</summary>
    <p>
    <strong>1. </strong>{t('TermsConditions.Paragraph32')}< br/>
    <strong>2. </strong>{t('TermsConditions.Paragraph33')}< br/>
    <strong>3. </strong>{t('TermsConditions.Paragraph34')}< br/>
    <strong>4. </strong>{t('TermsConditions.Paragraph35')}
    </p>
  </details>

  <details>
    <summary>VI. {t('TermsConditions.Subtitle6')}</summary>
    <p>
    <strong>1. {t('TermsConditions.Paragraph36')}</strong>< br/>
      {t('TermsConditions.Paragraph37')} < br/>
      <strong>2. {t('TermsConditions.Paragraph38')}</strong>< br/>
      {t('TermsConditions.Paragraph39')}
    </p>
  </details>

  <details>
    <summary>VII. {t('TermsConditions.Subtitle7')}</summary>
    <p>
    <strong>1. </strong>
      {t('TermsConditions.Paragraph40')} < br/>
    <strong>2. </strong>
      {t('TermsConditions.Paragraph41')} < br/>
    <strong>3. </strong>
      {t('TermsConditions.Paragraph42')} < br/>
    <strong>4. </strong>
      {t('TermsConditions.Paragraph43')}
    </p>
  </details>

  <details>
    <summary>VIII. {t('TermsConditions.Subtitle8')}</summary>
    <p>
    <strong>1. </strong>
      {t('TermsConditions.Paragraph44')} < br/>
    <strong>2. </strong>
      {t('TermsConditions.Paragraph45')} < br/>
    <strong>3. </strong>
      {t('TermsConditions.Paragraph46')} < br/>
    <strong>4. </strong>
      {t('TermsConditions.Paragraph47')}
    </p>
  </details>

  <details>
    <summary>IX. {t('TermsConditions.Subtitle9')}</summary>
    <p>
    <strong>1. </strong>
      {t('TermsConditions.Paragraph48')} < br/>
    <strong>2. </strong>
      {t('TermsConditions.Paragraph49')} < br/>
    <strong>3. </strong>
      {t('TermsConditions.Paragraph50')} < br/>
    <strong>4. </strong>
      {t('TermsConditions.Paragraph51')}
    </p>
  </details>

  <details>
    <summary>X. {t('TermsConditions.Subtitle10')}</summary>
    <p>
    <strong>1. </strong>
      {t('TermsConditions.Paragraph52')} < br/>
    <strong>2. </strong>
      {t('TermsConditions.Paragraph53')} < br/>
    <strong>3. </strong>
      {t('TermsConditions.Paragraph54')} < br/>
    <strong>4. </strong>
      {t('TermsConditions.Paragraph55')} < br/>
    <strong>5. </strong>
      {t('TermsConditions.Paragraph56')} < br/>
    <strong>6. </strong>
      {t('TermsConditions.Paragraph57')} < br/>
    <strong>7. </strong>
      {t('TermsConditions.Paragraph58')} < br/>
    <div style={{marginLeft : "2em"}}>
    <strong>a. </strong>
      {t('TermsConditions.Paragraph59')} < br/>
    <strong>b. </strong>
      {t('TermsConditions.Paragraph60')} < br/>
    <strong>c. </strong>
      {t('TermsConditions.Paragraph61')}
      </div>
    </p>
  </details>

  <details>
    <summary>XI. {t('TermsConditions.Subtitle11')}</summary>
    <p>
    <strong>1. </strong>
      {t('TermsConditions.Paragraph62')} < br/>
    <strong>2. </strong>
      {t('TermsConditions.Paragraph63')} < br/>
    <strong>3. </strong>
      {t('TermsConditions.Paragraph64')} < br/>
    <strong>4. </strong>
      {t('TermsConditions.Paragraph65')}
    </p>
  </details>

  <details>
    <summary>XII. {t('TermsConditions.Subtitle12')}</summary>
    <p>
    <strong>1. </strong>
      {t('TermsConditions.Paragraph66')} < br/>
    <strong>2. </strong>
      {t('TermsConditions.Paragraph67')} < br/>
    <div style={{marginLeft : "2em"}}>
    <strong>a. </strong>
      {t('TermsConditions.Paragraph68')} < br/>
    <strong>b. </strong>
      {t('TermsConditions.Paragraph69')} < br/>
    <strong>c. </strong>
      {t('TermsConditions.Paragraph70')} < br/>
    <strong>d. </strong>
      {t('TermsConditions.Paragraph71')}
      </div>
    <strong>3. </strong>
      {t('TermsConditions.Paragraph72')} < br/>
    <strong>4. </strong>
      {t('TermsConditions.Paragraph73')} < br/>
    <strong>5. </strong>
      {t('TermsConditions.Paragraph74')} < br/>
    </p>
  </details>

  <details>
    <summary>XIII. {t('TermsConditions.Subtitle13')}</summary>
    <p>
    <strong>1. </strong>
      {t('TermsConditions.Paragraph75')}
    </p>
  </details>

  <details>
    <summary>XIV. {t('TermsConditions.Subtitle14')}</summary>
    <p>
    <strong>1. </strong>
      {t('TermsConditions.Paragraph76')} < br/>
    <strong>2. </strong>
      {t('TermsConditions.Paragraph77')} < br/>
    <strong>3. </strong>
      {t('TermsConditions.Paragraph78')}
    </p>
  </details>

  <details>
  <summary>
    </summary>
  </details>
</section>
    </Fragment>
  );
};

TermsConditions.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(TermsConditions);
