import {
  ADD_CHAT,
  CHAT_ERROR,
  GET_CHATS,
  GET_CHATS_FOR_ADMIN,
  GET_ALL_CHATS,
  UPDATE_CHAT,
  UPDATE_CHAT_ERROR,
  FIND_CHAT,
  ADD_MESSAGE,
  MESSAGE_ERROR,
  GET_MESSAGES,
  GET_MESSAGES_FOR_ADMIN,
  } from '../actions/types';
  
  const initialState = {
    chats: [],
    chats_for_admin: [],
    chat: null,
    messages: [],
    messages_for_admin: [],
    message: null,
    loading: true,
    error: {}
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
  
    switch (type) {
      case ADD_CHAT:
        return {
          ...state,
          chats: [payload, ...state.chats],
          loading: false
        };
      case GET_CHATS:
        return {
          ...state,
          chats: payload,
          loading: false
        };
        case GET_CHATS_FOR_ADMIN:
          return {
            ...state,
            chats_for_admin: payload,
            loading: false
          };
        case GET_ALL_CHATS:
          return {
            ...state,
            all_chats: payload,
            loading: false
          };
          case UPDATE_CHAT:
                  return {
                      ...state,
                      ...payload,
                      loading: false,
                      updated_chat: payload
                  };
                  case UPDATE_CHAT_ERROR:
                  return {
                      ...state,
                      error: payload,
                      loading: false
                  };
        case FIND_CHAT:
          return {
            ...state,
            chat: payload,
            loading: false
          };
          case ADD_MESSAGE:
        return {
          ...state,
          messages: [payload, ...state.messages],
          loading: false
        };
      case GET_MESSAGES:
        return {
          ...state,
          messages: payload,
          loading: false
        };
        case GET_MESSAGES_FOR_ADMIN:
        return {
          ...state,
          messages_for_admin: payload,
          loading: false
        };
        case CHAT_ERROR:
        return {
          ...state,
          error: payload,
          loading: false
        };
        case MESSAGE_ERROR:
        return {
          ...state,
          error: payload,
          loading: false
        };
      default:
        return state;
    }
  }
  