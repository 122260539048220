import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

const Presentation = ({
  auth: { user }
}) => {

  const { t } = useTranslation();
  if (user && (user.status === "C" || user.status === "A")) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <Fragment>
      <section>
      <h1 className='large text-primary'>{t('Presentation.Title')}</h1>
  <details>
    <summary>I. {t('Presentation.Subtitle1')}</summary>
    <p>{t('Presentation.Paragraph1')}<br />{t('Presentation.Paragraph2')}<br />{t('Presentation.Paragraph3')}</p>
  </details>
  <details>
    <summary>II. {t('Presentation.Subtitle2')}</summary>
    <p>
    <strong>1. </strong>
      {t('Presentation.Paragraph4')} < br/>
    <strong>2. </strong>
      {t('Presentation.Paragraph5')} < br/>
    <strong>3. </strong>
      {t('Presentation.Paragraph6')} < br/>
    {t('Presentation.Paragraph7')}
    <div style={{marginLeft : "2em"}}>
    <strong>a. </strong>
      {t('Presentation.Paragraph8')} < br/>
    <strong>b. </strong>
      {t('Presentation.Paragraph9')} < br/>
    <strong>c. </strong>
      {t('Presentation.Paragraph10')}
    </div>
    </p>
  </details>
  <details>
    <summary>III. {t('Presentation.Subtitle3')}</summary>
    <p>
    <div>
    <strong>1. {t('Presentation.Paragraph11')}</strong><br />
    <div style={{marginLeft : "2em"}}>
      {t('Presentation.Paragraph12')} < br/></div>
    <strong>2. {t('Presentation.Paragraph13')}</strong><br />
    <div style={{marginLeft : "2em"}}>
      {t('Presentation.Paragraph14')} < br/></div>
    <strong>3. {t('Presentation.Paragraph15')}</strong>
    <div style={{marginLeft : "2em"}}>{t('Presentation.Paragraph16')} < br/>
      {t('Presentation.Paragraph17')}</div>
      </div>
    </p>
  </details>
  <details>
    <summary>IV. {t('Presentation.Subtitle4')}</summary>
    <p>
    {t('Presentation.Paragraph18')}<br />{t('Presentation.Paragraph19')}<br />
    {t('Presentation.Paragraph20')}<br />{t('Presentation.Paragraph21')}
    </p>
  </details>
  <details>
    <summary>V. {t('Presentation.Subtitle5')}</summary>
    <p>
    {t('Presentation.Paragraph22')}<br />
    {t('Presentation.Paragraph23')}<br />
    <strong>1. {t('Presentation.Paragraph24')}</strong> < br/>
    <div style={{marginLeft : "2em"}}>{t('Presentation.Paragraph25')} < br/></div>
    <strong>2. {t('Presentation.Paragraph26')}</strong> < br/>
    <div style={{marginLeft : "2em"}}>{t('Presentation.Paragraph27')} < br/></div>
    <strong>3. {t('Presentation.Paragraph28')}</strong> < br/>
    <div style={{marginLeft : "2em"}}>{t('Presentation.Paragraph29')} < br/> {t('Presentation.Paragraph30')} < br/></div>
    <strong>4. {t('Presentation.Paragraph31')}</strong> < br/>
    <div style={{marginLeft : "2em"}}>{t('Presentation.Paragraph32')} < br/> {t('Presentation.Paragraph33')} < br/></div>
    </p>
  </details>
  <details>
    <summary>VI. {t('Presentation.Subtitle6')}</summary>
    <p>
    {t('Presentation.Paragraph34')} < br/>
    <div style={{marginLeft : "2em"}}>
    <strong>1. </strong>
      {t('Presentation.Paragraph35')} < br/>
    <strong>2. </strong>
      {t('Presentation.Paragraph36')} < br/>
    <strong>3. </strong>
      {t('Presentation.Paragraph37')} < br/>
    <strong>4. </strong>
      {t('Presentation.Paragraph38')} < br/>
      <div style={{marginLeft : "2em"}}>
    <strong>a. </strong>
      {t('Presentation.Paragraph39')} < br/>
      </div></div>
      {t('Presentation.Paragraph40')}
    </p>
  </details>
  <details>
    <summary>VII. {t('Presentation.Subtitle7')}</summary>
    <p>
    {t('Presentation.Paragraph41')} < br/> {t('Presentation.Paragraph42')} < br/>
    <div style={{marginLeft : "2em"}}>
    <strong>1. </strong>
      {t('Presentation.Paragraph43')} < br/>
    <strong>2. </strong>
      {t('Presentation.Paragraph44')} < br/>
    <strong>3. </strong>
      {t('Presentation.Paragraph45')} < br/>
      {t('Presentation.Paragraph46')} < br/>
    <strong>4. </strong>
      {t('Presentation.Paragraph47')}
      </div>
    </p>
  </details>
  <details>
  <summary>
    </summary>
  </details>
</section>

    </Fragment>
  );
};

Presentation.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(Presentation);
