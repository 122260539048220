import {  axios } from '../utils/axios';
import {
  ADD_CHAT,
  CHAT_ERROR,
  GET_CHATS,
  GET_CHATS_FOR_ADMIN,
  GET_ALL_CHATS,
  UPDATE_CHAT,
  UPDATE_CHAT_ERROR,
  FIND_CHAT,
  ADD_MESSAGE,
  MESSAGE_ERROR,
  GET_MESSAGES,
  GET_MESSAGES_FOR_ADMIN,
  LOGOUT,
  SHOW_LOADING_API,
  HIDE_LOADING_API,
} from './types';



// Add chat
export const addChat = ({ senderId, receiverId, senderUsername, receiverUsername }) => async dispatch => {

  dispatch({
    type: SHOW_LOADING_API
  });

    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };

      const body = JSON.stringify({senderId, receiverId, senderUsername, receiverUsername});
  
    try {
      const res = await axios.post('/api/chats/add_chat', body, config);
  
      dispatch({
        type: ADD_CHAT,
        payload: res.data
      });
      dispatch({
        type: HIDE_LOADING_API
      });
  
    } catch (err) {
      dispatch({
        type: CHAT_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch({ type: LOGOUT });
    }
  };
  
// Get chats
export const getChats = ({ id }) => async dispatch => {

  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({id});
  try {
    const res = await axios.post('/api/chats/get_chats', body, config);

    dispatch({
      type: GET_CHATS,
      payload: res.data
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: CHAT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};


// Get all chats
export const getAllChats = ({ id }) => async dispatch => {

  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({id});
  try {
    const res = await axios.post('/api/chats/get_all_chats', body, config);

    dispatch({
      type: GET_ALL_CHATS,
      payload: res.data
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: CHAT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};


// Get chats for admin
export const getChatsForAdmin = ({ id }) => async dispatch => {

  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({id});
  try {
    const res = await axios.post('/api/chats/get_chats_for_admin', body, config);

    dispatch({
      type: GET_CHATS_FOR_ADMIN,
      payload: res.data
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: CHAT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};

// Find chat
export const findChat = ({ firstId, secondId }) => async dispatch => {

  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const body = JSON.stringify({firstId, secondId});
  try {
    const res = await axios.post('/api/chats/find_chat', body, config);

    dispatch({
      type: FIND_CHAT,
      payload: res.data
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: CHAT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};


  // Update chat
  export const updateChat = (id, state, userId, history) => async dispatch => {
   
    dispatch({
      type: SHOW_LOADING_API
    });
  
    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
    
      const body = JSON.stringify({ id : id, state });
      const body1 = JSON.stringify({ id : userId });
    
    try {
      const res = await axios.post('/api/chats/update_chat/', body, config);
      
      if (res) {
  
          const res2 = await axios.post('/api/chats/get_chats/', body1, config);
  
          dispatch({
            type: GET_CHATS,
            payload: res2.data
          });
      }
      dispatch({
        type: UPDATE_CHAT,
        payload: res.data
      });
      
      dispatch({
        type: HIDE_LOADING_API
      });
      history.go(0);
    } catch (err) {
      dispatch({
        type: UPDATE_CHAT_ERROR,
      //  payload: { msg: err.response.statusText, status: err.response.status }
      });
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch({ type: LOGOUT });
    }
  };

// Update chat admin
export const updateChatAdmin = (id, state, userId) => async dispatch => {
   
  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    const body = JSON.stringify({ id : id, state });
    const body1 = JSON.stringify({ id : userId });
  
  try {
    const res = await axios.post('/api/chats/update_chat/', body, config);
    
    if (res) {

        const res2 = await axios.post('/api/chats/get_all_chats/', body1, config);

        dispatch({
          type: GET_ALL_CHATS,
          payload: res2.data
        });
    }
    dispatch({
      type: UPDATE_CHAT,
      payload: res.data
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: UPDATE_CHAT_ERROR,
    //  payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};


// Add message
export const addMessage = ({ chatId, senderId, senderUsername, senderAdminUsername, text, fromAdmin}) => async dispatch => {
  
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };
  const body = JSON.stringify({chatId, senderId, senderUsername, senderAdminUsername, text, fromAdmin});

  try {
    const res = await axios.post('/api/messages/add_message', body, config);

    dispatch({
      type: ADD_MESSAGE,
      payload: res.data
    });

  } catch (err) {
    dispatch({
      type: MESSAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({ type: LOGOUT });
  }
};

// Get messages
export const getMessages = (chatId) => async dispatch => {

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({chatId});
  try {
    const res = await axios.post('/api/messages/get_messages', body, config);
    dispatch({
      type: GET_MESSAGES,
      payload: res.data.messages
    });
    dispatch({
      type: GET_CHATS,
      payload: res.data.chats
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: MESSAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({ type: LOGOUT });
  }
};


// Get messages for admin
export const getMessagesForAdmin = (chatId) => async dispatch => {

  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({chatId});
  try {
    const res = await axios.post('/api/messages/get_messages_for_admin', body, config);
    dispatch({
      type: GET_MESSAGES_FOR_ADMIN,
      payload: res.data.messages_for_admin
    });
    dispatch({
      type: GET_CHATS_FOR_ADMIN,
      payload: res.data.chats_for_admin
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: MESSAGE_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({ type: LOGOUT });
  }
};


  // Add advert message
  export const addAdvertMessage = ({ senderId, receiverId, advertId, category, title, senderUsername, senderAdminUsername, receiverUsername, text, history }) => async dispatch => {
    const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const body = JSON.stringify({ senderId, receiverId, advertId, category, title, senderUsername, senderAdminUsername, receiverUsername, text });
    try {
      const res = await axios.post('/api/chats/find_or_create_chat', body, config);
  
      dispatch({
        type: ADD_MESSAGE,
        payload: res.data
      });
      history.push('/messaging');
    } catch (err) {
      dispatch({
        type: MESSAGE_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
      dispatch({ type: LOGOUT });
    }
  };


    // Add admin message
    export const addAdminMessage = ({ receiverId, receiverUsername, receiverAdminUsername, email, category, subject, message, forAdmin, history }) => async dispatch => {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const body = JSON.stringify({ receiverId, receiverUsername, receiverAdminUsername, email, category, subject, message, forAdmin });
      try {
        const res = await axios.post('/api/chats/create_chat_by_admin', body, config);
    
        dispatch({
          type: ADD_MESSAGE,
          payload: res.data
        });
        history.push('/admin/messaging');
      } catch (err) {
        dispatch({
          type: MESSAGE_ERROR,
          payload: { msg: err.response.statusText, status: err.response.status }
        });
        dispatch({ type: LOGOUT });
      }
    };