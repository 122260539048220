import {
    GET_ADVERTISEMENTS,
    ADVERTISEMENT_ERROR,
    DELETE_ADVERTISEMENT,
    ADD_ADVERTISEMENT,
    GET_ADVERTISEMENT,
    GET_USERS_ADVERTISEMENT,
    ADVERTISEMENTS_SUCCESS,
    UPDATE_ADVERTISEMENT,
    UPDATE_ADVERTISEMENT_ERROR,
  } from '../actions/types';
  
  const initialState = {
    advertisements: [],
    advertisement: null,
    loading: true,
    error: {}
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case GET_ADVERTISEMENTS:
        return {
          ...state,
          advertisements: payload,
          loading: false
        };
      case GET_ADVERTISEMENT:
        return {
          ...state,
          advertisement: payload,
          loading: false
        };
      case GET_USERS_ADVERTISEMENT:
        return {
          ...state,
          users_advertisements: payload,
          loading: false
        };
      case ADD_ADVERTISEMENT:
        return {
          ...state,
          advertisements: [payload, ...state.advertisements],
          loading: false
        };
      case DELETE_ADVERTISEMENT:
        return {
          ...state,
          advertisements: state.advertisements.filter(advertisement => advertisement._id !== payload),
          loading: false
        };
      case ADVERTISEMENT_ERROR:
        return {
          ...state,
          error: payload,
          loading: false
        };
        case UPDATE_ADVERTISEMENT:
                return {
                    ...state,
                    ...payload,
                    loading: false,
                    updated_advertisement: payload
                };
                case UPDATE_ADVERTISEMENT_ERROR:
                return {
                    ...state,
                    error: payload,
                    loading: false
                };

            case ADVERTISEMENTS_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: false,
                advertisements: payload
            };
      default:
        return state;
    }
  }
  