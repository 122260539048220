import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AdvertisementsActions from './AdvertisementsActions';

const GcuAdvertisement = ({
  auth: { user }
}) => {

  const { t } = useTranslation();

  return (
    <Fragment>
    <AdvertisementsActions />
    <br/><br/>
         <section>
      <h1 className='large text-primary'>{t('GcuAdvertisement.Title')}</h1>
  <details>
    <summary>I. {t('GcuAdvertisement.Subtitle1')}</summary>
    <p>
    <strong>1. </strong>
      {t('GcuAdvertisement.Paragraph1')} < br/>
    <strong>2. </strong>
      {t('GcuAdvertisement.Paragraph2')} < br/>
    <strong>3. </strong>
      {t('GcuAdvertisement.Paragraph3')} < br/>
    <div style={{marginLeft : "2em"}}>
      a. {t('GcuAdvertisement.Paragraph4')} < br/>
      b. {t('GcuAdvertisement.Paragraph5')} < br/>
      c. {t('GcuAdvertisement.Paragraph6')} < br/>
    </div>
    <strong>4. </strong>
      {t('GcuAdvertisement.Paragraph7')} < br/>
    <strong>5. </strong>
      {t('GcuAdvertisement.Paragraph8')}
    </p>
  </details>

  <details>
    <summary>II. {t('GcuAdvertisement.Subtitle2')}</summary>
    <p>
    <strong>1. {t('GcuAdvertisement.Paragraph9')}</strong>< br/>
      {t('GcuAdvertisement.Paragraph10')} < br/>
      <strong>2. {t('GcuAdvertisement.Paragraph11')}</strong>< br/>
      {t('GcuAdvertisement.Paragraph12')} < br/>
    <strong>3. {t('GcuAdvertisement.Paragraph13')}</strong>< br/>
      {t('GcuAdvertisement.Paragraph14')}
    </p>
  </details>

  <details>
    <summary>III. {t('GcuAdvertisement.Subtitle3')}</summary>
    <p>
    <strong>1. </strong>
      {t('GcuAdvertisement.Paragraph15')} < br/>
    <strong>2. </strong>
      {t('GcuAdvertisement.Paragraph16')} < br/>
    <strong>3. </strong>
      {t('GcuAdvertisement.Paragraph17')} < br/>
    <strong>4. </strong>
      {t('GcuAdvertisement.Paragraph18')} < br/>
    <strong>5. </strong>
      {t('GcuAdvertisement.Paragraph19')}
    </p>
  </details>

  <details>
    <summary>IV. {t('GcuAdvertisement.Subtitle4')}</summary>
    <p>
    <strong>1. </strong>{t('GcuAdvertisement.Paragraph20')}<br />
    <div style={{marginLeft : "2em"}}>
      a. {t('GcuAdvertisement.Paragraph21')} < br/>
      b. {t('GcuAdvertisement.Paragraph22')}
    </div>
    <strong>2. </strong>
      {t('GcuAdvertisement.Paragraph23')} < br/>
    <strong>3. </strong>
      {t('GcuAdvertisement.Paragraph24')} < br/>
    <strong>4. </strong>
      {t('GcuAdvertisement.Paragraph25')} < br/>
    <strong>5. </strong>
      {t('GcuAdvertisement.Paragraph26')} < br/>
    <strong>6. </strong>
      {t('GcuAdvertisement.Paragraph27')} < br/>
    <strong>7. </strong>
      {t('GcuAdvertisement.Paragraph28')} < br/>
      {t('GcuAdvertisement.Paragraph29')}
    </p>
  </details>
  
  <details>
    <summary>V. {t('GcuAdvertisement.Subtitle5')}</summary>
    <p>
    <strong>1. </strong>{t('GcuAdvertisement.Paragraph30')}<br />
    <div style={{marginLeft : "2em"}}>
      a. {t('GcuAdvertisement.Paragraph31')} < br/>
      b. {t('GcuAdvertisement.Paragraph32')} < br/> {t('GcuAdvertisement.Paragraph33')} < br/>
      c. {t('GcuAdvertisement.Paragraph34')} < br/>
      d. {t('GcuAdvertisement.Paragraph35')} < br/>
      e. {t('GcuAdvertisement.Paragraph36')} < br/>
      f. {t('GcuAdvertisement.Paragraph37')}
    </div>
    <strong>2. </strong>{t('GcuAdvertisement.Paragraph38')}<br />
    <div style={{marginLeft : "2em"}}>
      a. {t('GcuAdvertisement.Paragraph39')} < br/>
      b. {t('GcuAdvertisement.Paragraph40')} < br/>
      c. {t('GcuAdvertisement.Paragraph41')} < br/>
      d. {t('GcuAdvertisement.Paragraph42')} < br/>
      e. {t('GcuAdvertisement.Paragraph43')}
    </div>
    <strong>3. </strong>{t('GcuAdvertisement.Paragraph44')} < br/>
    {t('GcuAdvertisement.Paragraph45')} < br/>
    <div style={{marginLeft : "2em"}}>
      a. {t('GcuAdvertisement.Paragraph46')} < br/>
      b. {t('GcuAdvertisement.Paragraph47')}
    </div>
    </p>
  </details>

  <details>
    <summary>VI. {t('GcuAdvertisement.Subtitle6')}</summary>
    <p>
    <strong>1. </strong>
      {t('GcuAdvertisement.Paragraph48')} < br/>
    <strong>2. </strong>
      {t('GcuAdvertisement.Paragraph49')} < br/>
    <strong>3. </strong>
      {t('GcuAdvertisement.Paragraph50')}
    </p>
  </details>

  <details>
    <summary>VII. {t('GcuAdvertisement.Subtitle7')}</summary>
    <p>
    {t('GcuAdvertisement.Paragraph51')} < br/>
    <strong>1. </strong>
      {t('GcuAdvertisement.Paragraph52')} < br/>
    <strong>2. </strong>
      {t('GcuAdvertisement.Paragraph53')} < br/>
    <strong>3. </strong>
      {t('GcuAdvertisement.Paragraph54')} < br/>
    <strong>4. </strong>
      {t('GcuAdvertisement.Paragraph55')}
    </p>
  </details>

  <details>
  <summary>
    </summary>
  </details>
</section>
    </Fragment>
  );
};

GcuAdvertisement.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(GcuAdvertisement);
