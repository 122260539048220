import {  axios } from '../utils/axios';
import { setAlert } from './alert';
import {
  GET_ADVERTISEMENTS,
  ADVERTISEMENT_ERROR,
  DELETE_ADVERTISEMENT,
  ADD_ADVERTISEMENT,
  GET_ADVERTISEMENT,
  GET_USERS_ADVERTISEMENT,
  ADVERTISEMENTS_SUCCESS,
  UPDATE_ADVERTISEMENT,
  UPDATE_ADVERTISEMENT_ERROR,
  LOGOUT,
  SHOW_LOADING_API,
  HIDE_LOADING_API,
} from './types';

// Get all advertisements
export const getAdvertisements = (id) => async dispatch => {
   
  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const body = JSON.stringify({ id : id });
  
  try {
    const res = await axios.post('/api/advertisements/get_all_advertisements/', body, config);
    dispatch({
      type: GET_ADVERTISEMENTS,
      payload: res.data.advertisements
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: ADVERTISEMENT_ERROR,
  //    payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};

// Delete advertisement
export const deleteAdvertisement = id => async dispatch => {
  try {
    await axios.delete(`/api/advertisements/${id}`);

    dispatch({
      type: DELETE_ADVERTISEMENT,
      payload: id
    });

    dispatch(setAlert('server_16_advertisement', 'success'));
  } catch (err) {
    dispatch({
      type: ADVERTISEMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add advertisement
export const addAdvertisement = ({ presenter, title, description, category, sub_category, selling_price, further_information, delivery_method, city, postal_code, country, imgToSendFirst, imgToSendSecond, imgToSendThird, history }) => async dispatch => {

  dispatch({
    type: SHOW_LOADING_API
  });
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = new FormData();
  body.append("presenter", presenter);
  body.append("title", title);
  body.append("description", description);
  body.append("selling_price", selling_price);
  body.append("further_information", further_information);
  body.append("category", category);
  body.append("sub_category", sub_category);
  body.append("delivery_method", delivery_method);
  body.append("city", city);
  body.append("postal_code", postal_code);
  body.append("country", country);
  body.append("imgFirst", imgToSendFirst);
  body.append("imgSecond", imgToSendSecond);
  body.append("imgThird", imgToSendThird);

  try {
    const res = await axios.post('/api/advertisements/add_advertisement', body, config);

    dispatch({
      type: ADD_ADVERTISEMENT,
      payload: res.data
    });

    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch(setAlert('server_18_advertisement', 'success'));
    history.push('/all-advertisements');
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: ADVERTISEMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });

    dispatch({
      type: HIDE_LOADING_API
    });
  }
};



  // Update advertisement
  export const updateAdvertisement = (id, state, userId, isAdmin) => async dispatch => {
   
    dispatch({
      type: SHOW_LOADING_API
    });
  
    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
    
      const body = JSON.stringify({ id : id, state });
      const body1 = JSON.stringify({ id : userId });
    
    try {
      const res = await axios.post('/api/advertisements/update_advertisement/', body, config);
      
      if (res) {
        var res2 = {data: {advertisements: []}};
        if (isAdmin) {
          const res2 = await axios.post('/api/advertisements/get_all_advertisements/', body1, config);
          dispatch({
            type: ADVERTISEMENTS_SUCCESS,
            payload: res2.data.advertisements
          });
        }
        else {
            const res2 = await axios.post('/api/advertisements/get_advertisements_for_clients/', body1, config);
            dispatch({
                type: GET_USERS_ADVERTISEMENT,
                payload: res2.data.advertisements
            });
        }
      }
      dispatch({
        type: UPDATE_ADVERTISEMENT,
        payload: res.data
      });
      dispatch({
        type: HIDE_LOADING_API
      });
    } catch (err) {
      dispatch({
        type: UPDATE_ADVERTISEMENT_ERROR,
      //  payload: { msg: err.response.statusText, status: err.response.status }
      });
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch({ type: LOGOUT });
    }
  };


  // Publish advertisements
  export const publishAdvertisements = (userId) => async dispatch => {
   
    dispatch({
      type: SHOW_LOADING_API
    });
  
    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
    
      const body = JSON.stringify({ id: userId });
    
    try {
      const res = await axios.post('/api/advertisements/publish_advertisements/', config);
      if (res) {
          const res2 = await axios.post('/api/advertisements/get_all_advertisements/', body, config);
  
          dispatch({
            type: ADVERTISEMENTS_SUCCESS,
            payload: res2.data.advertisements
          });
      }

      dispatch({
        type: HIDE_LOADING_API
      });
    } catch (err) {
      dispatch({
        type: ADVERTISEMENT_ERROR,
      //  payload: { msg: err.response.statusText, status: err.response.status }
      });
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch({ type: LOGOUT });
    }
  };

// Get advertisement
export const getAdvertisement = id => async dispatch => {
    dispatch({
        type: SHOW_LOADING_API
      });
  try {
    const res = await axios.get(`/api/advertisements/${id}`);

    dispatch({
      type: GET_ADVERTISEMENT,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: ADVERTISEMENT_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};


// Get advertisement for clients
export const getAdvertisementForClients = id => async dispatch => {
    dispatch({
        type: SHOW_LOADING_API
      });

    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const body = JSON.stringify({ id : id });
    try {
      const res = await axios.post('/api/advertisements/get_advertisements_for_clients/', body, config);
  
      dispatch({
        type: GET_USERS_ADVERTISEMENT,
        payload: res.data.advertisements
      });
      dispatch({
        type: HIDE_LOADING_API
      });
    } catch (err) {
      dispatch({
        type: ADVERTISEMENT_ERROR,
        payload: { msg: err.response.statusText, status: err.response.status }
      });
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch({ type: LOGOUT });
    }
  };