import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const AboutUs = ({
  auth: { user }
}) => {

  const { t } = useTranslation();

  return (
    <Fragment>
         <section className="container">
      <h1 className='large text-primary'>{t('AboutUs.Title')}</h1>
      <details>
    <summary>I. {t('AboutUs.Subtitle1')}</summary>
    <p>
    {t('AboutUs.Paragraph1')}<br />
    {t('AboutUs.Paragraph2')}<br />
    {t('AboutUs.Paragraph3')}<br />
    {t('AboutUs.Paragraph4')}<br />
    {t('AboutUs.Paragraph5')}
    </p>
  </details>

        <details>
    <summary>II. {t('AboutUs.Subtitle2')}</summary>
    <p>
    {t('AboutUs.Paragraph6')}<br />{t('AboutUs.Paragraph7')}
    </p>
  </details>

  <details>
    <summary>III. {t('AboutUs.Subtitle3')}</summary>
    <p>
    {t('AboutUs.Paragraph8')}<br />{t('AboutUs.Paragraph9')}
    </p>
  </details>

  <details>
    <summary>IV. {t('AboutUs.Subtitle4')}</summary>
    <p>
    {t('AboutUs.Paragraph10')}<br />{t('AboutUs.Paragraph11')}<br />
    {t('AboutUs.Paragraph12')}
    </p>
  </details>

  <details>
    <summary>V. {t('AboutUs.Subtitle5')}</summary>
    <p>
    {t('AboutUs.Paragraph13')}<br />
    <div style={{marginLeft : "2em"}}>
    <strong>1. {t('AboutUs.Paragraph14')}</strong>< br/>
      {t('AboutUs.Paragraph15')} < br/>
      <strong>2. {t('AboutUs.Paragraph16')}</strong>< br/>
      {t('AboutUs.Paragraph17')} < br/>
    <strong>3. {t('AboutUs.Paragraph18')}</strong>< br/>
      {t('AboutUs.Paragraph19')}<br />
      </div>
    {t('AboutUs.Paragraph20')}<br />
    {t('AboutUs.Paragraph21')}
    </p>
  </details>

  <details>
    <summary>VI. {t('AboutUs.Subtitle6')}</summary>
    <p>
    {t('AboutUs.Paragraph22')}<br />{t('AboutUs.Paragraph23')}<br />
    {t('AboutUs.Paragraph24')}<br />{t('AboutUs.Paragraph25')}<br />
    {t('AboutUs.Paragraph26')}<br />{t('AboutUs.Paragraph27')}<br />
    {t('AboutUs.Paragraph28')}
    </p>
  </details>

  <details>
    <summary>VII. {t('AboutUs.Subtitle7')}</summary>
    <p>
    {t('AboutUs.Paragraph29')}<br />{t('AboutUs.Paragraph30')}<br />
    {t('AboutUs.Paragraph31')}
    </p>
  </details>
  <details>
  <summary>
    </summary>
  </details>
</section>
    </Fragment>
  );
};

AboutUs.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(AboutUs);
