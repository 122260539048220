import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../layout/Spinner';
import SponsorshipActionsAdmin from './SponsorshipActionsAdmin';
import { getSponsorships } from '../../../actions/sponsorship';
import { getAdvertisements, updateAdvertisement, publishAdvertisements } from '../../../actions/advertisement';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import Pagination from '../../layout/Pagination';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


const AdvertisementAdmin = ({ getAdvertisements, getSponsorships, updateAdvertisement, publishAdvertisements, auth: { user, theme }, advertisement: { advertisements }, sponsorship: { loading } }) => {

 // const [newIndex, setNewIndex] = useState(0);
  const [modalsOpen, setModalIsOpen] = useState(false);
  const [modalsSecondOpen, setModalSecondIsOpen] = useState(false);
  const [modalsThirdOpen, setModalThirdIsOpen] = useState(false);
  const [modalsFourthOpen, setModalFourthIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalFirstButton, setModalFirstButton] = useState("");
  const [modalSecondButton, setModalSecondButton] = useState("");
  const [advertisementId, setAdvertisementId] = useState("");
  const [modalsDetailOpen, setModalDetailIsOpen] = useState(false);
  const [modalsPublishApprovedAdvertisementsOpen, setModalPublishApprovedAdvertisementsIsOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [advertisementsPerPage] = useState(10);

  const [criteria, setCriteria] = useState("All");
  const [searchCriteria, setSearchCriteria] = useState("Presenter");
  const [searchField, setSearchField] = useState("");


  const [advertisementImages, setAdvertisementImages] = useState([]);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isZooming, setIsZooming] = useState(false);
  const [zoomStyle, setZoomStyle] = useState({});


  useEffect(() => {
    getAdvertisements(user._id);
  }, [getAdvertisements, user._id]);

  useEffect(() => {
    getSponsorships(user._id);
  }, [getSponsorships, user._id]);



  const { t } = useTranslation();

  if ( user.role !== 0 ) {
    return <Redirect to="/dashboard" />;
  }


  var numberCounter = 0;

  const indexOfLastAdvertisement = currentPage * advertisementsPerPage;
  const indexOfFirstAdvertisement = indexOfLastAdvertisement - advertisementsPerPage;
  var currentAdvertisements = [];
  var sortedAdvertisements = [];
  if (advertisements && advertisements.length > 0) {
    let tempData = [...advertisements];
    for (var i = 0; i < tempData.length; i++) {

      if (i > 0 && (tempData[i - 1].date_creation !== tempData[i].date_creation)) {
        numberCounter = 0;
      }
      else {
        if (i > 0 && ((criteria === "All") || (criteria === "Pending" && tempData[i - 1].status === 1)
        || (criteria === "Approved" && tempData[i - 1].status === 2)
        || (criteria === "Online" && tempData[i - 1].status === 3)
        || (criteria === "Suspended" && tempData[i - 1].status === 4)
        || (criteria === "Deleted" && tempData[i - 1].status === 5))) {
            numberCounter ++;
        }
      }
      tempData[i].number = numberCounter + 1;


      if ((criteria === "All") || (criteria === "Pending" && tempData[i].status === 1)
        || (criteria === "Approved" && tempData[i].status === 2)
        || (criteria === "Online" && tempData[i].status === 3)
        || (criteria === "Suspended" && tempData[i].status === 4)
        || (criteria === "Deleted" && tempData[i].status === 5))
    {

      if (searchField) {
           if (searchCriteria === "Presenter") {
        if (tempData[i].presenter.toUpperCase().startsWith(searchField.toUpperCase())) {
            sortedAdvertisements.push(tempData[i]);
        }
      }
        else if (searchCriteria === "Title") {
          if (tempData[i].title.toUpperCase() === "") {
            if (t('Language.Lang') === "Français") {
              if ("Aucun".toUpperCase().startsWith(searchField.toUpperCase())) {
                sortedAdvertisements.push(tempData[i]);
            }
            }
            else if (t('Language.Lang') === "Italiano") {
              if ("Nessuna".toUpperCase().startsWith(searchField.toUpperCase())) {
                sortedAdvertisements.push(tempData[i]);
            }
            }
            else {
              if ("None".toUpperCase().startsWith(searchField.toUpperCase())) {
                sortedAdvertisements.push(tempData[i]);
            }
            }
          }
          else {
            if (tempData[i].title.toUpperCase().startsWith(searchField.toUpperCase())) {
              sortedAdvertisements.push(tempData[i]);
          }
          }
        }
      else if (searchCriteria === "D") {

        var dateDay = tempData[i].date_creation.substring(0, 10).split("-")
        var finalDateDay = dateDay[1] + "-" + dateDay[2] + "-" + dateDay[0]
          if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
            finalDateDay = dateDay[2] + "-" + dateDay[1] + "-" + dateDay[0];
          }

      if (finalDateDay.startsWith(searchField)) {
        sortedAdvertisements.push(tempData[i]);
      }
    }
    else if (searchCriteria === "M") {
      var dateMonth = tempData[i].date_creation.substring(0, 10).split("-")
      
        dateMonth = dateMonth[1] + "-" + dateMonth[0]
     

    if (dateMonth.startsWith(searchField)) {
        sortedAdvertisements.push(tempData[i]);
    }
  }
  else if (searchCriteria === "Y") {

    var dateYear = tempData[i].date_creation.substring(0, 10).split("-")[0]


  if (dateYear.startsWith(searchField)) {
    sortedAdvertisements.push(tempData[i]);
  }
}
      }
      else {
        sortedAdvertisements.push(tempData[i]);
      }
    }
  }

    currentAdvertisements = sortedAdvertisements.reverse().slice(indexOfFirstAdvertisement, indexOfLastAdvertisement);
  }


  const paginate = pageNum => setCurrentPage(pageNum);

  const nextPage = () => setCurrentPage(currentPage === Math.ceil(sortedAdvertisements.length / advertisementsPerPage) ? currentPage : currentPage + 1);

  const prevPage = () => setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage);

  const firstPage = () => setCurrentPage(1);

  const lastPage = () => setCurrentPage(Math.ceil(sortedAdvertisements.length / advertisementsPerPage));



  function handleCriteriaChange (changeEvent) {
    setCriteria(changeEvent.currentTarget.value);
    setCurrentPage(1);
}

function handleSearchCriteriaChange (changeEvent) {

  if ((changeEvent.currentTarget.value === "D" || changeEvent.currentTarget.value === "M" || changeEvent.currentTarget.value === "Y") && isNaN(searchField)) {
    setSearchField("");
  }
  else if ((changeEvent.currentTarget.value === "Presenter" || changeEvent.currentTarget.value === "Title") && !isNaN(searchField)) {
    setSearchField("");
  }

  setSearchCriteria(changeEvent.currentTarget.value);

  setCurrentPage(1);
}


      
const handleNext = () => {
  setCurrentIndex((prevIndex) => (prevIndex + 1) % advertisementImages.length);
};

const handlePrev = () => {
  setCurrentIndex((prevIndex) =>
    prevIndex === 0 ? advertisementImages.length - 1 : prevIndex - 1
  );
};

const handleZoom = (e) => {
  const rect = e.target.getBoundingClientRect();
  const x = ((e.clientX - rect.left) / rect.width) * 100;
  const y = ((e.clientY - rect.top) / rect.height) * 100;

  setZoomStyle({
    transformOrigin: `${x}% ${y}%`,
    transform: "scale(2)", // Ajustez la valeur du zoom ici
  });
  setIsZooming(true);
};

const resetZoom = () => {
  setIsZooming(false);
  setZoomStyle({});
};

      const showTable =
      
      
      <Fragment>
      
      
<h1 className='large text-primary'>{t('Advertisements.Advertisement_list')}</h1>


  
<div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>

<div className="scrollme"> 
<div className='form'>
<div className="form-group">
          <input
            type='text'
            placeholder={t('Advertisements.Search_by') + " " + (searchCriteria === "Presenter" ? t('Advertisements.Presenter').toLowerCase() : searchCriteria === "Title" ? t('Advertisements.Title').toLowerCase() : searchCriteria === "D" ? t('Advertisements.Day_text').toLowerCase() : searchCriteria === "M" ? t('Advertisements.Month_text').toLowerCase() : searchCriteria === "Y" ? t('Advertisements.Year_text').toLowerCase() : "")}
            name='searchField'
            value={searchField}
            onChange={e => findByCriteria(e.target.value)}
          />
        </div>
</div>


<div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
         <h4>
    {t('Advertisements.Search_by')}:
          </h4>
        <label style={{marginRight:"20px", marginTop: "10px"}}>

      </label>



      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Presenter" 
         id="presenter"
         className="custom-control-input"
                      checked={searchCriteria === 'Presenter'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="presenter">
          {t('Advertisements.Presenter')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Title" 
         id="title"
         className="custom-control-input"
                      checked={searchCriteria === 'Title'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="title">
          {t('Advertisements.Title')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="D" 
         id="day"
         className="custom-control-input"
                      checked={searchCriteria === 'D'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="day">
          {t('Advertisements.Day')}
      </label>
      </div>
      
      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="M" 
         id="month"
         className="custom-control-input"
                      checked={searchCriteria === 'M'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="month">
          {t('Advertisements.Month')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Y" 
         id="year"
         className="custom-control-input"
                      checked={searchCriteria === 'Y'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="year">
          {t('Advertisements.Year')}
      </label>
      </div>

    </div>

<div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
         <h4>
    {t('Advertisements.State')}:
          </h4>
        <label style={{marginRight:"20px", marginTop: "10px"}}>

      </label>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="All" 
         id="all"
         className="custom-control-input"
                      checked={criteria === 'All'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="all">
          {t('Advertisements.All')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Pending" 
         id="pending"
         className="custom-control-input"
                      checked={criteria === 'Pending'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="pending">
          {t('Advertisements.Pending')}
      </label>
      </div>

  
      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Approved" 
         id="approved"
         className="custom-control-input"
                      checked={criteria === 'Approved'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="approved">
          {t('Advertisements.Approved')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Online" 
         id="online"
         className="custom-control-input"
                      checked={criteria === 'Online'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="online">
          {t('Advertisements.Online')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Suspended" 
         id="suspended"
         className="custom-control-input"
                      checked={criteria === 'Suspended'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="suspended">
          {t('Advertisements.Suspended')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Deleted" 
         id="deleted"
         className="custom-control-input"
                      checked={criteria === 'Deleted'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="deleted">
          {t('Advertisements.Deleted')}
      </label>
      </div>

    </div>


<button className="btn btn-primary btn-block" onClick={() => onClickPublishApprovedAdvertisements()}>
      {t('Advertisements.Publish_advertisements')}
    </button>
<br/>
  
<table id="tbl" className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
  <thead  className={theme === "light" ? "thead-dark" : "thead-light"} >
  <tr>
      <th scope="col">{t('Advertisements.Number')}</th>
      <th scope="col">{t('Advertisements.Reference')}</th>
      <th scope="col">{t('Advertisements.Date')}</th>
      <th scope="col">{t('Advertisements.State')}</th>
      <th scope="col">{t('Advertisements.Presenter')}</th>
      <th scope="col">{t('Advertisements.Title')}</th>
      <th scope="col">{t('Advertisements.Amount')}</th>
      <th scope="col">{t('Advertisements.Actions')}</th>
    </tr>
  </thead>
  <tbody>


  {!advertisements  ? 
          <tr>
          <th colSpan={8}>{t('Advertisements.Calculating') + " ..."}</th>
        </tr>
      :
      sortedAdvertisements.length === 0 ?
      <tr>
          <th colSpan={8}>{t('Advertisements.No_advertisements')}</th>
        </tr>
       :
      currentAdvertisements.map(( get_advertisement, index ) => {
   // console.log("sponsorships"+JSON.stringify(sponsorship))

     return (
    <tr key={index}>
      <td>{sortedAdvertisements.length - index}</td>

      <td>{get_advertisement.new_id}</td>

    <td>{(t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? get_advertisement.date_creation.substring(0, 10).split("-")[2] + '/' + get_advertisement.date_creation.substring(0, 10).split("-")[1] + '/' + get_advertisement.date_creation.substring(0, 10).split("-")[0] : get_advertisement.date_creation.substring(0, 10).split("-")[1] + '/' + get_advertisement.date_creation.substring(0, 10).split("-")[2] + '/' + get_advertisement.date_creation.substring(0, 10).split("-")[0]}</td>
    <td>
    {(get_advertisement.status === 1 ? t('Advertisements.Pending') :
    get_advertisement.status === 2 ? t('Advertisements.Approved') :
    get_advertisement.status === 3 ? t('Advertisements.Online') :
    get_advertisement.status === 4 ? t('Advertisements.Suspended') :
      get_advertisement.status === 5 ? t('Advertisements.Deleted') : "" )}</td>
      <td>{get_advertisement.presenter}</td>
      <td>{get_advertisement.title}</td>
      <td>{get_advertisement.selling_price.toFixed(2) + "€"}</td>
      <td>
        {get_advertisement.status === 1 ? 
             <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
             <option value="">{t('Advertisements.Select_action')}</option>
             <option value= {'{"index": "1", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Details')}</option>
             <option value= {'{"index": "2", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Approve')}</option>
             <option value= {'{"index": "5", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Delete')}</option>
           </select>
           : get_advertisement.status === 2 ?
           <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
             <option value="">{t('Advertisements.Select_action')}</option>
             <option value= {'{"index": "1", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Details')}</option>
             <option value= {'{"index": "3", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Publish')}</option>
             <option value= {'{"index": "5", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Delete')}</option>
           </select>
           : get_advertisement.status === 3 ?
           <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
             <option value="">{t('Advertisements.Select_action')}</option>
             <option value= {'{"index": "1", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Details')}</option>
             <option value= {'{"index": "4", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Suspend')}</option>
             <option value= {'{"index": "5", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Delete')}</option>
           </select>
           : get_advertisement.status === 4 ?
           <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
             <option value="">{t('Advertisements.Select_action')}</option>
             <option value= {'{"index": "1", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Details')}</option>
             <option value= {'{"index": "3", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Publish')}</option>
             <option value= {'{"index": "5", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Delete')}</option>
           </select>
           : 
           <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
             <option value="">{t('Advertisements.Select_action')}</option>
             <option value= {'{"index": "1", "advertisement": {"_id": "' + get_advertisement._id + '", "new_id": "' + get_advertisement.new_id + '", "presenter": "' + get_advertisement.presenter + '", "title": "' + get_advertisement.title + '", "description": "' + get_advertisement.description + '", "selling_price": "' + get_advertisement.selling_price.toFixed(2) + '", "category": "' + get_advertisement.category + '", "sub_category": "' + get_advertisement.sub_category + '", "delivery_method": "' + get_advertisement.delivery_method + '", "further_information": "' + get_advertisement.further_information + '", "city": "' + get_advertisement.city + '", "postal_code": "' + get_advertisement.postal_code + '", "country": "' + get_advertisement.country + '", "attachment_name_first": "' + get_advertisement.attachment_name_first + '", "attachment_name_second": "' + get_advertisement.attachment_name_second + '", "attachment_name_third": "' + get_advertisement.attachment_name_third + '", "status": "' + get_advertisement.status + '", "date_creation": "' + get_advertisement.date_creation +'"}}'}>{t('Advertisements.Details')}</option>
           </select>
           }
      </td>
    </tr>
  );
})}

  </tbody>
</table>
</div>
</div>


<div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>
<div className="scrollme"> 
<table>
{ sortedAdvertisements.length > 0 ? <Pagination usersPerPage={advertisementsPerPage} totalUsers={advertisements && sortedAdvertisements.length > 0 ? sortedAdvertisements.length : 0} paginate={paginate} nextPage={nextPage} prevPage={prevPage} firstPage={firstPage} lastPage={lastPage} currentPage={currentPage} /> : null }
</table>
</div>
</div>
  </Fragment>;



const showModalApprove = 
<Modal className="my-modal" show={modalsOpen} onHide={() => setModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">

  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-primary" onClick={() => approveButtonClicked()}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setModalIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }

</ModalFooter>
</Modal>



const showModalPublish = 
<Modal className="my-modal" show={modalsSecondOpen} onHide={() => setModalSecondIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">

  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-primary" onClick={() => publishButtonClicked()}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setModalSecondIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }

</ModalFooter>
</Modal>





const showModalSuspend = 
<Modal className="my-modal" show={modalsThirdOpen} onHide={() => setModalThirdIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">

  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-primary" onClick={() => suspendButtonClicked()}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setModalThirdIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }

</ModalFooter>
</Modal>




const showModalDelete = 
<Modal className="my-modal" show={modalsFourthOpen} onHide={() => setModalFourthIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">

  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-danger" onClick={() => deleteButtonClicked()}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setModalFourthIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }

</ModalFooter>
</Modal>



const showModalDetail = 
<Modal className="my-modal" show={modalsDetailOpen} onHide={() => setModalDetailIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>  {advertisementImages.length > 0 ? (
  <div className="carousel-container">
    <div className="carousel-wrapper">
      <div className="carousel-slides">
      <div
                        className="carousel-item-wrapper"
                        onMouseMove={handleZoom}
                        onMouseLeave={resetZoom}
                      >
                        <img
                          src={advertisementImages[currentIndex]}
                          alt={`Image ${currentIndex + 1}`}
                          onClick={() => window.open(advertisementImages[currentIndex], "_blank")}
                          className={`carousel-image ${isZooming ? "zoomed" : ""}`}
                          style={zoomStyle}
                        />
                      </div>
      </div>
    <button
      className="carousel-control prev"
      onClick={handlePrev}
    >
      &#10094;
    </button>
    <button
      className="carousel-control next"
      onClick={handleNext}
    >
      &#10095;
    </button>
  </div>
  </div>
) : (
  <></>
)}
<br/>
{modalBody}<div/></ModalBody>
<ModalFooter as="footer">

  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-primary" onClick={() => setModalDetailIsOpen(false)}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setModalDetailIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }

</ModalFooter>
</Modal>


const showModalPublishApprovedAdvertisements = 
<Modal className="my-modal" show={modalsPublishApprovedAdvertisementsOpen} onHide={() => setModalPublishApprovedAdvertisementsIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">

  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-primary" onClick={() => publishAdvertisementsClicked()}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setModalPublishApprovedAdvertisementsIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }

</ModalFooter>
</Modal>

      const tables =
      
      <Fragment>
       <SponsorshipActionsAdmin />
      
     
    { advertisements !== null ? (
 
<div>

{showTable}

</div>
    ) : (
<Fragment>
</Fragment>
    )}
  </Fragment>;



function findByCriteria (name) {

  if ( searchCriteria === "Y") {
    setSearchField(name.replace(/\D/,''));
  }
  else if (searchCriteria === "D" || searchCriteria === "M") {
    setSearchField(name.replace(/(?!-)[^0-9.]/,''));
  }
  else {
    setSearchField(name);
  }


  if (searchCriteria === "D" && ((name.length === 2 && searchField.length === 1) || (name.length === 5 && searchField.length === 4))){
    setSearchField(name + "-")
}
else if (searchCriteria === "M" && ((name.length === 2 && searchField.length === 1))){
  setSearchField(name + "-")
}


  setCurrentPage(1);
}

function onClickApproveItem(newAdvertisement) {
    setModalTitle(t('Advertisements.Approve_sure')+ ': "' + JSON.parse(JSON.stringify(newAdvertisement.title))+'"?');
    setModalBody(
      t('Advertisements.Title')+" : " + (JSON.parse(JSON.stringify(newAdvertisement.title)) !== '' ?
      JSON.parse(JSON.stringify(newAdvertisement.title)) : t('Advertisements.None'))  +
      "\n"+t('Advertisements.Category')+" : " + (JSON.parse(JSON.stringify(newAdvertisement.category)) !== '' ?
      t('Advertisements.'+JSON.parse(JSON.stringify(newAdvertisement.category))) : t('Advertisements.None'))  +
     "\n"+t('Advertisements.Sub_category')+" : " + (JSON.parse(JSON.stringify(newAdvertisement.sub_category)) !== '' ?
     t('Advertisements.'+JSON.parse(JSON.stringify(newAdvertisement.sub_category))) : t('Advertisements.None'))  +
     "\n"+t('Advertisements.Selling_price')+" : " + JSON.parse(JSON.stringify(newAdvertisement.selling_price)) + "€"
    );
    setModalFirstButton(t('Advertisements.Approve'));
    setModalSecondButton("");
   setModalIsOpen(true);
}


function onClickPublishItem(newAdvertisement) {
    setModalTitle(t('Advertisements.Publish_sure')+ ': "' + JSON.parse(JSON.stringify(newAdvertisement.title))+'"?');
    setModalBody(
      t('Advertisements.Title')+" : " + (JSON.parse(JSON.stringify(newAdvertisement.title)) !== '' ?
      JSON.parse(JSON.stringify(newAdvertisement.title)) : t('Advertisements.None'))  +
      "\n"+t('Advertisements.Category')+" : " + (JSON.parse(JSON.stringify(newAdvertisement.category)) !== '' ?
      t('Advertisements.'+JSON.parse(JSON.stringify(newAdvertisement.category))) : t('Advertisements.None'))  +
     "\n"+t('Advertisements.Sub_category')+" : " + (JSON.parse(JSON.stringify(newAdvertisement.sub_category)) !== '' ?
     t('Advertisements.'+JSON.parse(JSON.stringify(newAdvertisement.sub_category))) : t('Advertisements.None'))  +
     "\n"+t('Advertisements.Selling_price')+" : " + JSON.parse(JSON.stringify(newAdvertisement.selling_price)) + "€"
    );
    setModalFirstButton(t('Advertisements.Publish'));
    setModalSecondButton("");
   setModalSecondIsOpen(true);
}

function onClickSuspendItem(newAdvertisement) {
  setModalTitle(t('Advertisements.Suspend_sure')+ ': "' + JSON.parse(JSON.stringify(newAdvertisement.title))+'"?');
  setModalBody(
    t('Advertisements.Title')+" : " + (JSON.parse(JSON.stringify(newAdvertisement.title)) !== '' ?
    JSON.parse(JSON.stringify(newAdvertisement.title)) : t('Advertisements.None'))  +
    "\n"+t('Advertisements.Category')+" : " + (JSON.parse(JSON.stringify(newAdvertisement.category)) !== '' ?
    t('Advertisements.'+JSON.parse(JSON.stringify(newAdvertisement.category))) : t('Advertisements.None'))  +
   "\n"+t('Advertisements.Sub_category')+" : " + (JSON.parse(JSON.stringify(newAdvertisement.sub_category)) !== '' ?
   t('Advertisements.'+ JSON.parse(JSON.stringify(newAdvertisement.sub_category))) : t('Advertisements.None'))  +
   "\n"+t('Advertisements.Selling_price')+" : " + JSON.parse(JSON.stringify(newAdvertisement.selling_price)) + "€"
  );
  setModalFirstButton(t('Advertisements.Suspend'));
  setModalSecondButton("");
 setModalThirdIsOpen(true);
}


function onClickDeleteItem(newAdvertisement) {
  setModalTitle(t('Advertisements.Delete_sure')+ ': "' + JSON.parse(JSON.stringify(newAdvertisement.title))+'"?');
  setModalBody(
    t('Advertisements.Title')+" : " + (JSON.parse(JSON.stringify(newAdvertisement.title)) !== '' ?
    JSON.parse(JSON.stringify(newAdvertisement.title)) : t('Advertisements.None'))  +
    "\n"+t('Advertisements.Category')+" : " + (JSON.parse(JSON.stringify(newAdvertisement.category)) !== '' ?
    t('Advertisements.'+JSON.parse(JSON.stringify(newAdvertisement.category))) : t('Advertisements.None'))  +
   "\n"+t('Advertisements.Sub_category')+" : " + (JSON.parse(JSON.stringify(newAdvertisement.sub_category)) !== '' ?
   t('Advertisements.'+JSON.parse(JSON.stringify(newAdvertisement.sub_category))) : t('Advertisements.None'))  +
   "\n"+t('Advertisements.Selling_price')+" : " + JSON.parse(JSON.stringify(newAdvertisement.selling_price)) + "€"
  );
  setModalFirstButton(t('Advertisements.Delete'));
  setModalSecondButton("");
 setModalFourthIsOpen(true);
}

function onClickPublishApprovedAdvertisements() {
  setModalTitle(t('Advertisements.Publish_approved_sure'));
  setModalBody(t('Advertisements.Approved_advertisements_accessible'));
  setModalFirstButton(t('Advertisements.Approve'));
  setModalSecondButton("");
 setModalPublishApprovedAdvertisementsIsOpen(true);
}


function showDetailsTable(advertisement) {
  var dateCreation = JSON.parse(JSON.stringify(advertisement.date_creation))
  var finalDateCreation = ""
  if (dateCreation !== "") {
    dateCreation = JSON.parse(JSON.stringify(advertisement.date_creation)).substring(0, 10).split("-")
    finalDateCreation = dateCreation[1] + '/' + dateCreation[2] + '/' + dateCreation[0];
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      finalDateCreation = dateCreation[2] + '/' + dateCreation[1] + '/' + dateCreation[0];
    }
  }
  setCurrentIndex(0);
  setAdvertisementImages([advertisement.attachment_name_first, advertisement.attachment_name_second, advertisement.attachment_name_third].filter(image => image !== ""))
  
  setModalTitle(JSON.parse(JSON.stringify(advertisement.title)));
  setModalBody(
    t('Advertisements.Reference')+" : " + JSON.parse(JSON.stringify(advertisement.new_id))  +
    "\n"+t('Advertisements.Presenter')+" : " + JSON.parse(JSON.stringify(advertisement.presenter))  +
    "\n"+t('Advertisements.Description')+" : " + JSON.parse(JSON.stringify(advertisement.description))  +
    "\n"+t('Advertisements.Further_information')+" : " + (JSON.parse(JSON.stringify(advertisement.further_information)) !== '' ?
    JSON.parse(JSON.stringify(advertisement.further_information)) : t('Advertisements.None')) +
    "\n"+t('Advertisements.Category')+" : " + (JSON.parse(JSON.stringify(advertisement.category)) !== '' ?
  t('Advertisements.'+JSON.parse(JSON.stringify(advertisement.category))) : t('Advertisements.None')) +
  "\n"+t('Advertisements.Sub_category')+" : " + (JSON.parse(JSON.stringify(advertisement.sub_category)) !== '' ?
  t('Advertisements.'+JSON.parse(JSON.stringify(advertisement.sub_category))) : t('Advertisements.None')) +
  "\n"+t('Advertisements.Selling_price')+" : " + (JSON.parse(JSON.stringify(advertisement.selling_price)) !== '' ?
  JSON.parse(JSON.stringify(advertisement.selling_price)) + "€" : t('Advertisements.None'))  +
  "\n"+t('Advertisements.Delivery_method')+" : " + t('Advertisements.'+JSON.parse(JSON.stringify(advertisement.delivery_method)))  +
  "\n"+t('Advertisements.City')+" : " + (JSON.parse(JSON.stringify(advertisement.city)) !== '' ?
  JSON.parse(JSON.stringify(advertisement.city)) : t('Advertisements.None')) +
  "\n"+t('Advertisements.Postal_code')+" : " + (JSON.parse(JSON.stringify(advertisement.postal_code)) !== '' ?
  JSON.parse(JSON.stringify(advertisement.postal_code)) : t('Advertisements.None')) +
  "\n"+t('Advertisements.Country')+" : " + t('Country.'+JSON.parse(JSON.stringify(advertisement.country)))  +
  "\n"+t('Advertisements.State')+" : " + (JSON.parse(JSON.stringify(advertisement.status)) === "1" ? t('Advertisements.Pending') :
  JSON.parse(JSON.stringify(advertisement.status)) === "2" ? t('Advertisements.Approved') :
  JSON.parse(JSON.stringify(advertisement.status)) === "3" ? t('Advertisements.Online') :
  JSON.parse(JSON.stringify(advertisement.status)) === "4" ? t('Advertisements.Suspended') :
  JSON.parse(JSON.stringify(advertisement.status)) === "5" ? t('Advertisements.Deleted') : t('Advertisements.None')) +
  (advertisement.date_creation !== "" ? ("\n"+t('Advertisements.Date')+" : "  + finalDateCreation) : "")
  );
    setModalFirstButton("");
    setModalSecondButton(t('Advertisements.Ok'));
   setModalDetailIsOpen(true);
  }





function approveButtonClicked() {
    updateAdvertisement(advertisementId, 2, user._id, true);
    setModalIsOpen(false);
}

function publishButtonClicked() {
    updateAdvertisement(advertisementId, 3, user._id, true);
    setModalSecondIsOpen(false);
}

function suspendButtonClicked() {
    updateAdvertisement(advertisementId, 4, user._id, true);
    setModalThirdIsOpen(false);
}

function deleteButtonClicked() {
    updateAdvertisement(advertisementId, 5, user._id, true);
    setModalFourthIsOpen(false);
}

function publishAdvertisementsClicked() {
  publishAdvertisements(user._id);
  setModalPublishApprovedAdvertisementsIsOpen(false);
}

const dropDownSelected = event => {
  if (JSON.parse(event).index === "1") {
    showDetailsTable(JSON.parse(event).advertisement);
  }
  else if (JSON.parse(event).index === "2") {
    setAdvertisementId(JSON.parse(event).advertisement._id)
    onClickApproveItem(JSON.parse(event).advertisement);
  }
  else if (JSON.parse(event).index === "3") {
    setAdvertisementId(JSON.parse(event).advertisement._id)
    onClickPublishItem(JSON.parse(event).advertisement);
  }
  else if (JSON.parse(event).index === "4") {
    setAdvertisementId(JSON.parse(event).advertisement._id)
    onClickSuspendItem(JSON.parse(event).advertisement);
  }
  else if (JSON.parse(event).index === "5") {
    setAdvertisementId(JSON.parse(event).advertisement._id)
    onClickDeleteItem(JSON.parse(event).advertisement);
  }
}




return loading && advertisements === null ? (
  <Spinner />
) : (
  
  <Fragment>

    {showModalApprove}
    {showModalPublish}
    {showModalSuspend}
    {showModalDelete}
    {showModalDetail}
    {showModalPublishApprovedAdvertisements}
    {tables}



</Fragment>
);
};


AdvertisementAdmin.propTypes = {
  getAdvertisements: PropTypes.func.isRequired,
  updateAdvertisement: PropTypes.func.isRequired,
  publishAdvertisements: PropTypes.func.isRequired,
  sponsorship: PropTypes.object.isRequired,
  advertisement: PropTypes.object.isRequired,
  getSponsorships: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  sponsorship: state.sponsorship,
  advertisement: state.advertisement,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getAdvertisements, updateAdvertisement, publishAdvertisements, getSponsorships }
)(AdvertisementAdmin);


