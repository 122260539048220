import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../layout/Spinner';
import SponsorshipActionsAdmin from './SponsorshipActionsAdmin';
import { getSponsorships } from '../../../actions/sponsorship';
import { getAllChats, updateChatAdmin } from '../../../actions/chat';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import Pagination from '../../layout/Pagination';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';


const MessageHistory = ({ getAllChats, getSponsorships, updateChatAdmin, auth: { user, theme }, chat: { all_chats }, sponsorship: { loading } }) => {

 // const [newIndex, setNewIndex] = useState(0);
  const [modalsDetailOpen, setModalDetailIsOpen] = useState(false);
  const [modalsDeleteOpen, setModalDeleteIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalFirstButton, setModalFirstButton] = useState("");
  const [modalSecondButton, setModalSecondButton] = useState("");
  const [selectedChat, setSelectedChat] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [allChatsPerPage] = useState(10);

  const [criteria, setCriteria] = useState("All");
  const [searchCriteria, setSearchCriteria] = useState("Username");
  const [searchField, setSearchField] = useState("");

  let history = useHistory();
  useEffect(() => {
    getAllChats(user._id);
  }, [getAllChats, user._id]);

  useEffect(() => {
    getSponsorships(user._id);
  }, [getSponsorships, user._id]);



  const { t } = useTranslation();

  if ( user.role !== 0 ) {
    return <Redirect to="/dashboard" />;
  }


  var numberCounter = 0;

  const indexOfLastAllChat = currentPage * allChatsPerPage;
  const indexOfFirstAllChat = indexOfLastAllChat - allChatsPerPage;
  var currentAllChats = [];
  var sortedAllChats = [];
  if (all_chats && all_chats.length > 0) {
    let tempData = [...all_chats];
    for (var i = 0; i < tempData.length; i++) {

      if (i > 0 && (tempData[i - 1].date_creation !== tempData[i].date_creation)) {
        numberCounter = 0;
      }
      else {
        if (i > 0 && ((criteria === "All") || (criteria === "Active" && tempData[i - 1].status === 1)
        || (criteria === "Deleted" && tempData[i - 1].status === 2)
        || (criteria === "Reported" && tempData[i - 1].status === 3))) {
            numberCounter ++;
        }
      }
      tempData[i].number = numberCounter + 1;


      if ((criteria === "All") || (criteria === "Active" && tempData[i].status === 1)
        || (criteria === "Deleted" && tempData[i].status === 2)
        || (criteria === "Reported" && tempData[i].status === 3))
    {

      if (searchField) {
           if (searchCriteria === "Username") {
        if (tempData[i].membersUsername.some(username => username.toUpperCase().startsWith(searchField.toUpperCase()))) {
            sortedAllChats.push(tempData[i]);
        }
      }
        else if (searchCriteria === "Title") {
          if (tempData[i].title.toUpperCase() === "") {
            if (t('Language.Lang') === "Français") {
              if ("Aucun".toUpperCase().startsWith(searchField.toUpperCase())) {
                sortedAllChats.push(tempData[i]);
            }
            }
            else if (t('Language.Lang') === "Italiano") {
              if ("Nessuna".toUpperCase().startsWith(searchField.toUpperCase())) {
                sortedAllChats.push(tempData[i]);
            }
            }
            else {
              if ("None".toUpperCase().startsWith(searchField.toUpperCase())) {
                sortedAllChats.push(tempData[i]);
            }
            }
          }
          else {
            if (tempData[i].title.toUpperCase().startsWith(searchField.toUpperCase())) {
              sortedAllChats.push(tempData[i]);
          }
          }
        }
      else if (searchCriteria === "D") {

        var dateDay = tempData[i].date_creation.substring(0, 10).split("-")
        var finalDateDay = dateDay[1] + "-" + dateDay[2] + "-" + dateDay[0]
          if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
            finalDateDay = dateDay[2] + "-" + dateDay[1] + "-" + dateDay[0];
          }

      if (finalDateDay.startsWith(searchField)) {
        sortedAllChats.push(tempData[i]);
      }
    }
    else if (searchCriteria === "M") {
      var dateMonth = tempData[i].date_creation.substring(0, 10).split("-")
      
        dateMonth = dateMonth[1] + "-" + dateMonth[0]
     

    if (dateMonth.startsWith(searchField)) {
      sortedAllChats.push(tempData[i]);
    }
  }
  else if (searchCriteria === "Y") {

    var dateYear = tempData[i].date_creation.substring(0, 10).split("-")[0]


  if (dateYear.startsWith(searchField)) {
    sortedAllChats.push(tempData[i]);
  }
}
      }
      else {
        sortedAllChats.push(tempData[i]);
      }
    }
  }

    currentAllChats = sortedAllChats.reverse().slice(indexOfFirstAllChat, indexOfLastAllChat);
  }


  const paginate = pageNum => setCurrentPage(pageNum);

  const nextPage = () => setCurrentPage(currentPage === Math.ceil(sortedAllChats.length / allChatsPerPage) ? currentPage : currentPage + 1);

  const prevPage = () => setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage);

  const firstPage = () => setCurrentPage(1);

  const lastPage = () => setCurrentPage(Math.ceil(sortedAllChats.length / allChatsPerPage));



  function handleCriteriaChange (changeEvent) {
    setCriteria(changeEvent.currentTarget.value);
    setCurrentPage(1);
}

function handleSearchCriteriaChange (changeEvent) {

  if ((changeEvent.currentTarget.value === "D" || changeEvent.currentTarget.value === "M" || changeEvent.currentTarget.value === "Y") && isNaN(searchField)) {
    setSearchField("");
  }
  else if ((changeEvent.currentTarget.value === "Username" || changeEvent.currentTarget.value === "Title") && !isNaN(searchField)) {
    setSearchField("");
  }

  setSearchCriteria(changeEvent.currentTarget.value);

  setCurrentPage(1);
}


      const showTable =
      
      
      <Fragment>
      
      
<h1 className='large text-primary'>{t('Messaging.Chat_list')}</h1>


  
<div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>

<div className="scrollme"> 
<div className='form'>
<div className="form-group">
          <input
            type='text'
            placeholder={t('Messaging.Search_by') + " " + (searchCriteria === "Username" ? t('Messaging.Username').toLowerCase() : searchCriteria === "Title" ? t('Messaging.Title').toLowerCase() : searchCriteria === "D" ? t('Messaging.Day_text').toLowerCase() : searchCriteria === "M" ? t('Messaging.Month_text').toLowerCase() : searchCriteria === "Y" ? t('Messaging.Year_text').toLowerCase() : "")}
            name='searchField'
            value={searchField}
            onChange={e => findByCriteria(e.target.value)}
          />
        </div>
</div>


<div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
         <h4>
    {t('Messaging.Search_by')}:
          </h4>
        <label style={{marginRight:"20px", marginTop: "10px"}}>

      </label>



      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Username" 
         id="username"
         className="custom-control-input"
                      checked={searchCriteria === 'Username'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="username">
          {t('Messaging.Username')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Title" 
         id="title"
         className="custom-control-input"
                      checked={searchCriteria === 'Title'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="title">
          {t('Messaging.Title')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="D" 
         id="day"
         className="custom-control-input"
                      checked={searchCriteria === 'D'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="day">
          {t('Messaging.Day')}
      </label>
      </div>
      
      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="M" 
         id="month"
         className="custom-control-input"
                      checked={searchCriteria === 'M'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="month">
          {t('Messaging.Month')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Y" 
         id="year"
         className="custom-control-input"
                      checked={searchCriteria === 'Y'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="year">
          {t('Messaging.Year')}
      </label>
      </div>

    </div>

<div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
         <h4>
    {t('Messaging.State')}:
          </h4>
        <label style={{marginRight:"20px", marginTop: "10px"}}>

      </label>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="All" 
         id="all"
         className="custom-control-input"
                      checked={criteria === 'All'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="all">
          {t('Messaging.All')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Deleted" 
         id="deleted"
         className="custom-control-input"
                      checked={criteria === 'Deleted'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="deleted">
          {t('Messaging.Deleted')}
      </label>
      </div>

  
      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Reported" 
         id="reported"
         className="custom-control-input"
                      checked={criteria === 'Reported'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="reported">
          {t('Messaging.Reported')}
      </label>
      </div>
    </div>
  
<table id="tbl" className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
  <thead  className={theme === "light" ? "thead-dark" : "thead-light"} >
  <tr>
      <th scope="col">{t('Messaging.Number')}</th>
      <th scope="col">{t('Messaging.Reference')}</th>
      <th scope="col">{t('Messaging.Date')}</th>
      <th scope="col">{t('Messaging.Subject')}</th>
      <th scope="col">{t('Messaging.State')}</th>
      <th scope="col">{t('Messaging.Members')}</th>
      <th scope="col">{t('Messaging.Actions')}</th>
    </tr>
  </thead>
  <tbody>


  {!all_chats  ? 
          <tr>
          <th colSpan={7}>{t('Messaging.Calculating') + " ..."}</th>
        </tr>
      :
      sortedAllChats.length === 0 ?
      <tr>
          <th colSpan={7}>{t('Messaging.No_chats')}</th>
        </tr>
       :
      currentAllChats.map(( get_all_chats, index ) => {
   // console.log("sponsorships"+JSON.stringify(sponsorship))

     return (
    <tr key={index}>
      <td>{sortedAllChats.length - index}</td>

      <td>{get_all_chats.new_id}</td>

    <td>{(t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? get_all_chats.date_creation.substring(0, 10).split("-")[2] + '/' + get_all_chats.date_creation.substring(0, 10).split("-")[1] + '/' + get_all_chats.date_creation.substring(0, 10).split("-")[0] : get_all_chats.date_creation.substring(0, 10).split("-")[1] + '/' + get_all_chats.date_creation.substring(0, 10).split("-")[2] + '/' + get_all_chats.date_creation.substring(0, 10).split("-")[0]}</td>
    <td>{get_all_chats.title}</td>
    <td>
    {(get_all_chats.status === 1 ? t('Messaging.Active') :
    get_all_chats.status === 2 ? t('Messaging.Deleted') :
    get_all_chats.status === 3 ? t('Messaging.Reported') : "" )}</td>
      <td>{get_all_chats.membersUsername.join(", ")}</td>
      <td>
          {get_all_chats.status === 1 ? 
             <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
             <option value="">{t('Messaging.Select_action')}</option>
             <option value= {'{"index": "1", "all_chat": {"_id": "' + get_all_chats._id + '", "new_id": "' + get_all_chats.new_id + '", "membersUsername": "' + get_all_chats.membersUsername.join(", ") + '", "title": "' + get_all_chats.title + '", "advert_id": "' + get_all_chats.advert_id + '", "category": "' + get_all_chats.category + '", "status": "' + get_all_chats.status + '", "date_creation": "' + get_all_chats.date_creation +'"}}'}>{t('Messaging.Details')}</option>
             <option value= {'{"index": "2", "all_chat": {"_id": "' + get_all_chats._id + '", "new_id": "' + get_all_chats.new_id + '", "membersUsername": "' + get_all_chats.membersUsername.join(", ") + '", "title": "' + get_all_chats.title + '", "advert_id": "' + get_all_chats.advert_id + '", "category": "' + get_all_chats.category + '", "status": "' + get_all_chats.status + '", "date_creation": "' + get_all_chats.date_creation +'"}}'}>{t('Messaging.Delete')}</option>
           </select>
           : 
           <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
             <option value="">{t('Messaging.Select_action')}</option>
             <option value= {'{"index": "1", "all_chat": {"_id": "' + get_all_chats._id + '", "new_id": "' + get_all_chats.new_id + '", "membersUsername": "' + get_all_chats.membersUsername.join(", ") + '", "title": "' + get_all_chats.title + '", "advert_id": "' + get_all_chats.advert_id + '", "category": "' + get_all_chats.category + '", "status": "' + get_all_chats.status + '", "date_creation": "' + get_all_chats.date_creation +'"}}'}>{t('Messaging.Details')}</option>
           </select>
           }
      </td>
    </tr>
  );
})}

  </tbody>
</table>
</div>
</div>


<div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>
<div className="scrollme"> 
<table>
{ sortedAllChats.length > 0 ? <Pagination usersPerPage={allChatsPerPage} totalUsers={all_chats && sortedAllChats.length > 0 ? sortedAllChats.length : 0} paginate={paginate} nextPage={nextPage} prevPage={prevPage} firstPage={firstPage} lastPage={lastPage} currentPage={currentPage} /> : null }
</table>
</div>
</div>
  </Fragment>;








const showModalDelete = 
<Modal className="my-modal" show={modalsDeleteOpen} onHide={() => setModalDeleteIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">

  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-danger" onClick={() => deleteButtonClicked()}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setModalDeleteIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }

</ModalFooter>
</Modal>



const showModalDetail = 
<Modal className="my-modal" show={modalsDetailOpen} onHide={() => setModalDetailIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}<div/></ModalBody>
<ModalFooter as="footer">

  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-primary" onClick={() => history.push(
      '/admin/message-details', { selectedChat: selectedChat }
    )}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setModalDetailIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }

</ModalFooter>
</Modal>




      const tables =
      
      <Fragment>
       <SponsorshipActionsAdmin />
      
     
    { all_chats !== null ? (
 
<div>

{showTable}

</div>
    ) : (
<Fragment>
</Fragment>
    )}
  </Fragment>;



function findByCriteria (name) {

  if ( searchCriteria === "Y") {
    setSearchField(name.replace(/\D/,''));
  }
  else if (searchCriteria === "D" || searchCriteria === "M") {
    setSearchField(name.replace(/(?!-)[^0-9.]/,''));
  }
  else {
    setSearchField(name);
  }


  if (searchCriteria === "D" && ((name.length === 2 && searchField.length === 1) || (name.length === 5 && searchField.length === 4))){
    setSearchField(name + "-")
}
else if (searchCriteria === "M" && ((name.length === 2 && searchField.length === 1))){
  setSearchField(name + "-")
}


  setCurrentPage(1);
}



function onClickDeleteItem(newAllChat) {
  setModalTitle(t('Messaging.Delete_sure')+ ': "' + JSON.parse(JSON.stringify(newAllChat.membersUsername))+'"?');
  setModalBody(
    t('Messaging.Subject')+" : " + (JSON.parse(JSON.stringify(newAllChat.title)) !== '' ?
    JSON.parse(JSON.stringify(newAllChat.title)) : t('Messaging.None'))  +
    "\n"+t('Messaging.Category')+" : " + (JSON.parse(JSON.stringify(newAllChat.category)) !== '' ?
    t('Messaging.'+JSON.parse(JSON.stringify(newAllChat.category))) : t('Messaging.None'))  +
   "\n"+t('Messaging.Advert_id')+" : " + (JSON.parse(JSON.stringify(newAllChat.advert_id)) !== '' ?
   t('Messaging.'+JSON.parse(JSON.stringify(newAllChat.advert_id))) : t('Messaging.None'))
  );
  setModalFirstButton(t('Messaging.Delete'));
  setModalSecondButton("");
  setModalDeleteIsOpen(true);
}



function showDetailsTable(allChat) {
  setSelectedChat(allChat)
  var dateCreation = JSON.parse(JSON.stringify(allChat.date_creation))
  var finalDateCreation = ""
  if (dateCreation !== "") {
    dateCreation = JSON.parse(JSON.stringify(allChat.date_creation)).substring(0, 10).split("-")
    finalDateCreation = dateCreation[1] + '/' + dateCreation[2] + '/' + dateCreation[0];
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      finalDateCreation = dateCreation[2] + '/' + dateCreation[1] + '/' + dateCreation[0];
    }
  }
  setModalTitle(t('Messaging.Discussion_members')+" : " + JSON.parse(JSON.stringify(allChat.membersUsername)));
  setModalBody(
    t('Messaging.Reference')+" : " + JSON.parse(JSON.stringify(allChat.new_id))  +
    "\n"+t('Messaging.Subject')+" : " + JSON.parse(JSON.stringify(allChat.title))  +
    "\n"+t('Messaging.Advert_id')+" : " + (JSON.parse(JSON.stringify(allChat.advert_id)) !== '' ?
    JSON.parse(JSON.stringify(allChat.advert_id)) : t('Messaging.None')) +
    "\n"+t('Messaging.Category')+" : " + (JSON.parse(JSON.stringify(allChat.category)) !== '' ?
  t('Messaging.'+JSON.parse(JSON.stringify(allChat.category))) : t('Messaging.None')) +
  "\n"+t('Messaging.State')+" : " + (JSON.parse(JSON.stringify(allChat.status)) === "1" ? t('Messaging.Active') :
  JSON.parse(JSON.stringify(allChat.status)) === "2" ? t('Messaging.Deleted') :
  JSON.parse(JSON.stringify(allChat.status)) === "3" ? t('Messaging.Reported'): t('Messaging.None')) +
  (allChat.date_creation !== "" ? ("\n"+t('Messaging.Date')+" : "  + finalDateCreation) : "")
  );
  setModalFirstButton(t('Messaging.See_discussion'));
  setModalSecondButton("");
   setModalDetailIsOpen(true);
  }





function deleteButtonClicked() {
    updateChatAdmin(selectedChat._id, 2, user._id);
    setModalDeleteIsOpen(false);
}


const dropDownSelected = event => {
  if (JSON.parse(event).index === "1") {
    showDetailsTable(JSON.parse(event).all_chat);
  }
  else if (JSON.parse(event).index === "2") {
    setSelectedChat(JSON.parse(event).all_chat)
    onClickDeleteItem(JSON.parse(event).all_chat);
  }
}




return loading && all_chats === null ? (
  <Spinner />
) : (
  
  <Fragment>

    {showModalDelete}
    {showModalDetail}
    {tables}



</Fragment>
);
};


MessageHistory.propTypes = {
  getAllChats: PropTypes.func.isRequired,
  updateChatAdmin: PropTypes.func.isRequired,
  sponsorship: PropTypes.object.isRequired,
  chat: PropTypes.object.isRequired,
  getSponsorships: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  sponsorship: state.sponsorship,
  chat: state.chat,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getAllChats, updateChatAdmin, getSponsorships }
)(MessageHistory);


