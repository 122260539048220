import React, { Fragment, useEffect, useState } from 'react';
import { Container, Row, Col, Card, ListGroup, ListGroupItem, Button, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../layout/Spinner';
import SponsorshipActionsAdmin from './SponsorshipActionsAdmin';
import { getSponsorships } from '../../../actions/sponsorship';
import { addMessage, getMessages } from '../../../actions/chat';
import { format, register } from "timeago.js";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import Pagination from '../../layout/Pagination';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useLocation } from "react-router-dom";

const MessageDetails = ({ addMessage, getMessages, auth: { user, theme }, currentUser, setSendMessage,  receivedMessage, chat: { messages }, sponsorship: { sponsorships } }) => {

    const { t } = useTranslation();
    const location = useLocation();
    const [selectedChat, setSelectedChat] = useState(location.state && location.state.selectedChat ? location.state.selectedChat : "");
  // État pour la pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [messagesPerPage] = useState(10);

  const indexOfLastMessage = currentPage * messagesPerPage;
  const indexOfFirstMessage = indexOfLastMessage - messagesPerPage;
  const currentMessages = messages.slice(indexOfFirstMessage, indexOfLastMessage);

  const paginate = pageNum => setCurrentPage(pageNum);

  const nextPage = () => setCurrentPage(currentPage === Math.ceil(messages.length / messagesPerPage) ? currentPage : currentPage + 1);

  const prevPage = () => setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage);

  const firstPage = () => setCurrentPage(1);

  const lastPage = () => setCurrentPage(Math.ceil(messages.length / messagesPerPage));

  // Fonction pour gérer le bouton "Retour"
  const handleGoBack = () => {history.push('/admin/message-history')}

  let history = useHistory();
    // fetch messages
    useEffect(() => {
        if (selectedChat !== "") {
          getMessages(selectedChat._id);
      }
          }, [selectedChat, getMessages]);



          const lightThemeStyles = {
            cardBg: "#ffffff",
            secondaryBg: "#f8f9fa",
            textPrimary: "#000000",
            textMuted: "#6c757d",
          };
        
          const darkThemeStyles = {
            cardBg: "#444444",
            secondaryBg: "#343a40",
            textPrimary: "#ffffff",
            textMuted: "#aaaaaa",
          };
        
          const themeStyles = theme === "light" ? lightThemeStyles : darkThemeStyles;

          
  return (
    <Fragment>
      <Row>
        <Col>
          {/* Bouton Retour */}
          <Button
            variant="outline-secondary"
            onClick={handleGoBack}
            className="mb-3"
        style={{
            backgroundColor: themeStyles.secondaryBg,
            color: themeStyles.textPrimary,
          }}
          >
            {t('Messaging.Back')}
          </Button>

          <Card>
      <Card.Header
        style={{
          backgroundColor: themeStyles.secondaryBg,
          color: themeStyles.textPrimary,
          padding: "1rem",
          borderBottom: `1px solid ${theme === "light" ? "#ddd" : "#555"}`,
        }}
      >
        {t("Messaging.Discussion_members") + " : " + selectedChat.membersUsername}
      </Card.Header>

      <Card.Body
        style={{
          backgroundColor: themeStyles.cardBg,
          color: themeStyles.textPrimary,
          padding: "1rem",
        }}
      >
        <ListGroup>
          {currentMessages.map((message, index) => (
            <ListGroupItem
              key={index}
              style={{
                backgroundColor: themeStyles.secondaryBg,
                color: themeStyles.textPrimary,
                border: `1px solid ${theme === "light" ? "#ddd" : "#555"}`,
                marginBottom: "0.5rem",
                padding: "0.75rem",
                borderRadius: "0.25rem",
              }}
            >
              <div>
                <strong>{message.senderUsername + (message.senderAdminUsername !== "" ? (" (" + message.senderAdminUsername + ")") : "")}:</strong> {message.text}
              </div>
              <div
                style={{
                  color: themeStyles.textMuted,
                  fontSize: "0.85rem",
                }}
              >
                {t("Language.Lang") === "Français"
                  ? format(message?.createdAt, "fr")
                  : t("Language.Lang") === "Italiano"
                  ? format(message?.createdAt, "it")
                  : format(message?.createdAt)}
              </div>
            </ListGroupItem>
          ))}
        </ListGroup>

        {/* Pagination */}
        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            {messages.length > 0 ? (
              <Pagination
                usersPerPage={messagesPerPage}
                totalUsers={messages.length}
                paginate={paginate}
                nextPage={nextPage}
                prevPage={prevPage}
                firstPage={firstPage}
                lastPage={lastPage}
                currentPage={currentPage}
              />
            ) : null}
          </div>
        </div>
      </Card.Body>
    </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

MessageDetails.propTypes = {
    auth: PropTypes.object.isRequired
  };
  
  const mapStateToProps = state => ({
    auth: state.auth,
    chat: state.chat,
    sponsorship: state.sponsorship
  });
  
  export default connect(
    mapStateToProps,
    { addMessage, getMessages }
  )(MessageDetails);