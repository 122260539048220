import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

const QuestionsAnswers = ({
  auth: { user }
}) => {

  const { t } = useTranslation();

  return (
    <Fragment>
    <section>
    <h1 className='large text-primary'>{t('QuestionsAnswers.Title')}</h1>

  <details>
    <summary>I. {t('QuestionsAnswers.Subtitle1')}</summary>
    <p>
    {t('QuestionsAnswers.Paragraph1')}<br />
    {t('QuestionsAnswers.Paragraph2')}
    </p>
  </details>

  <details>
    <summary>II. {t('QuestionsAnswers.Subtitle2')}</summary>
    <p>
    {t('QuestionsAnswers.Paragraph3')}<br />
    {t('QuestionsAnswers.Paragraph4')}
    </p>
  </details>

  <details>
    <summary>III. {t('QuestionsAnswers.Subtitle3')}</summary>
    <p>
    {t('QuestionsAnswers.Paragraph5')}
    </p>
  </details>

  <details>
    <summary>IV. {t('QuestionsAnswers.Subtitle4')}</summary>
    <p>
    {t('QuestionsAnswers.Paragraph6')}<br />
    {t('QuestionsAnswers.Paragraph7')}
    </p>
  </details>

  <details>
    <summary>V. {t('QuestionsAnswers.Subtitle5')}</summary>
    <p>
    {t('QuestionsAnswers.Paragraph8')}
    </p>
  </details>

  <details>
    <summary>VI. {t('QuestionsAnswers.Subtitle6')}</summary>
    <p>
    {t('QuestionsAnswers.Paragraph9')}<br />
    {t('QuestionsAnswers.Paragraph10')}
    </p>
  </details>

  <details>
    <summary>VII. {t('QuestionsAnswers.Subtitle7')}</summary>
    <p>
    {t('QuestionsAnswers.Paragraph11')}<br />
    {t('QuestionsAnswers.Paragraph12')}
    </p>
  </details>

  <details>
    <summary>VIII. {t('QuestionsAnswers.Subtitle8')}</summary>
    <p>
    {t('QuestionsAnswers.Paragraph13')}
    </p>
  </details>

  <details>
    <summary>IX. {t('QuestionsAnswers.Subtitle9')}</summary>
    <p>
    {t('QuestionsAnswers.Paragraph14')}
    </p>
  </details>

  <details>
    <summary>X. {t('QuestionsAnswers.Subtitle10')}</summary>
    <p>
    {t('QuestionsAnswers.Paragraph15')}<br />
    {t('QuestionsAnswers.Paragraph16')}
    </p>
  </details>

  <details>
    <summary>XI. {t('QuestionsAnswers.Subtitle11')}</summary>
    <p>
    {t('QuestionsAnswers.Paragraph17')}<br />
    {t('QuestionsAnswers.Paragraph18')}
    </p>
  </details>

  <details>
    <summary>XII. {t('QuestionsAnswers.Subtitle12')}</summary>
    <p>
    {t('QuestionsAnswers.Paragraph19')}<br />
    {t('QuestionsAnswers.Paragraph20')}
    </p>
  </details>

  <details>
    <summary>XIII. {t('QuestionsAnswers.Subtitle13')}</summary>
    <p>
    {t('QuestionsAnswers.Paragraph21')}<br />
    {t('QuestionsAnswers.Paragraph22')}
    </p>
  </details>

  <details>
    <summary>XIV. {t('QuestionsAnswers.Subtitle14')}</summary>
    <p>
    {t('QuestionsAnswers.Paragraph23')}<br />
    1. {t('QuestionsAnswers.Paragraph24')}<br />
    2. {t('QuestionsAnswers.Paragraph25')}<br />
    3. {t('QuestionsAnswers.Paragraph26')}<br />
    4. {t('QuestionsAnswers.Paragraph27')}<br />
    {t('QuestionsAnswers.Paragraph28')}
    </p>
  </details>

  <details>
  <summary>
    </summary>
  </details>
</section>
    </Fragment>
  );
};

QuestionsAnswers.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(QuestionsAnswers);
