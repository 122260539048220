import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PostActions from './PostActions';

const CodeConduct = ({
  auth: { user }
}) => {

  const { t } = useTranslation();

  return (
    <Fragment>
    <PostActions />
         <section>
      <h1 className='large text-primary'>{t('CodeConduct.Title')}</h1>
  <details>
    <summary>I. {t('CodeConduct.Subtitle1')}</summary>
    <p>
    {t('CodeConduct.Paragraph1')}
    </p>
  </details>

  <details>
    <summary>II. {t('CodeConduct.Subtitle2')}</summary>
    <p>
    {t('CodeConduct.Paragraph2')} < br/>
    {t('CodeConduct.Paragraph3')} < br/>
    {t('CodeConduct.Paragraph4')}
    </p>
  </details>

  <details>
    <summary>III. {t('CodeConduct.Subtitle3')}</summary>
    <p>
    {t('CodeConduct.Paragraph5')} < br/>
    {t('CodeConduct.Paragraph6')} < br/>
    {t('CodeConduct.Paragraph7')} < br/>
    <div style={{marginLeft : "2em"}}>
      1. {t('CodeConduct.Paragraph8')} < br/>
      2. {t('CodeConduct.Paragraph9')} < br/>
      3. {t('CodeConduct.Paragraph10')} < br/>
      4. {t('CodeConduct.Paragraph11')} < br/>
      5. {t('CodeConduct.Paragraph12')} < br/>
    </div>
    </p>
  </details>

  <details>
    <summary>IV. {t('CodeConduct.Subtitle4')}</summary>
    <p>
    {t('CodeConduct.Paragraph13')} < br/>
    {t('CodeConduct.Paragraph14')}
    </p>
  </details>

  <details>
    <summary>V. {t('CodeConduct.Subtitle5')}</summary>
    <p>
    {t('CodeConduct.Paragraph15')} < br/>
    {t('CodeConduct.Paragraph16')}
    </p>
  </details>

  <details>
    <summary>VI. {t('CodeConduct.Subtitle6')}</summary>
    <p>
    {t('CodeConduct.Paragraph17')} < br/>
    {t('CodeConduct.Paragraph18')}
    </p>
  </details>

  <details>
    <summary>VII. {t('CodeConduct.Subtitle7')}</summary>
    <p>
    {t('CodeConduct.Paragraph19')} < br/>
    {t('CodeConduct.Paragraph20')}
    </p>
  </details>
  
  <details>
  <summary>
    </summary>
  </details>
</section>
    </Fragment>
  );
};

CodeConduct.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(CodeConduct);
