export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_PROFILES = 'GET_PROFILES';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const PROFILE_ERROR = 'PROFILE_ERROR';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const GET_POSTS = 'GET_POSTS';
export const GET_POST = 'GET_POST';
export const POST_ERROR = 'POST_ERROR';
export const UPDATE_LIKES = 'UPDATE_LIKES';
export const DELETE_POST = 'DELETE_POST';
export const ADD_POST = 'ADD_POST';
export const ADD_COMMENT = 'ADD_COMMENT';
export const REMOVE_COMMENT = 'REMOVE_COMMENT';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAIL = 'ADD_USER_FAIL';
export const UPDATE_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'ADD_USER_FAIL';
export const EDIT_PROFILE_SUCCESS = 'EDIT_PROFILE_SUCCESS';
export const EDIT_PROFILE_FAIL = 'EDIT_PROFILE_FAIL';
export const GET_SPONSORSHIPS = 'GET_SPONSORSHIPS';
export const GET_LEVEL_AFFILIATION = 'GET_LEVEL_AFFILIATION';
export const GET_LEVEL_AFFILIATION_REPOSITIONED = 'GET_LEVEL_AFFILIATION_REPOSITIONED';
export const GET_LEVEL_AFFILIATION_OTHER = 'GET_LEVEL_AFFILIATION_OTHER';
export const GET_LEVEL_AFFILIATION_REPOSITIONED_OTHER = 'GET_LEVEL_AFFILIATION_REPOSITIONED_OTHER';
export const GET_SPONSORSHIPS_OTHER = 'GET_SPONSORSHIPS_OTHER';
export const GET_LEVEL_AFFILIATION_OLD = 'GET_LEVEL_AFFILIATION_OLD';
export const GET_LEVEL_AFFILIATION_REPOSITIONED_OLD = 'GET_LEVEL_AFFILIATION_REPOSITIONED_OLD';
export const GET_BONUS_GENEALOGY_OLD = 'GET_BONUS_GENEALOGY_OLD';
export const GET_DELETED_AFFILIATION = 'GET_DELETED_AFFILIATION';
export const GET_FULL_MATRIX = 'GET_FULL_MATRIX';
export const GET_OLD_USER = 'GET_OLD_USER';
export const GET_CANDIDATE_WARNING = 'GET_CANDIDATE_WARNING';
export const GET_BONUS_GENEALOGY = 'GET_BONUS_GENEALOGY';
export const GET_BONUS_GENEALOGY_OTHER = 'GET_BONUS_GENEALOGY_OTHER';
export const GET_OLD_USERS = 'GET_OLD_USERS';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const UPDATE_TOKEN = 'UPDATE_TOKEN';
export const SPONSORSHIP_ERROR = 'SPONSORSHIP_ERROR';
export const UPDATE_SPONSORSHIPS = 'UPDATE_SPONSORSHIPS';
export const GET_GENEALOGIES = 'GET_GENEALOGIES';
export const GET_GENEALOGIES_THIS_MONTH = 'GET_GENEALOGIES_THIS_MONTH';
export const GET_BONUS = 'GET_BONUS';
export const GET_GENEALOGIES_OTHER = 'GET_GENEALOGIES_OTHER';
export const GET_GENEALOGIES_THIS_MONTH_OTHER = 'GET_GENEALOGIES_THIS_MONTH_OTHER';
export const GET_BONUS_OTHER = 'GET_BONUS_OTHER';
export const GET_GENEALOGIES_OLD = 'GET_GENEALOGIES_OTHER_OLD';
export const GET_GENEALOGIES_THIS_MONTH_OLD = 'GET_GENEALOGIES_THIS_MONTH_OLD';
export const GET_BONUS_OLD = 'GET_BONUS_OLD';
export const GENEALOGY_ERROR = 'GENEALOGY_ERROR';
export const GET_PARTNERS = 'GET_PARTNERS';
export const PARTNER_ERROR = 'PARTNER_ERROR';
export const GET_ADMIN_PARTNERS = 'GET_ADMIN_PARTNERS';
export const ADMIN_PARTNER_ERROR = 'ADMIN_PARTNER_ERROR';
export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';
export const SHOW_LOADING_API = 'SHOW_LOADING_API';
export const HIDE_LOADING_API = 'HIDE_LOADING_API';
export const SEND_EMAIL_SUCCESS = 'SEND_EMAIL_SUCCESS';
export const SEND_EMAIL_FAIL = 'SEND_EMAIL_FAIL';
export const ALL_USERS = 'ALL_USERS';
export const UPDATE_USERS = 'UPDATE_USERS';
export const ALL_USERS_ERROR = 'ALL_USERS_ERROR';
export const REPOSITIONING_USER = 'REPOSITIONING_USER';
export const REPOSITIONING_USER_ERROR = 'REPOSITIONING_USER_ERROR';
export const PAYED = 'PAYED';
export const PAYED_ERROR = 'PAYED_ERROR';
export const TRANSACTIONS = 'TRANSACTIONS';
export const TRANSACTIONS_ERROR = 'TRANSACTIONS_ERROR';
export const ADD_RIB = 'ADD_RIB';
export const ADD_RIB_ERROR = 'ADD_RIB_ERROR';
export const RIBS = 'RIBS';
export const RIBS_ERROR = 'RIBS_ERROR';
export const DELETE_RIB = 'DELETE_RIB';
export const DELETE_RIB_ERROR = 'DELETE_RIB_ERROR';
export const UPDATE_RIB = 'UPDATE_RIB';
export const UPDATE_RIB_ERROR = 'UPDATE_RIB_ERROR';
export const UPDATE_MONTH = 'UPDATE_MONTH';
export const UPDATE_MONTH_ERROR = 'UPDATE_MONTH_ERROR';
export const ADD_TRANSFER_SUCCESS = 'ADD_TRANSFER_SUCCESS';
export const ADD_TRANSFER_FAIL = 'ADD_TRANSFER_FAIL';
export const TRANSFERS_SUCCESS = 'TRANSFERS_SUCCESS';
export const TRANSFERS_FAIL = 'TRANSFERS_FAIL';
export const UPDATE_TRANSFER = 'UPDATE_TRANSFER';
export const UPDATE_TRANSFER_ERROR = 'UPDATE_TRANSFER_ERROR';
export const ALL_TRANSACTIONS = 'ALL_TRANSACTIONS';
export const ALL_TRANSACTIONS_FAIL = 'ALL_TRANSACTIONS_FAIL';
export const CHANGE_THEME = 'CHANGE_THEME';
export const ADD_ARTICLE_SUCCESS = 'ADD_ARTICLE_SUCCESS';
export const ARTICLES_SUCCESS = 'ARTICLES_SUCCESS';
export const ARTICLES_FAIL = 'ARTICLES_FAIL';
export const ARTICLES_NEXT = 'ARTICLES_NEXT';
export const FIRST_ARTICLES_SUCCESS = 'FIRST_ARTICLES_SUCCESS';
export const ARTICLES_CURRENT = 'ARTICLES_CURRENT';
export const ALL_ARTICLES_SUCCESS = 'ALL_ARTICLES_SUCCESS';
export const ALL_ARTICLES_FAIL = 'ALL_ARTICLES_FAIL';
export const GET_ARTICLE_SUCCESS = 'GET_ARTICLE_SUCCESS';
export const GET_ARTICLE_FAIL = 'GET_ARTICLE_FAIL';
export const PLAY_MUSIC = 'PLAY_MUSIC';
export const CHANGE_FOCUS = 'CHANGE_FOCUS';
export const VALIDATE_INVOICE = 'VALIDATE_INVOICE';
export const VALIDATE_INVOICE_ERROR = 'VALIDATE_INVOICE_ERROR';
export const VALIDATE_PAYMENT = 'VALIDATE_PAYMENT';
export const VALIDATE_PAYMENT_ERROR = 'VALIDATE_PAYMENT_ERROR';
export const REGISTER_USER_MEMBER = 'REGISTER_USER_MEMBER';
export const REGISTER_USER_MEMBER_ERROR = 'REGISTER_USER_MEMBER_ERROR';
export const ACCEPT_MEMBERSHIP = 'ACCEPT_MEMBERSHIP';
export const ACCEPT_MEMBERSHIP_ERROR = 'ACCEPT_MEMBERSHIP_ERROR';
export const GET_DEALS = 'GET_DEALS';
export const DEAL_ERROR = 'DEAL_ERROR';
export const DELETE_DEAL = 'DELETE_DEAL';
export const ADD_DEAL = 'ADD_DEAL';
export const GET_DEAL = 'GET_DEAL';
export const GET_USERS_DEAL = 'GET_USERS_DEAL';
export const DEALS_SUCCESS = 'DEALS_SUCCESS';
export const UPDATE_DEAL = 'UPDATE_DEAL';
export const UPDATE_DEAL_ERROR = 'UPDATE_DEAL_ERROR';
export const GET_PURCHASES = 'GET_PURCHASES';
export const PURCHASE_ERROR = 'PURCHASE_ERROR';
export const DELETE_PURCHASE = 'DELETE_PURCHASE';
export const ADD_PURCHASE = 'ADD_PURCHASE';
export const GET_PURCHASE = 'GET_PURCHASE';
export const GET_USERS_PURCHASE = 'GET_USERS_PURCHASE';
export const PURCHASES_SUCCESS = 'PURCHASES_SUCCESS';
export const UPDATE_PURCHASE = 'UPDATE_PURCHASE';
export const UPDATE_PURCHASE_ERROR = 'UPDATE_PURCHASE_ERROR';
export const GET_ADVERTISEMENTS = 'GET_ADVERTISEMENTS';
export const ADVERTISEMENT_ERROR = 'ADVERTISEMENT_ERROR';
export const DELETE_ADVERTISEMENT = 'DELETE_ADVERTISEMENT';
export const ADD_ADVERTISEMENT = 'ADD_ADVERTISEMENT';
export const GET_ADVERTISEMENT = 'GET_ADVERTISEMENT';
export const GET_USERS_ADVERTISEMENT = 'GET_USERS_ADVERTISEMENT';
export const ADVERTISEMENTS_SUCCESS = 'ADVERTISEMENTS_SUCCESS';
export const UPDATE_ADVERTISEMENT = 'UPDATE_ADVERTISEMENT';
export const UPDATE_ADVERTISEMENT_ERROR = 'UPDATE_ADVERTISEMENT_ERROR';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR';
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION';
export const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
export const GET_NOTIFICATION = 'GET_NOTIFICATION';
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS';
export const UPDATE_NOTIFICATION = 'UPDATE_NOTIFICATION';
export const UPDATE_NOTIFICATION_ERROR = 'UPDATE_NOTIFICATION_ERROR';
export const ADD_CHAT = 'ADD_CHAT';
export const CHAT_ERROR = 'CHAT_ERROR';
export const GET_CHATS = 'GET_CHATS';
export const GET_CHATS_FOR_ADMIN = 'GET_CHATS_FOR_ADMIN';
export const GET_ALL_CHATS = 'GET_ALL_CHATS';
export const UPDATE_CHAT = 'UPDATE_CHAT';
export const UPDATE_CHAT_ERROR = 'UPDATE_CHAT_ERROR';
export const FIND_CHAT = 'FIND_CHAT';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const MESSAGE_ERROR = 'MESSAGE_ERROR';
export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGES_FOR_ADMIN = 'GET_MESSAGES_FOR_ADMIN';
export const PAY_AFFILIATION_PROGRAM = 'PAY_AFFILIATION_PROGRAM';
export const PAY_AFFILIATION_PROGRAM_ERROR = 'PAY_AFFILIATION_PROGRAM_ERROR';
export const GET_AFFILIATION_COMMISSION_NUMBER_YEAR = 'GET_AFFILIATION_COMMISSION_NUMBER_YEAR';
export const GET_AFFILIATION_COMMISSION_NUMBER_YEAR_OTHER = 'GET_AFFILIATION_COMMISSION_NUMBER_YEAR_OTHER';