import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { getChats } from '../../actions/chat';
import { getSponsorships } from '../../actions/sponsorship';
import { io } from "socket.io-client";
import Conversation from './Conversation';
import ChatBox from './ChatBox';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


const Chat = ({ getSponsorships, getChats, auth: { user, theme }, chat: { chats } }) => {

    const { t } = useTranslation();


  const socket = useRef();


  const [onlineUsers, setOnlineUsers] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [sendMessage, setSendMessage] = useState(null);
  const [receivedMessage, setReceivedMessage] = useState(null);


  /*useEffect(() => {
    getSponsorships(user._id);
  }, [getSponsorships, user._id]);*/

  // Get the chat in chat section
  useEffect(() => {
    getChats(user._id);
  }, [getChats, user._id]);

  // Connect to Socket.io
  useEffect(() => {
    socket.current = io("ws://localhost:8800");
    socket.current.emit("new-user-add", user._id);
    socket.current.on("get-users", (users) => {
      setOnlineUsers(users);
    });
  }, [user]);

  // Send Message to socket server
  useEffect(() => {
    if (sendMessage!==null) {
      socket.current.emit("send-message", sendMessage);}
  }, [sendMessage]);


  // Get the message from socket server
  useEffect(() => {
    socket.current.on("recieve-message", (data) => {
      setReceivedMessage(data);
    }

    );
  }, []);


  const checkOnlineStatus = (chat) => {
    const chatMember = chat.members.find((member) => member !== user._id);
    const online = onlineUsers.find((user) => user.userId === chatMember);
    return online ? true : false;
  };


  return (
    <Fragment>
    <div className="container-fluid">
    <div className="row">

      {/* Left Side */}
    <div className="col-md-4 col-sm-12 Left-side-chat">
      <div className={`Chat-container border p-3 ${theme === 'light' ? 'chat-border-light' : 'chat-border-dark'}`}>
          <h2>{t('Messaging.Messaging')}</h2>
          <div className="Chat-list scrollable">
            {chats.map((chat, index) => (
              <div
                className={`${theme === 'light' ? 'chat-item-light' : 'chat-item-dark'}`}
                key={index}
                onClick={() => {
                  setCurrentChat(chat);
                }}
              >
                <Conversation
                  data={chat}
                  currentUser={user._id}
                  online={checkOnlineStatus(chat)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Right Side */}

      <div className="col-md-8 col-sm-12 Right-side-chat">
        <div style={{ width: "20rem", alignSelf: "flex-end" }}>
        </div>
        <ChatBox
          currentChat={currentChat}
          currentUser={user._id}
          currentUsername={user.username}
          setSendMessage={setSendMessage}
          receivedMessage={receivedMessage}
        />
      </div>
    </div>
    </div>
    </Fragment>
    ) ;
}


Chat.propTypes = {
  auth: PropTypes.object.isRequired,
  chat: PropTypes.object.isRequired,
  getChats: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  chat: state.chat,
});

export default connect(
  mapStateToProps,
  { getSponsorships, getChats }
)(Chat);
