import React, { Fragment, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import SponsorshipActions from './SponsorshipActions';
import { getGenealogies, getSponsorships } from '../../actions/sponsorship';
import { useTranslation } from 'react-i18next';
import * as moment from 'moment-timezone';




const Genealogy = ({ getGenealogies, getSponsorships, auth: { user, theme }, sponsorship: { sponsorships, level_affiliation, level_affiliation_repositioned/*, deleted_affiliation*/, genealogies, genealogies_this_month, bonus, bonus_genealogy, affiliations_commissions_number_year, loading } }) => {

    useEffect(() => {
        getGenealogies(user._id);
      }, [getGenealogies, user._id]);


  useEffect(() => {
    getSponsorships(user._id);
  }, [getSponsorships, user._id]);

      const { t } = useTranslation();
      if (user && (user.status === "C" || user.status === "A")) {
        return <Redirect to="/dashboard" />;
      }
    
      function foo(name) { console.log(name) }

      function isFunction(functionToCheck) {
        return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
       }

      var oldUsers = 0
      var oldUsersAll = 0
      var oldUsersAffiliations = 0
      var oldAffiliationsCommissionsNumberYear = 0
      const showTable =
      <Fragment>
       <SponsorshipActions />
      
      
<h1 className='large text-primary'>{t('Sponsorship.Payment_table')}</h1>
{/*<h2>
{bonus && bonus.length > 0 ? t('Sponsorship.Position') + ": " + user.verticalLevel + "-" + user.horizontalLevel : ""}
</h2>*/}
  
 <div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>

<div className="scrollme"> 



<table id="tbl" style={{ width : 950 }} className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
  <thead  className={theme === "light" ? "thead-dark" : "thead-light"} >
  <tr>
      <th scope="col" style={{ width : 250 }}>{t('Sponsorship.Levels')}</th>
      <th scope="col">{t('Sponsorship.Subscribers')}</th>
      <th scope="col">{t('Sponsorship.Matrix_commissions')}</th>
      <th scope="col">{t('Sponsorship.Training_bonuses')}</th>
      <th scope="col">{t('Sponsorship.Monthly_income')}</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row" onClick={() => foo("aa")}>1</th>
  <td>{genealogies && !isNaN(genealogies[0]) && bonus && genealogies_this_month ? genealogies[0] + genealogies_this_month[0] : t('Sponsorship.Calculating') + " ..."}</td>
   <td>{genealogies && !isNaN(genealogies[0]) && bonus ? (genealogies[0] * 6) + "€": t('Sponsorship.Calculating') + " ..."}</td>
   {
        // UNCOMMENT pre-opening ON FIRST MONTH          <td>0€</td>
  }

  <td>{level_affiliation && !isNaN(level_affiliation[0]) && bonus ? level_affiliation[0] * 100 + "€": t('Sponsorship.Calculating') + " ..."}</td>
  <td>{genealogies && !isNaN(genealogies[0]) && bonus && level_affiliation ? /* UNCOMMENT pre-opening ON FIRST MONTH*/ (genealogies[0] * 6) + (level_affiliation[0] * 100) + "€" : t('Sponsorship.Calculating') + " ..."}</td>
</tr>
<tr>
<th scope="row">2</th>
  <td>{genealogies && !isNaN(genealogies[1]) && bonus && genealogies_this_month ? genealogies[1] + genealogies_this_month[1] : t('Sponsorship.Calculating') + " ..."}</td>
  
  <td>{genealogies && !isNaN(genealogies[1]) && bonus ? (genealogies[1] * 6) + "€": t('Sponsorship.Calculating') + " ..."}</td>
  {
        // UNCOMMENT pre-opening ON FIRST MONTH         <td>0€</td>
  }
  <td>{level_affiliation && !isNaN(level_affiliation[1]) && bonus ? level_affiliation[1] * 100 + "€": t('Sponsorship.Calculating') + " ..."}</td>
  <td>{genealogies && !isNaN(genealogies[1]) && bonus && level_affiliation ? /* UNCOMMENT pre-opening ON FIRST MONTH*/ (genealogies[1] * 6) + (level_affiliation[1] * 100) + "€" : t('Sponsorship.Calculating') + " ..."}</td>
</tr>
<tr>
  <th scope="row">3</th>
  <td>{genealogies && !isNaN(genealogies[2]) && bonus && genealogies_this_month ? genealogies[2] + genealogies_this_month[2] : t('Sponsorship.Calculating') + " ..."}</td>

    <td>{genealogies && !isNaN(genealogies[2]) && bonus ? (genealogies[2] * 6) + "€": t('Sponsorship.Calculating') + " ..."}</td>
    {
        // UNCOMMENT pre-opening ON FIRST MONTH        <td>0€</td>
  }
  
  <td>{level_affiliation && !isNaN(level_affiliation[2]) && bonus ? level_affiliation[2] * 100 + "€": t('Sponsorship.Calculating') + " ..."}</td>
  <td>{genealogies && !isNaN(genealogies[2]) && bonus && level_affiliation ? /* UNCOMMENT pre-opening ON FIRST MONTH*/ (genealogies[2] * 6) + (level_affiliation[2] * 100) + "€" : t('Sponsorship.Calculating') + " ..."}</td>
</tr>
<tr>
  <th scope="row">4</th>
  <td>{genealogies && !isNaN(genealogies[3]) && bonus && genealogies_this_month ? genealogies[3] + genealogies_this_month[3] : t('Sponsorship.Calculating') + " ..."}</td>

 <td>{genealogies && !isNaN(genealogies[3]) && bonus ? (genealogies[3] * 6) + "€": t('Sponsorship.Calculating') + " ..."}</td>
 {
        // UNCOMMENT pre-opening ON FIRST MONTH      <td>0€</td>
  }
  
  <td>{level_affiliation && !isNaN(level_affiliation[3]) && bonus ? level_affiliation[3] * 100 + "€": t('Sponsorship.Calculating') + " ..."}</td>
  <td>{genealogies && !isNaN(genealogies[3]) && bonus && level_affiliation ? /* UNCOMMENT pre-opening ON FIRST MONTH*/ (genealogies[3] * 6) + (level_affiliation[3] * 100) + "€" : t('Sponsorship.Calculating') + " ..."}</td>
</tr>
<tr>
  <th scope="row">5</th>
  <td>{genealogies && !isNaN(genealogies[4]) && bonus && genealogies_this_month ? genealogies[4] + genealogies_this_month[4] : t('Sponsorship.Calculating') + " ..."}</td>

 <td>{genealogies && !isNaN(genealogies[4]) && bonus ? (genealogies[4] * 6) + "€": t('Sponsorship.Calculating') + " ..."}</td>
 {
        // UNCOMMENT pre-opening ON FIRST MONTH       <td>0€</td>
  }

  
  <td>{level_affiliation && !isNaN(level_affiliation[4]) && bonus ? level_affiliation[4] * 100 + "€": t('Sponsorship.Calculating') + " ..."}</td>
  <td>{genealogies && !isNaN(genealogies[4]) && bonus && level_affiliation ? /* UNCOMMENT pre-opening ON FIRST MONTH */ (genealogies[4] * 6) + (level_affiliation[4] * 100) + "€" : t('Sponsorship.Calculating') + " ..."}</td>
</tr>
<tr>
  <th scope="row">6</th>
  <td>{genealogies && !isNaN(genealogies[5]) && bonus && genealogies_this_month ? genealogies[5] + genealogies_this_month[5] : t('Sponsorship.Calculating') + " ..."}</td>

  <td>{genealogies && !isNaN(genealogies[5]) && bonus ? (genealogies[5] * 6) + "€": t('Sponsorship.Calculating') + " ..."}</td>
  {
        // UNCOMMENT pre-opening ON FIRST MONTH         <td>0€</td>
  }

  
  <td>{level_affiliation && !isNaN(level_affiliation[5]) && bonus ? level_affiliation[5] * 100 + "€": t('Sponsorship.Calculating') + " ..."}</td>
  <td>{genealogies && !isNaN(genealogies[5]) && bonus && level_affiliation ? /* UNCOMMENT pre-opening ON FIRST MONTH*/ (genealogies[5] * 6) + (level_affiliation[5] * 100) + "€" : t('Sponsorship.Calculating') + " ..."}</td>
</tr>
<tr>
  <th scope="row">7</th>
  <td>{genealogies && !isNaN(genealogies[6]) && bonus && genealogies_this_month ? genealogies[6] + genealogies_this_month[6] : t('Sponsorship.Calculating') + " ..."}</td>

  <td>{genealogies && !isNaN(genealogies[6]) && bonus ? (genealogies[6] * 6) + "€": t('Sponsorship.Calculating') + " ..."}</td>
  {
        // UNCOMMENT pre-opening ON FIRST MONTH        <td>0€</td>
  }

  
  <td>{level_affiliation && !isNaN(level_affiliation[6]) && bonus ? level_affiliation[6] * 100 + "€": t('Sponsorship.Calculating') + " ..."}</td>
  <td>{genealogies && !isNaN(genealogies[6]) && bonus && level_affiliation ? /* UNCOMMENT pre-opening ON FIRST MONTH*/ (genealogies[6] * 6) + (level_affiliation[6] * 100) + "€" : t('Sponsorship.Calculating') + " ..."}</td>
</tr>

<tr>
  <th scope="row">{t('Sponsorship.Affiliate_commissions')}</th>
  <td>{genealogies && !isNaN(genealogies[6]) && bonus && genealogies_this_month ? affiliations_commissions_number_year : t('Sponsorship.Calculating') + " ..."}</td>

  <td>{genealogies && !isNaN(genealogies[6]) && bonus ? "" : t('Sponsorship.Calculating') + " ..."}</td>
  {
        // UNCOMMENT pre-opening ON FIRST MONTH        <td>0€</td>
  }

  
  <td>{level_affiliation && !isNaN(level_affiliation[6]) && bonus ? "" : t('Sponsorship.Calculating') + " ..."}</td>
  <td>{genealogies && !isNaN(genealogies[6]) && bonus && level_affiliation ? (affiliations_commissions_number_year * 6) + "€" : t('Sponsorship.Calculating') + " ..."}</td>
</tr>

<tr>
  <th scope="row">{bonus && bonus.length > 0 ? t('Sponsorship.Matrix') + " " + (bonus.length + 1) : t('Sponsorship.Total')}</th>
  <td>
  {genealogies && !isNaN(genealogies[0]) && bonus && genealogies_this_month ? (genealogies[0] + genealogies[1] +
  genealogies[2] + genealogies[3] + genealogies[4] +
  genealogies[5] + genealogies[6] + genealogies_this_month[0]
  + genealogies_this_month[1] + genealogies_this_month[2] + genealogies_this_month[3]
  + genealogies_this_month[4] + genealogies_this_month[5] + genealogies_this_month[6]) : t('Sponsorship.Calculating') + " ..."}
  </td>

  <td>
  {genealogies && !isNaN(genealogies[0]) && bonus ? ((genealogies[0] * 6) + (genealogies[1] * 6) +
    (genealogies[2] * 6) + (genealogies[3] * 6) + (genealogies[4] * 6) +
    (genealogies[5] * 6) + (genealogies[6] * 6)) + "€" : t('Sponsorship.Calculating') + " ..."}
    </td> 
    {
        /* UNCOMMENT pre-opening ON FIRST MONTH     <td>0€</td>*/
  }
  
  <td>
  {level_affiliation && !isNaN(level_affiliation[0]) && bonus ? (level_affiliation[0] * 100 + level_affiliation[1] * 100 +
  level_affiliation[2] * 100 + level_affiliation[3] * 100 + level_affiliation[4] * 100 +
  level_affiliation[5] * 100 + level_affiliation[6] * 100) + "€" : t('Sponsorship.Calculating') + " ..."
  }
  </td>
  <td>
  { genealogies && !isNaN(genealogies[0]) && bonus && level_affiliation ? (/* UNCOMMENT pre-opening ON FIRST MONTH*/(genealogies[0] * 6) + (genealogies[1] * 6) +
  (genealogies[2] * 6) + (genealogies[3] * 6) + (genealogies[4] * 6) +
  (genealogies[5] * 6) + (genealogies[6] * 6) +
  level_affiliation[0] * 100 + level_affiliation[1] * 100 +
  level_affiliation[2] * 100 + level_affiliation[3] * 100 + level_affiliation[4] * 100 +
  level_affiliation[5] * 100 + level_affiliation[6] * 100 + affiliations_commissions_number_year * 6)
  + "€" : t('Sponsorship.Calculating') + " ..."
    }
  </td>
</tr>
<div style={{ display : (bonus_genealogy && bonus_genealogy !== 0) ? "" : "none" }}><br/></div>
  <tr>
  <th style={{ display : (bonus_genealogy && bonus_genealogy !== 0) ? "" : "none" }} scope="row">{t('Sponsorship.Repositioning_bonus')}</th>
  <td style={{ display : (bonus_genealogy && bonus_genealogy !== 0) ? "" : "none" }} colSpan={3}></td>
  <td style={{ display : (bonus_genealogy && bonus_genealogy !== 0) ? "" : "none" }}>{bonus_genealogy + "€"}</td>
  </tr> 
  </tbody>
</table>








    
<table  style={{ display : bonus && bonus.length > 0 ? "" : "none" , width : 950}} id="tbl" className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
<thead  className={theme === "light" ? "thead-dark" : "thead-light"}>
<tr>
  <th scope="col" style={{ width : 250 }}>{t('Sponsorship.Full_matrices')}</th>
  <th scope="col">{t('Sponsorship.Subscribers')}</th>
  <th scope="col">{t('Sponsorship.Matrix_commissions')}</th>
  <th scope="col">{t('Sponsorship.Training_bonuses')}</th>
  <th scope="col">{t('Sponsorship.Monthly_income')}</th>
</tr>
</thead>
<tbody>


{(!bonus || !level_affiliation_repositioned) || ((bonus && bonus.length === 0) || (level_affiliation_repositioned && level_affiliation_repositioned.length === 0)) ? 
  <>
</>
:
(bonus && isFunction(bonus.map)) ?
bonus.map(( get_bonus, index ) => {
  oldUsers += get_bonus ? get_bonus !== undefined ? get_bonus.user_number : 0 : 0
  oldUsersAll += get_bonus ? get_bonus !== undefined ? get_bonus.user_number_all : 0 : 0
  oldAffiliationsCommissionsNumberYear += get_bonus ? get_bonus !== undefined ? get_bonus.old_affiliations_commissions_number_year : 0 : 0
  oldUsersAffiliations += level_affiliation_repositioned[index] ? level_affiliation_repositioned[index] !== undefined ? level_affiliation_repositioned[index].user_number : 0 : 0
return (
  <>
<tr key={index}>
<th scope="row">{t('Sponsorship.Matrix') + " " + (bonus && bonus.length ? bonus.length - index : 0)}</th>
<td>{get_bonus ? get_bonus !== undefined ? get_bonus.user_number + get_bonus.user_number_all : t('Sponsorship.Calculating') + " ..." : t('Sponsorship.Calculating') + " ..."}</td>
<td>{get_bonus ? get_bonus !== undefined ? get_bonus.user_number * 6 + "€" : t('Sponsorship.Calculating') + " ..." : t('Sponsorship.Calculating') + " ..."}</td>
<td>{level_affiliation_repositioned[index] ? level_affiliation_repositioned[index] !== undefined ? level_affiliation_repositioned[index].user_number * 100 + "€" : t('Sponsorship.Calculating') + " ..." : t('Sponsorship.Calculating') + " ..."}</td>
<td>{get_bonus ? get_bonus !== undefined ? get_bonus.user_number * 6 + level_affiliation_repositioned[index].user_number * 100 + "€" : t('Sponsorship.Calculating') + " ..." : t('Sponsorship.Calculating') + " ..."}</td>
</tr>

<tr>
  <th scope="row">{t('Sponsorship.Affiliate_commissions')}</th>
  <td>{get_bonus && get_bonus !== undefined ? get_bonus.old_affiliations_commissions_number_year : t('Sponsorship.Calculating') + " ..."}</td>
  <td>{get_bonus && get_bonus !== undefined ? "" : t('Sponsorship.Calculating') + " ..."}</td>
  <td>{get_bonus && get_bonus !== undefined ? "" : t('Sponsorship.Calculating') + " ..."}</td>
  <td>{get_bonus && get_bonus !== undefined ? (get_bonus.old_affiliations_commissions_number_year * 6) + "€" : t('Sponsorship.Calculating') + " ..."}</td>
</tr>
</>
);
}) : null}

<tr>
  <th scope="row">{t('Sponsorship.Total')}</th>
  <td>
    {
      genealogies && !isNaN(genealogies[0]) && genealogies_this_month && bonus && level_affiliation_repositioned ? (genealogies[0] + genealogies[1] +
        genealogies[2] + genealogies[3] + genealogies[4] +
        genealogies[5] + genealogies[6] + genealogies_this_month[0]
        + genealogies_this_month[1] + genealogies_this_month[2] + genealogies_this_month[3]
        + genealogies_this_month[4] + genealogies_this_month[5] + genealogies_this_month[6] + oldUsers + oldUsersAll) : t('Sponsorship.Calculating') + " ..."
    }
  </td>
  <td>
  {genealogies && !isNaN(genealogies[0]) && bonus ? ((genealogies[0] * 6) + (genealogies[1] * 6) +
  (genealogies[2] * 6) + (genealogies[3] * 6) + (genealogies[4] * 6) +
  (genealogies[5] * 6) + (genealogies[6] * 6) + (oldUsers * 6)) + "€" : t('Sponsorship.Calculating') + " ..."}
  </td>
  <td>
  {level_affiliation && !isNaN(level_affiliation[0]) && level_affiliation_repositioned ? (level_affiliation[0] * 100 + level_affiliation[1] * 100 +
  level_affiliation[2] * 100 + level_affiliation[3] * 100 + level_affiliation[4] * 100 +
  level_affiliation[5] * 100 + level_affiliation[6] * 100 + oldUsersAffiliations * 100) + "€" : t('Sponsorship.Calculating') + " ..."
  }
  </td>
  <td>
  {genealogies && !isNaN(genealogies[0]) && level_affiliation && bonus && level_affiliation_repositioned ? ((genealogies[0] * 6) + (genealogies[1] * 6) +
  (genealogies[2] * 6) + (genealogies[3] * 6) + (genealogies[4] * 6) +
  (genealogies[5] * 6) + (genealogies[6] * 6) +
  level_affiliation[0] * 100 + level_affiliation[1] * 100 +
  level_affiliation[2] * 100 + level_affiliation[3] * 100 + level_affiliation[4] * 100 +
  level_affiliation[5] * 100 + level_affiliation[6] * 100
  + oldUsers * 6 + oldUsersAffiliations * 100 + bonus_genealogy + affiliations_commissions_number_year * 6)
  + "€" : t('Sponsorship.Calculating') + " ..."
    }
  </td>
</tr>
  </tbody>
</table>

</div>
</div>
   
  </Fragment>;

      



/*function find(searchData, target, accum=[]){

  target.forEach((f)=>{
    if(f.children !== 0){
      console.log("children" + f.children)
      find(searchData, f.children, accum)
      setLevel(level + 1);
    }
    if(f.id.includes(searchData)){
      accum.push(f);
    }
  });
  return JSON.stringify(accum);
} */




return loading && genealogies === null ? (
    <Spinner />
  ) : (

<Fragment>
{showTable}
</Fragment>
);
};




Genealogy.propTypes = {
    getGenealogies: PropTypes.func.isRequired,
    getSponsorships: PropTypes.func.isRequired,
    sponsorship: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    sponsorship: state.sponsorship,
    auth: state.auth
});

export default connect(
  mapStateToProps,
  { getGenealogies, getSponsorships }
)(Genealogy);




