import {  axios } from '../utils/axios';
import { setAlert } from './alert';
import {
  GET_DEALS,
  DEAL_ERROR,
  DELETE_DEAL,
  ADD_DEAL,
  GET_DEAL,
  GET_USERS_DEAL,
  DEALS_SUCCESS,
  UPDATE_DEAL,
  UPDATE_DEAL_ERROR,
  LOGOUT,
  SHOW_LOADING_API,
  HIDE_LOADING_API,
} from './types';

// Get all deals
export const getDeals = (id) => async dispatch => {
   
  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const body = JSON.stringify({ id : id });
  
  try {
    const res = await axios.post('/api/deals/get_all_deals/', body, config);
    dispatch({
      type: GET_DEALS,
      payload: res.data.deals
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: DEAL_ERROR,
  //    payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};

// Delete deal
export const deleteDeal = id => async dispatch => {
  try {
    await axios.delete(`/api/deals/${id}`);

    dispatch({
      type: DELETE_DEAL,
      payload: id
    });

    dispatch(setAlert('server_17_deal', 'success'));
  } catch (err) {
    dispatch({
      type: DEAL_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
  }
};

// Add deal
export const addDeal = ({ presenter, title, description, initial_selling_price, negotiated_selling_price, further_information, offer_expiry_date, delivery_method, city, postal_code, country, imgToSendFirst, imgToSendSecond, imgToSendThird, history }) => async dispatch => {

  dispatch({
    type: SHOW_LOADING_API
  });
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };


  const body = new FormData();
  body.append("presenter", presenter);
  body.append("title", title);
  body.append("description", description);
  body.append("initial_selling_price", initial_selling_price);
  body.append("negotiated_selling_price", negotiated_selling_price);
  body.append("further_information", further_information);
  body.append("offer_expiry_date", offer_expiry_date);
  body.append("delivery_method", delivery_method);
  body.append("city", city);
  body.append("postal_code", postal_code);
  body.append("country", country);
  body.append("imgFirst", imgToSendFirst);
  body.append("imgSecond", imgToSendSecond);
  body.append("imgThird", imgToSendThird);

  try {
    const res = await axios.post('/api/deals/add_deal', body, config);

    dispatch({
      type: ADD_DEAL,
      payload: res.data
    });

    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch(setAlert('server_19_deal', 'success'));
    history.push('/all-deals');
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: DEAL_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });

    dispatch({
      type: HIDE_LOADING_API
    });
  }
};



  // Update deal
  export const updateDeal = (id, state, userId, isAdmin) => async dispatch => {
   
    dispatch({
      type: SHOW_LOADING_API
    });
  
    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
    
      const body = JSON.stringify({ id, state });

      const body1 = JSON.stringify({ id : userId });
    
    try {
      const res = await axios.post('/api/deals/update_deal/', body, config);
      if (res) {
        var res2 = {data: {deals: []}};
        if (isAdmin) {
          const res2 = await axios.post('/api/deals/get_all_deals/', body1, config);
          dispatch({
            type: DEALS_SUCCESS,
            payload: res2.data.deals
          });
        }
        else {
            const res2 = await axios.post('/api/deals/get_deals_for_clients/', body1, config);
            dispatch({
                type: GET_USERS_DEAL,
                payload: res2.data.deals
            });
        }
      }
      
      dispatch({
        type: UPDATE_DEAL,
        payload: res.data
      });
      dispatch({
        type: HIDE_LOADING_API
      });
    } catch (err) {
      dispatch({
        type: UPDATE_DEAL_ERROR,
      //  payload: { msg: err.response.statusText, status: err.response.status }
      });
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch({ type: LOGOUT });
    }
  };

  // Publish deals
  export const publishDeals = (userId) => async dispatch => {
   
    dispatch({
      type: SHOW_LOADING_API
    });
  
    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
    
      const body = JSON.stringify({ id: userId });
    
    try {
      const res = await axios.post('/api/deals/publish_deals/', config);
      if (res) {
          const res2 = await axios.post('/api/deals/get_all_deals/', body, config);
  
          dispatch({
            type: DEALS_SUCCESS,
            payload: res2.data.deals
          });
      }

      dispatch({
        type: HIDE_LOADING_API
      });
    } catch (err) {
      dispatch({
        type: DEAL_ERROR,
      //  payload: { msg: err.response.statusText, status: err.response.status }
      });
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch({ type: LOGOUT });
    }
  };

// Get deal
export const getDeal = id => async dispatch => {
  dispatch({
      type: SHOW_LOADING_API
    });
  try {
    const res = await axios.get(`/api/deals/${id}`);

    dispatch({
      type: GET_DEAL,
      payload: res.data
    });
  } catch (err) {
    dispatch({
      type: DEAL_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};

// Get deal for clients
export const getDealForClients = id => async dispatch => {
  dispatch({
      type: SHOW_LOADING_API
    });

  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const body = JSON.stringify({ id : id });
  try {
    const res = await axios.post('/api/deals/get_deals_for_clients/', body, config);

    dispatch({
      type: GET_USERS_DEAL,
      payload: res.data.deals
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: DEAL_ERROR,
      payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};