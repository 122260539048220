import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DealsActions from './DealsActions';

const GcuDeal = ({
  auth: { user }
}) => {

  const { t } = useTranslation();

  return (
    <Fragment>
        <DealsActions />
        <br/><br/>
         <section>
      <h1 className='large text-primary'>{t('GcuDeal.Title')}</h1>
  <details>
    <summary>I. {t('GcuDeal.Subtitle1')}</summary>
    <p>
    <strong>1. </strong>
      {t('GcuDeal.Paragraph1')} < br/>
    <strong>2. </strong>
      {t('GcuDeal.Paragraph2')} < br/>
    <strong>3. </strong>
      {t('GcuDeal.Paragraph3')} < br/>
    <div style={{marginLeft : "2em"}}>
      a. {t('GcuDeal.Paragraph4')} < br/>
      b. {t('GcuDeal.Paragraph5')} < br/>
    </div>
    <strong>4. </strong>
      {t('GcuDeal.Paragraph6')} < br/>
    <strong>5. </strong>
      {t('GcuDeal.Paragraph7')}
    </p>
  </details>

  <details>
    <summary>II. {t('GcuDeal.Subtitle2')}</summary>
    <p>
    <strong>1. {t('GcuDeal.Paragraph8')}</strong>< br/>
      {t('GcuDeal.Paragraph9')} < br/>
      <strong>2. {t('GcuDeal.Paragraph10')}</strong>< br/>
      {t('GcuDeal.Paragraph11')} < br/>
    <strong>3. {t('GcuDeal.Paragraph12')}</strong>< br/>
      {t('GcuDeal.Paragraph13')}
    </p>
  </details>

  <details>
    <summary>III. {t('GcuDeal.Subtitle3')}</summary>
    <p>
    <strong>1. </strong>
      {t('GcuDeal.Paragraph14')} < br/>
    <strong>2. </strong>
      {t('GcuDeal.Paragraph15')} < br/>
    <strong>3. </strong>
      {t('GcuDeal.Paragraph16')} < br/>
    <strong>4. </strong>
      {t('GcuDeal.Paragraph17')} < br/>
    <strong>5. </strong>
      {t('GcuDeal.Paragraph18')}
    </p>
  </details>

  <details>
    <summary>IV. {t('GcuDeal.Subtitle4')}</summary>
    <p>
    <strong>1. </strong>{t('GcuDeal.Paragraph19')}<br />
    <div style={{marginLeft : "2em"}}>
      a. {t('GcuDeal.Paragraph20')} < br/>
      b. {t('GcuDeal.Paragraph21')}
    </div>
    <strong>2. </strong>
      {t('GcuDeal.Paragraph22')} < br/>
    <strong>3. </strong>
      {t('GcuDeal.Paragraph23')} < br/>
    <strong>4. </strong>
      {t('GcuDeal.Paragraph24')} < br/>
    <strong>5. </strong>
      {t('GcuDeal.Paragraph25')} < br/>
    <strong>6. </strong>
      {t('GcuDeal.Paragraph26')} < br/>
    <strong>7. </strong>
      {t('GcuDeal.Paragraph27')} < br/>
      {t('GcuDeal.Paragraph28')}
    </p>
  </details>
  
  <details>
    <summary>V. {t('GcuDeal.Subtitle5')}</summary>
    <p>
    <strong>1. </strong>{t('GcuDeal.Paragraph29')}<br />
    <div style={{marginLeft : "2em"}}>
      a. {t('GcuDeal.Paragraph30')} < br/>
      b. {t('GcuDeal.Paragraph31')} < br/>
      c. {t('GcuDeal.Paragraph32')}
    </div>
    <strong>2. </strong>{t('GcuDeal.Paragraph33')}<br />
    <div style={{marginLeft : "2em"}}>
      a. {t('GcuDeal.Paragraph34')} < br/>
      b. {t('GcuDeal.Paragraph35')} < br/>
      c. {t('GcuDeal.Paragraph36')}
    </div>
    <strong>3. </strong>{t('GcuDeal.Paragraph37')} < br/>
    {t('GcuDeal.Paragraph38')} < br/>
    <div style={{marginLeft : "2em"}}>
      a. {t('GcuDeal.Paragraph39')} < br/>
      b. {t('GcuDeal.Paragraph40')}
    </div>
    </p>
  </details>

  <details>
    <summary>VI. {t('GcuDeal.Subtitle6')}</summary>
    <p>
    <strong>1. </strong>
      {t('GcuDeal.Paragraph41')} < br/>
    <strong>2. </strong>
      {t('GcuDeal.Paragraph42')} < br/>
    <strong>3. </strong>
      {t('GcuDeal.Paragraph43')}
    </p>
  </details>

  <details>
    <summary>VII. {t('GcuDeal.Subtitle7')}</summary>
    <p>
    {t('GcuDeal.Paragraph44')} < br/>
    <strong>1. </strong>
      {t('GcuDeal.Paragraph45')} < br/>
    <strong>2. </strong>
      {t('GcuDeal.Paragraph46')} < br/>
    <strong>3. </strong>
      {t('GcuDeal.Paragraph47')} < br/>
    <strong>4. </strong>
      {t('GcuDeal.Paragraph48')}
    </p>
  </details>

  <details>
  <summary>
    </summary>
  </details>
</section>
    </Fragment>
  );
};

GcuDeal.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(GcuDeal);
