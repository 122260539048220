import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AdvertisementsActions from './AdvertisementsActions';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import { useHistory } from 'react-router-dom';


const AdvertisementCategory = ({ auth: { user, theme } }) => {
  const { t } = useTranslation();
  let history = useHistory();

  const adsData = [
    {
      id: 1,
      image: require('../../img/house.png'),
      original: "House",
      name: t('Advertisements.House'),
      sub_categories: [{name: t('Advertisements.Furnishings'), original: "Furnishings"}, {name: t('Advertisements.Household_appliances'), original: "Household_appliances"}, {name: t('Advertisements.Decoration'), original: "Decoration"}, {name: t('Advertisements.DIY'), original: "DIY"}, {name: t('Advertisements.Gardening'), original: "Gardening"}]
    },
    {
      id: 2,
      image: require('../../img/mode.png'),
      original: "Fashion",
      name: t('Advertisements.Fashion'),
      sub_categories: [{name: t('Advertisements.Clothing'), original: "Clothing"}, {name: t('Advertisements.Shoes'), original: "Shoes"}, {name: t('Advertisements.Accessories_luggage'), original: "Accessories_luggage"}, {name: t('Advertisements.Watches_jewelry'), original: "Watches_jewelry"}, {name: t('Advertisements.Baby'), original: "Baby"}]
    },
    {
      id: 3,
      image: require('../../img/multimedia.png'),
      original: "Multimedia",
      name: t('Advertisements.Multimedia'),
      sub_categories: [{name: t('Advertisements.Computers'), original: "Computers"}, {name: t('Advertisements.Telephony'), original: "Telephony"}, {name: t('Advertisements.Image_sound'), original: "Image_sound"}, {name: t('Advertisements.Console_video_games'), original: "Console_video_games"}, {name: t('Advertisements.Accessories_peripherals'), original: "Accessories_peripherals"}]
    },
    {
      id: 4,
      image: require('../../img/sports_and_leisure.png'),
      original: "Sports_leisure",
      name: t('Advertisements.Sports_leisure'),
      sub_categories: [{name: t('Advertisements.Sports_equipment'), original: "Sports_equipment"}, {name: t('Advertisements.Bikes'), original: "Bikes"}, {name: t('Advertisements.Musical_instruments'), original: "Musical_instruments"}, {name: t('Advertisements.Games_toys'), original: "Games_toys"}, {name: t('Advertisements.Collection'), original: "Collection"}]
    },
    {
      id: 5,
      image: require('../../img/vehicle.png'),
      original: "Vehicle",
      name: t('Advertisements.Vehicle'),
      sub_categories: [{name: t('Advertisements.Cars'), original: "Cars"}, {name: t('Advertisements.Motorcycles'), original: "Motorcycles"}, {name: t('Advertisements.Caravans'), original: "Caravans"}, {name: t('Advertisements.Boating'), original: "Boating"}, {name: t('Advertisements.Equipment'), original: "Equipment"}]
    },
    {
      id: 6,
      image: require('../../img/various.png'),
      original: "Various",
      name: t('Advertisements.Various'),
      sub_categories: [{name: t('Advertisements.Hotels_restaurants'), original: "Hotels_restaurants"}, {name: t('Advertisements.Travel'), original: "Travel"}, {name: t('Advertisements.Vacations'), original: "Vacations"}, {name: t('Advertisements.Services'), original: "Services"}, {name: t('Advertisements.Others'), original: "Others"}]
    }
  ]

  const [showMoreHouse, setShowMoreHouse] = useState(false);
  const [showMoreMode, setShowMoreMode] = useState(false);
  const [showMoreMultimedia, setShowMoreMultimedia] = useState(false);
  const [showMoreSports, setShowMoreSports] = useState(false);
  const [showMoreVehicle, setShowMoreVehicle] = useState(false);
  const [showMoreVarious, setShowMoreVarious] = useState(false);

  if (user && user.status === "C") {
    return <Redirect to="/dashboard" />;
  }


  return (
    <Fragment>
    <AdvertisementsActions />
    <br/><br/>
    <section id="ad-categories" className="block ad-categories-block">
      <Container fluid>
        <div className="title-holder">
          <button className="btn btn-primary btn-block" onClick={() => history.push('/all-advertisements')}>
      {t('Advertisements.All_ads')}
    </button>
    <br/>
          <h2>{t('Advertisements.Our_categories')}</h2>
          <div className="subtitle">{t('Advertisements.And_subcategories')}</div>
        </div>
        <Row>
          {
            adsData.map(categories => {
              return (
                <Col sm={4} key={categories.id}>
                  <div className={`card " ${theme === "light" ? "bg-light border-primary" : "text-white bg-dark border-primary"}`}>
  <Image className="card-img-top" src={categories.image} alt="Card image cap" />
  <div className="card-body">
    <h5 className="card-title" style={{cursor: 'pointer'}} onClick={() => history.push('/all-advertisements', { category: categories.original })}>{categories.name}</h5>
  </div>
  <ul className="list-group list-group-flush" >
  {categories.sub_categories.map(sub_categories => {
    return(
    <li className={`list-group-item " ${theme === "light" ? "bg-light" : "bg-primary"}`} style={{ display : (categories.id === 1 && showMoreHouse)
      || (categories.id === 2 && showMoreMode)
      || (categories.id === 3 && showMoreMultimedia)
      || (categories.id === 4 && showMoreSports)
      || (categories.id === 5 && showMoreVehicle)
      || (categories.id === 6 && showMoreVarious)
      ? "" : "none", cursor: 'pointer' }}
      onClick={() => history.push('/all-advertisements', { category: categories.original, subCategory: sub_categories.original  })}>{sub_categories.name}</li>
    )
  })}
  </ul>
  <div className="card-body d-flex justify-content-center">
  <button className="btn btn-primary" onClick={() => {
    if (categories.id === 1)
    setShowMoreHouse(!showMoreHouse)
    else if (categories.id === 2)
    setShowMoreMode(!showMoreMode)
    else if (categories.id === 3)
    setShowMoreMultimedia(!showMoreMultimedia)
    else if (categories.id === 4)
    setShowMoreSports(!showMoreSports)
    else if (categories.id === 5)
    setShowMoreVehicle(!showMoreVehicle)
    else if (categories.id === 6)
    setShowMoreVarious(!showMoreVarious)
  }}>{
    (categories.id === 1 && showMoreHouse)
    || (categories.id === 2 && showMoreMode)
    || (categories.id === 3 && showMoreMultimedia)
    || (categories.id === 4 && showMoreSports)
    || (categories.id === 5 && showMoreVehicle)
    || (categories.id === 6 && showMoreVarious)
    ? t('Advertisements.Show_less') : t('Advertisements.Show_more')}</button>
  </div>
</div>
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </section>
    </Fragment>
  );
}


AdvertisementCategory.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(AdvertisementCategory);
