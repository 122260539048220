import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { updateUser } from '../../actions/sponsorship';
import PostActions from './PostActions';
import Spinner from '../layout/Spinner';
import PropTypes from 'prop-types';
//import { CountryDropdown } from 'react-country-region-selector';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import { getSponsorships } from '../../actions/sponsorship';
import noImage from '../../img/passport.png';
import pdfIcon from '../../img/pdf.png';
import DateButton from '../layout/DateButton';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { Button } from 'react-bootstrap';


 

const SponsorshipProfile = ({ getSponsorships, setAlert, updateUser, 
    auth: { user, loading }, sponsorship: { update_user }}) => {

  /*    useEffect(() => {
        getSponsorships(user._id);
      }, [getSponsorships, user._id]);

      useEffect(() => {
        getPartners(user._id) 
      }, [getPartners, user._id]); */

  const [formData, setFormData] = useState({
    username: user.username,
    email: user.email,
    first_name: user.first_name,
    last_name: user.last_name,
    phone_number: user.phone_number,
    address: user.address,
    additional_address: user.additional_address,
    city: user.city,
    postal_code: user.postal_code
  });

  useEffect(() => {
    getSponsorships(user._id, false, false, false);
  }, [getSponsorships, user._id, update_user]);

   /* useEffect(() => {
      getPartners(user._id) 
    }, [getPartners, user._id]); */

  const { t } = useTranslation();


  const { username, email, first_name, last_name, phone_number, address, additional_address, city, postal_code, userId } = formData;


  const [country, setCountry] = useState(user.country);
  const [countryLabel, setCountryLabel] = useState();

  const [gender, setGender] = useState(user.gender);

  const [firstErrorMessage, setFirstErrorMessage] = useState(false);
  const [secondErrorMessage, setSecondErrorMessage] = useState(false);
  const [thirdErrorMessage, setThirdErrorMessage] = useState(false);
  const [fourthErrorMessage, setFourthErrorMessage] = useState(false);
  const [fifthErrorMessage, setFifthErrorMessage] = useState(false);
  const [sixthErrorMessage, setSixthErrorMessage] = useState(false);


  const [modalsOpen, setModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalFirstButton, setModalFirstButton] = useState("");


const showModal = 
<Modal className="my-modal" show={modalsOpen} onHide={() => setModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">

{modalFirstButton !== "" ?
          <button className="btn btn-primary" onClick={() => validateUpdatingUser()}>{modalFirstButton}</button> :
          null
        }
</ModalFooter>
</Modal>


// Créer un nouvel objet Date en utilisant les parties de la date
const dateObject = new Date(user.date_of_birth);

  const thisDate = new Date()
  thisDate.setFullYear(thisDate.getFullYear() - 18)
  const [startDate, setStartDate] = useState(dateObject);
  const [startDateChanged, setStartDateChanged] = useState(false);


  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const onSubmit = async e => {
    e.preventDefault();

    setModalTitle(t('Sponsorship.Update_personal_informations'));
    setModalBody(t('Sponsorship.Update_personal_informations_sure'));
    setModalFirstButton(t('Sponsorship.Update'));
    setModalIsOpen(true);
  };

  function validateUpdatingUser () {
    var date_of_birth = startDate
    updateUser({ id: userId, username, email, first_name, last_name, country, gender, date_of_birth, phone_number, address, additional_address, city, postal_code });
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
    setModalIsOpen(false)
  }


  function selectCountry (val) {
    setCountry(JSON.parse(val).index);
    setCountryLabel(val);
  }

  const dropDownSelected = event => {
    if (JSON.parse(event).index) {
    selectCountry(event)
    }
  }

  function handleGenderChange  (changeEvent) {
    //setGender(changeEvent.currentTarget.value);
    //setThirdErrorMessage(true)
}


function range(size, startAt) {
  return [...Array(size).keys()].map(i => startAt - i);
}


const maxThisDate = new Date()
maxThisDate.setFullYear(maxThisDate.getFullYear() - 18)
const minThisDate = new Date()
minThisDate.setFullYear(minThisDate.getFullYear() - 130)
minThisDate.getMonth(minThisDate.getMonth())


const years = range(113, thisDate.getYear() + 1900);
const months = [
  t('Sponsorship.January'),
  t('Sponsorship.February'),
  t('Sponsorship.March'),
  t('Sponsorship.April'),
  t('Sponsorship.May'),
  t('Sponsorship.June'),
  t('Sponsorship.July'),
  t('Sponsorship.August'),
  t('Sponsorship.September'),
  t('Sponsorship.October'),
  t('Sponsorship.November'),
  t('Sponsorship.December')
];

var finalDateCreation = ""
var dateBirth = ""
if (user.date_of_birth !== "") {
  dateBirth = user.date_of_birth.substring(0, 10).split("-")
  finalDateCreation = dateBirth[1] + '/' + dateBirth[2] + '/' + dateBirth[0];

  if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
    finalDateCreation = dateBirth[2] + '/' + dateBirth[1] + '/' + dateBirth[0];
  }
}

/*const ExampleCustomInput = ({ value, onClick }) => 
{
  const datearray = value.split("/");

  if (t('Language.Lang') === "Français") {
    value = datearray[1] + '/' + datearray[0] + '/' + datearray[2];
  }
  return(
      <button type="button" className="btn btn-info" onClick={onClick}>
  {value}
</button>);
};*/
  

    return loading && user === null ? (
        <Spinner />
      ) : (

    <Fragment>

<PostActions />
<div className="scrollme"> 
      <h1 className='large text-primary'>{t('Sponsorship.Profile')}</h1>
      <form className='form' onSubmit={e => onSubmit(e)}>
      <p className="h1">I. {t('Sponsorship.Connexion_identifier')}</p>
        <div className='form-group'>

    <label for="username">{t('Sponsorship.Username')}</label>
          <input
           className="form-control"
            type='text'
            placeholder={t('Sponsorship.Username')}
            name='username'
        //    value={username.replace(/\*/g, '')}
            value={username}
            readOnly = "readOnly"
            onFocus={() => setFirstErrorMessage(true)}
            onBlur={() => setFirstErrorMessage(false)}
          />
          {firstErrorMessage && (
          <div className="text-danger mt-2">{t('Sponsorship.Updating_credentials_forbidden')}</div>
        )}
        </div>
        <div className='form-group'>
        <label for="email">{t('Sponsorship.Email_address')}</label>
          <input
           className="form-control"
            type='email'
            placeholder={t('Sponsorship.Email_address')}
            name='email'
            value={email}
            onChange={e => onChange(e)}
            readOnly = "readOnly"
            onFocus={() => setSecondErrorMessage(true)}
            onBlur={() => setSecondErrorMessage(false)}
          />
          {secondErrorMessage && (
            <div className="text-danger mt-2">{t('Sponsorship.Updating_credentials_forbidden')}</div>
          )}
          <small className='form-text'>
          </small>
          </div>

        <p className="h1">II. {t('Sponsorship.Personal_informations')}</p>
        <div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
        <label style={{marginRight:"20px", marginTop: "10px"}}>
    <h4>
    {t('Sponsorship.Gender')} :
          </h4>
      </label>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="M" 
         id="male"
         className="custom-control-input"
                      checked={gender === 'M'} 
                      onChange={handleGenderChange}
            readOnly = "readOnly"
            onFocus={() => setThirdErrorMessage(true)}
            onBlur={() => setThirdErrorMessage(false)}
                      onF />
                      <label className="custom-control-label" for="male">
          {t('Sponsorship.Male')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="F" 
         id="female"
         className="custom-control-input"
                      checked={gender === 'F'} 
                      onChange={handleGenderChange}
            readOnly = "readOnly"
            onFocus={() => setThirdErrorMessage(true)}
            onBlur={() => setThirdErrorMessage(false)} />
                      <label className="custom-control-label" for="female">
          {t('Sponsorship.Female')}
      </label>
      </div>
      {thirdErrorMessage && (
            <div className="text-danger mt-2">{t('Sponsorship.Updating_credentials_forbidden')}</div>
          )}
    </div>

    
    <div className='form-group'>
    <label for="first_name">{t('Sponsorship.First_name')}</label>
          <input
            className="form-control"
            type='text'
            placeholder={t('Sponsorship.First_name')}
            name='first_name'
            value={first_name.replace(/[^a-zA-Z ]/g, '')}
            onChange={e => onChange(e)}
            readOnly = "readOnly"
            onFocus={() => setFourthErrorMessage(true)}
            onBlur={() => setFourthErrorMessage(false)}
          />
          {fourthErrorMessage && (
            <div className="text-danger mt-2">{t('Sponsorship.Updating_credentials_forbidden')}</div>
          )}
        </div>
        <div className='form-group'>
    <label for="last_name">{t('Sponsorship.Last_name')}</label>
          <input
            className="form-control"
            type='text'
            placeholder={t('Sponsorship.Last_name')}
            name='last_name'
            value={last_name.replace(/[^a-zA-Z ]/g, '')}
            onChange={e => onChange(e)}
            readOnly = "readOnly"
            onFocus={() => setFifthErrorMessage(true)}
            onBlur={() => setFifthErrorMessage(false)}
          />
          {fifthErrorMessage && (
            <div className="text-danger mt-2">{t('Sponsorship.Updating_credentials_forbidden')}</div>
          )}
        </div>

    <div className='form-group' style= {{
   display: "flex",
   alignItems: "center" }}>

          <h4 style={{marginRight:"20px"}}>
    {t('Register.Date_birth')} :
    </h4>

   {/* <button type="button" className="btn date-button">{finalDateCreation}</button> */}

     {/* <DatePicker
           className="form-control"
      disabled= "disabled"
      formatWeekDay={nameOfDay => 
       (
        (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ?
        nameOfDay.substr(0,3) === "Sun" ?
        t('Register.Sunday').substr(0,3) :
        nameOfDay.substr(0,3) === "Mon" ?
        t('Register.Monday').substr(0,3) :
        nameOfDay.substr(0,3) === "Tue" ?
        t('Register.Tuesday').substr(0,3) :
        nameOfDay.substr(0,3) === "Wed" ?
        t('Register.Wednesday').substr(0,3) :
        nameOfDay.substr(0,3) === "Thu" ?
        t('Register.Thursday').substr(0,3) :
        nameOfDay.substr(0,3) === "Fri" ?
        t('Register.Friday').substr(0,3) :
        nameOfDay.substr(0,3) === "Sat" ?
        t('Register.Saturday').substr(0,3)
        : nameOfDay.substr(0,3) : nameOfDay.substr(0,3)
       ) 
       /* nameOfDay.substr(0,3) *//*}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center"
          }}
        >
          <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>
          <select className="form-control"
            value={date.getYear() + 1900}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select className="form-control"
            value={months[date.getMonth()]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
        </div>
      )}
      selected={startDate}
      maxDate={maxThisDate}
      minDate={minThisDate}
      customInput={<DateButton />}
      onChange={date => {
        setStartDate(date);
        setStartDateChanged(true)
      }}
      
    />*/ }

<button type="button" className="btn date-button" onFocus={() => setSixthErrorMessage(true)} onBlur={() => setSixthErrorMessage(false)}>
{finalDateCreation}
          </button>
{/*<DatePicker
disabled= "disabled"
onClick={() => setSixthErrorMessage(true)}
onBlur={() => setSixthErrorMessage(false)}
      formatWeekDay={nameOfDay => 
       (
        (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ?
        nameOfDay.substr(0,3) === "Sun" ?
        t('Register.Sunday').substr(0,3) :
        nameOfDay.substr(0,3) === "Mon" ?
        t('Register.Monday').substr(0,3) :
        nameOfDay.substr(0,3) === "Tue" ?
        t('Register.Tuesday').substr(0,3) :
        nameOfDay.substr(0,3) === "Wed" ?
        t('Register.Wednesday').substr(0,3) :
        nameOfDay.substr(0,3) === "Thu" ?
        t('Register.Thursday').substr(0,3) :
        nameOfDay.substr(0,3) === "Fri" ?
        t('Register.Friday').substr(0,3) :
        nameOfDay.substr(0,3) === "Sat" ?
        t('Register.Saturday').substr(0,3)
        : nameOfDay.substr(0,3) : nameOfDay.substr(0,3)
       ) 
       }
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center"
          }}
        >
          <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>
          <select className="form-control"
            value={date.getYear() + 1900}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select className="form-control"
            value={months[date.getMonth()]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
        </div>
      )}
      selected={startDate}
      maxDate={maxThisDate}
      minDate={minThisDate}
      customInput={<DateButton />}
      onChange={date => {
        setStartDate(date);
        setStartDateChanged(true)
      }}
      
    />*/}
    {sixthErrorMessage && (
            <div className="text-danger mt-2">{t('Sponsorship.Updating_credentials_forbidden')}</div>
          )}
    </div>
        
    

        <div className='form-group'>

    <label for="phone_number">{t('Sponsorship.Phone_number')}</label>
          <input
           className="form-control"
            type='tel'
            placeholder={t('Sponsorship.Phone_number')}
            name='phone_number'
            value={phone_number.replace(/\D/,'')}
            onChange={e => onChange(e)}
          />
        </div>

        <div className='form-group'>
        <label for="address">{t('Sponsorship.Address')}</label>
          <input
           className="form-control"
            type='text'
            placeholder={t('Sponsorship.Address')}
            name='address'
            value={address.replace(/[^a-zA-Z0-9 ]/g, '')}
            onChange={e => onChange(e)}
          />
        </div>

        <div className='form-group'>
        <label for="additional_address">{t('Sponsorship.Additional_address')}</label>
          <input
           className="form-control"
            type='text'
            placeholder={t('Sponsorship.Additional_address') + " - (" + t('Register.Optional') + ")"}
            name='additional_address'
            value={additional_address.replace(/[^a-zA-Z0-9 ]/g, '')}
            onChange={e => onChange(e)}
          />
        </div>

        <div className='form-group'>
        <label for="city">{t('Sponsorship.City')}</label>
          <input
           className="form-control"
            type='text'
            placeholder={t('Sponsorship.City')}
            name='city'
            value={city.replace(/[^a-zA-Z0-9 ]/g, '')}
            onChange={e => onChange(e)}
          />
        </div>

        <div className='form-group'>
        <label for="postal_code">{t('Sponsorship.Postal_code')}</label>
          <input
           className="form-control"
            type='text'
            placeholder={t('Sponsorship.Postal_code')}
            name='postal_code'
            value={postal_code.replace(/[^a-zA-Z0-9 ]/g, '')}
            onChange={e => onChange(e)}
          />
        </div>




    <div className='form-group'>

    <label>{t('Sponsorship.Country')}</label>
<select className="form-control" name="action" value={countryLabel} onChange={e => dropDownSelected(e.target.value)}>
             <option disabled="disabled" selected="selected" value= {t(`{"index": "${user.country}"}`)}>{t(`Country.${user.country}`)}</option>
             <option value= {'{"index": "BE"}'}>{t('Country.BE')}</option>
             <option value= {'{"index": "FR"}'}>{t('Country.FR')}</option>
             <option value= {'{"index": "IT"}'}>{t('Country.IT')}</option>
             <option value= {'{"index": "LU"}'}>{t('Country.LU')}</option>
             <option value= {'{"index": "CH"}'}>{t('Country.CH')}</option>
             <option value= {'{"index": "GB"}'}>{t('Country.GB')}</option>
             <option value= {'{"index": "GP"}'}>{t('Country.GP')}</option>
             <option value= {'{"index": "GY"}'}>{t('Country.GY')}</option>
             <option value= {'{"index": "MQ"}'}>{t('Country.MQ')}</option>
             <option value= {'{"index": "RE"}'}>{t('Country.RE')}</option>
           </select>
        </div>

        


        <br/>

       <input type='submit' className='btn btn-block btn-primary' value={t('Sponsorship.Update')} /> 
      </form>


      {showModal}
      </div>
    </Fragment>
  );
};



      /*
        <CountryDropdown
          defaultOptionLabel= {t('Sponsorship.Select_country')}
          value={country}
          onChange={(val) => selectCountry(val)} />
           */

          SponsorshipProfile.propTypes = {
  getSponsorships: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  sponsorship: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    sponsorship: state.sponsorship,
    getSponsorships: PropTypes.func.isRequired
});

export default connect(
  mapStateToProps,
  { getSponsorships, setAlert, updateUser }
)(SponsorshipProfile);

