import React, { Fragment, useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import DashboardActions from './DashboardActions';
import Experience from './Experience';
import Education from './Education';
import { getSponsorships } from '../../actions/sponsorship';
import { getCurrentProfile, deleteAccount } from '../../actions/profile';
import { getArticles } from '../../actions/article';
import { useTranslation } from 'react-i18next';


import Button from 'react-bootstrap/esm/Button';

import styled from 'styled-components';
import * as moment from 'moment-timezone';

let ArrowUp, ArrowDown;

ArrowUp = ArrowDown = styled.svg`
  transition: all .5s linear;
`;

const Dashboard = ({
  getSponsorships,
  getCurrentProfile,
  deleteAccount,
  getArticles,
  auth: { user, theme },
  article: { articles, first_articles, /*articles_next,*/ articles_current },
  profile: { profile, loading }
}) => {

    //Only once
    const isInitialMount = useRef(true);
    const isInitialMountSecond = useRef(true);

  const [page, setPage] = useState(2);

  //const [hasMore, setHasMore] = useState(true)


  const [newArticles, setNewArticles] = useState([])

  const [showMore, setShowMore] = useState(false)
  

  /*const handleScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.currentTarget;
    console.log("scrollHeight " + scrollHeight)
    console.log("scrollTop " + scrollTop)
    console.log("clientHeight " + clientHeight)
    if (scrollHeight - scrollTop === clientHeight) {
      setPage(prev => prev + 1);
    }
  };*/


    useEffect(() => {
    if (user) {
      document.title = t('Login.Atrakxion');
    }
    else {
    document.title = t('Login.Home');
    }
      if (isInitialMount.current) {
  

         
         if (user && user._id) {

          isInitialMount.current = false;
            /* const loadArticles = async () => {
           getSponsorships(user._id, false, false, false);
       await getArticles(1, 5);
      };
      loadArticles();*/

          getSponsorships(user._id, false, false, false);
        /*  getArticles(1, 5);
          setNewArticles(articles) */
     //     getArticles(1, 5);
      //    getArticles(page, 5);
       //   getPartners(user._id);
        }
      }
    });



  const loadNewArticles = async () => {
    await setNewArticles(first_articles)
    await setNewArticles(prevArticles => {
      return [...new Set([...prevArticles, ...articles])]
    })
  }


   useEffect(() => {
     if (isInitialMountSecond.current && user) {
    //  if (!first_articles) {
        onPageChange()
    //  }
      /* else */ if (first_articles && articles) {
        isInitialMountSecond.current = false;
        loadNewArticles();
       }

     }
 });





    const { t } = useTranslation();

  const observer = useRef()
  const lastArticleElementRef = useCallback(node => {
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting /*&& hasMore*/) {
        setPage(page => page + 1)
      }
    })
    if (node) observer.current.observe(node)
  }, [/*hasMore*/])

  const onPageChange = useCallback(() => {
    const loadArticles = async () => {
      try {
        if (user) {
         await getArticles(page, 10, user.country, t('Language.Lang'));
    /*  console.log(newArticles[0] && newArticles[0].content)
      console.log(articles[0] && articles[0].content) */
     /* console.log("articles_current" + articles_current.page)
      console.log("page" + page)*/
     /* if (articles && articles[articles.length - 1] && articles[articles.length - 1]._id === newArticles[newArticles.length - 1]._id) {

      }
      else */if (articles && (articles_current && articles_current.page === page - 1)) {
        await setNewArticles(prevArticles => {
          return [...new Set([...prevArticles, ...articles])]
        })
      }
      /*else {
        setPage(page > 2 ? articles_current.page - 1 : 2)
      }*/
    }
  }
    catch (err) {
      console.log('erreur :', err);
    }
     // setOldArticles((prev) => [...prev, ...articles]);
    /* setHasMore(articles_next ? true : false) */
    };
      loadArticles();
  }, [page, articles_current, /*articles_next,*/ getArticles, articles, user, t]);

  useEffect(
    onPageChange
     /*console.log("page" + page)
     getArticles(page, 5);

     setNewArticles(prevArticles => {
      return [...new Set([...prevArticles, ...articles])]
    })
     setHasMore(articles_next ? true : false)*/
     
  , [page]);

  const onPageChangeLanguage = useCallback(() => {
    const loadArticles = async () => {
      try {
        await window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
        if (!isInitialMountSecond.current) {
             setPage(2)
           //  await onPageChange()
  }       
}
    catch (err) {
      console.log('erreur :', err);
    }
     // setOldArticles((prev) => [...prev, ...articles]);
    /* setHasMore(articles_next ? true : false) */
    };

    loadArticles();
  }, []);

   /* useEffect(() => {
      window.location.reload();
    }, [t]);*/



    useEffect(
      onPageChangeLanguage
    , [t]);


 const onFirstArticleChange = useCallback(() => {
  if (page === 2) {
    setNewArticles(first_articles)
    onPageChange()
  }
}, [first_articles, onPageChange, page]);

useEffect(
  onFirstArticleChange
, [first_articles]);

  useEffect(() => {
    getCurrentProfile();
  }, [getCurrentProfile]);


  var renderCards = null;
  /*var m1 = null;
  var m2 = null;
  var m3 = null;
  //var m4 = null;*/
  var numyears = 0;
  var nummonths = 0;
  var numweeks = 0;
  var numdays = 0;
  var numhours = 0;
  var numminutes = 0;
  var delta = null;
  var m5 = "";
  if (newArticles && newArticles.length > 0) {
    var event = new Date()
  //  var event = new Date('2022-01-13T20:00:00');
var utc = event.getTime() + (event.getTimezoneOffset() * 60000);
var nd = new Date(utc + (3600000*0)); // 0 For GB
var newNd = moment(nd);
    var newEvent = null
     //  m2 = moment(event.toLocaleString('en-GB', { timeZone: 'Europe/London' }), 'YYYY-MM-DD HH:mm'); 
    renderCards = newArticles.map((article, index) => {
       numyears = 0;
       nummonths = 0;
       numweeks = 0;
       numdays = 0;
       numhours = 0;
       numminutes = 0;
       delta = null;
      m5 = "";
       newEvent = new Date(article.date_creation);
   /*    m1 = moment(article.date_creation, 'YYYY-MM-DD HH:mm'); 
       m3 = m2.diff(m1,'minutes');
    //   m4 = m2.diff(m1,'h');  */
    var newNewEvent = moment(newEvent);

    // get total seconds between the times
 delta = Math.abs(nd - newEvent) / 1000;


 numyears = newNd.diff(newNewEvent, 'years');

 nummonths = (newNd.diff(newNewEvent, 'months') - (12 * numyears));


 // calculate (and subtract) whole weeks
 numweeks = Math.floor(delta / 604800);
delta -= numdays * 604800;




// calculate (and subtract) whole days
 numdays = Math.floor(delta / 86400) % 7;
delta -= numdays * 86400;

// calculate (and subtract) whole hours
 numhours = Math.floor(delta / 3600) % 24;
delta -= numhours * 3600;

// calculate (and subtract) whole minutes
 numminutes = Math.floor(delta / 60) % 60;
delta -= numminutes * 60;

// what's left is seconds
//var seconds = delta % 60;  // in theory the modulus is not required

  //  numdays = m1.diff(m2, 'days')
    //numdays = Math.floor(moment.duration(m2.diff(m1)).asDays())
     /*  numdays = Math.floor(m3 / 1440) < 0 ? Math.floor(m3 / 1440) + 6922 : Math.floor(m3 / 1440); 
       numhours = (Math.floor((m3 % 1440) / 60) + 24) === 24 ? 0 : Math.floor((m3 % 1440) / 60) + 24;  
       numminutes = (Math.floor((m3 % 1440) % 60) + 60) === 60 ? 0 : Math.floor((m3 % 1440) % 60) + 60; */
       if (numyears > 0) {
        m5 = m5 + (numyears + (numyears > 1 ? (" " + t('Dashboard.Years')) :  (" " + t('Dashboard.Year'))));
       if (nummonths > 0) {
        m5 = m5 + (', ' + (nummonths + (nummonths > 1 ? (" " + t('Dashboard.Months'))  :  (" " + t('Dashboard.Month')))))
       }
      }
      else {
       if (nummonths > 0) {
        m5 = m5 + ('' + (nummonths + (nummonths > 1 ? (" " + t('Dashboard.Months'))  :  (" " + t('Dashboard.Month')))))
      /*  if (numweeks > 0) {
          m5 = m5 + (', ' + (numweeks + (numweeks > 1 ? (" " + t('Dashboard.Weeks'))  :  (" " + t('Dashboard.Week')))))
        } */
       }
       else {
       if (numweeks > 0) {
        m5 = m5 + ('' + (numweeks + (numweeks > 1 ? (" " + t('Dashboard.Weeks'))  :  (" " + t('Dashboard.Week')))))

        if (numdays > 0) {
          m5 = m5 + (', ' + (numdays + (numdays > 1 ? (" " + t('Dashboard.Days'))  :  (" " + t('Dashboard.Day')))))
        }
       }
       else {
       if (numdays > 0) {
        m5 = m5 + ('' + (numdays + (numdays > 1 ? (" " + t('Dashboard.Days'))  :  (" " + t('Dashboard.Day')))))
        if (numhours > 0) {
          m5 = m5 + (', ' + (numhours + (t('Language.Lang') === "Italiano" ? "o" : "h")))
        }
       }
       else {
         if (numhours > 0) {
          m5 = m5 + ('' + (numhours + (t('Language.Lang') === "Italiano" ? "o" : "h")))
          if (numminutes > 0) {
        m5 = m5 + (', ' + (numminutes + "m"))
          }
         }
         else {
           if (numminutes > 0) {
            m5 = m5 + ('' + (numminutes + "m"))
           }
         }
      }  
    }
    }
    }
  //   m5 = (numyears > 0 ? (numyears + (numyears > 1 ? (" " + t('Dashboard.Years'))  :  (" " + t('Dashboard.Year')))) : "") + (numyears > 0 && nummonths > 0 ?  ", " : "") + (nummonths > 0 ? (nummonths + (nummonths > 1 ? (" " + t('Dashboard.Months'))  :  (" " + t('Dashboard.Month')))) : "") + (numyears < 1 && nummonths > 0 && numweeks > 0 ?  ", " : "") + (numyears < 1 && numweeks > 0 ? (numweeks + (numweeks > 1 ? (" " + t('Dashboard.Weeks'))  :  (" " + t('Dashboard.Week')))) : "") + (numweeks > 0 && numdays > 0 ?  ", " : "") + (numdays > 0 ? (numdays + (numdays > 1 ? (" " + t('Dashboard.Days'))  :  (" " + t('Dashboard.Day')))) : "") + ((numweeks < 1 && numhours > 0) ? ( (numdays > 0 ? ", " : "") + numhours + (t('Language.Lang') === "Italiano" ? "o" : "h")) : "") + ((numdays < 1 && numweeks < 1 && numminutes > 0) ? ( ", " + numminutes +"m") : "");
      if (newArticles.length === index + 1) {
      return(
<div key={index} style={{ overflow: "auto", display: "grid"}} className="mb-1" /*onScroll={handleScroll}*/ ref={lastArticleElementRef}>
        <Fragment>
      <div  className={`card" ${theme === "light" ? "text-white bg-secondary" : "text-white bg-secondary"}`}/* text-center*/>
  <div className="card-header" style={{ maxHeight: 85, overflowY: 'scroll' }}>
  <div className="page-header">
  <div className="float-left">
  <h3>{article.title}</h3>
  </div>
  <div className="float-right">
  <p className="text-right">

    {
        t('Dashboard.Since') + " " + m5
    }
  </p>
  </div>
  <div className="clearfix"></div>
</div>
  </div>
  <div className="card-body">
    {/*<h5 className="card-title">
  {article.title}</h5>*/}
    <div style={{ maxHeight: 50, overflowY: 'scroll' }}>
                  <div className="card-text ql-editor" dangerouslySetInnerHTML={{ __html: article.content }} />
  </div></div>
  <div className="card-footer text-muted">
    <Link to={`/article-detail/${article._id}`} className="btn btn-primary btn-block">
        <i className='fas fa-graduation-cap text-primary' /> {t('Dashboard.Show_more')}
      </Link>
  </div>

</div>
          <br/><br/>
        </Fragment>
        </div>)
  }
 else {
  return(
    
    <div key={index} className="mb-1" style={{ overflow: "auto", display: "grid"}} /*onScroll={handleScroll}*/>
        <Fragment>
      <div  className={`card" ${theme === "light" ? "text-white bg-secondary" : "text-white bg-secondary"}`}/* text-center*/>
  <div className="card-header" style={{ maxHeight: 85, overflowY: 'scroll' }}>
  <div className="page-header">
  <div className="float-left">
  <h3>{article.title}</h3>
  </div>
  <div className="float-right">
  <p className="text-right">

    {
        t('Dashboard.Since') + " " + m5
    }
  </p>
  </div>
  <div className="clearfix"></div>
</div>
  </div>
  <div className="card-body">
    {/*<h5 className="card-title">
  {article.title}</h5>*/}
    <div style={{ maxHeight: 50, overflowY: 'scroll' }}>
                  <div className="card-text ql-editor" dangerouslySetInnerHTML={{ __html: article.content }} />
  </div></div>

  <div className="card-footer text-muted">
    <Link to={`/article-detail/${article._id}`} className="btn btn-primary btn-block">
        <i className='fas fa-graduation-cap text-primary' /> {t('Dashboard.Show_more')}
      </Link>
  </div>


</div>
          <br/><br/>
        </Fragment>
        </div>
  )
}
})
  }


  return loading && profile === null ? (
    <Spinner />
  ) : (
    <Fragment>
      <h1 className='large text-primary'>{t('Dashboard.Dashboard')}</h1>
      <p className='lead'>
        <i className='fas fa-user' /> {t('Dashboard.Welcome')} {user && user.username}
  </p>

{t('Language.Lang') === "English" ?
  <div style={{ overflow: "auto", display: "grid"}} className="mb-1" /*onScroll={handleScroll}*/>
        <Fragment>
      <div  className="card text-white bg-secondary"/* text-center*/>







 



  <div className="card-header" style={{ maxHeight: 85, overflowY: 'scroll' }}>
  <div className="page-header">
  <div className="float-left">
  <h3>WELCOME MESSAGE</h3>
  </div>
  <div className="float-right">
  <p className="text-right">

   

    <div onClick={() => setShowMore(!showMore)} style={{cursor: "pointer"}}>
    {   showMore ?
        <ArrowDown xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" /*stroke="#212121"*/ strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></ArrowDown>
        :
        <ArrowUp xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" /*stroke="#212121"*/ strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-up"><polyline points="18 15 12 9 6 15"></polyline></ArrowUp>
      }
        </div>
   
  </p>
  </div>
  <div className="clearfix"></div>
</div>
  </div>
  <div className="card-body">
    {/*<h5 className="card-title">
  {article.title}</h5>*/}
    <div style={{ maxHeight: showMore ? "" : 50, overflowY: 'scroll' }}>
                  <div className="card-text ql-editor" dangerouslySetInnerHTML={{ __html: "<p>Dear subscribers,</p><p>We are delighted to welcome you to our community.</p><p>We are counting on your commitment and dynamism to ensure that this space evolves in the best possible conditions, in a spirit of sharing, exchange and solidarity.</p><p>We assure you that we will do our best to ensure that human values are a priority within our network.</p><p>Thank you for your contribution</p><p>Our best regards to you all</p>" }} />
  </div></div>
<div className="card-footer text-muted">
{<Button onClick={() => setShowMore(!showMore)} className="btn btn-primary btn-block">
<i className='fas fa-graduation-cap text-primary' /> {showMore ? t('Dashboard.Show_less') : t('Dashboard.Show_more')}
</Button>}

</div>

</div>
          <br/><br/>
        </Fragment>
        </div>
:

t('Language.Lang') === "Français" ?
<div style={{ overflow: "auto", display: "grid"}} className="mb-1" /*onScroll={handleScroll}*/>
<Fragment>
<div  className="card text-white bg-secondary"/* text-center*/>
<div className="card-header" style={{ maxHeight: 85, overflowY: 'scroll' }}>
<div className="page-header">
  <div className="float-left">
<h3>Message de bienvenue</h3>
  </div>
  <div className="float-right">
  <p className="text-right">

   

    <div onClick={() => setShowMore(!showMore)} style={{cursor: "pointer"}}>
    {   showMore ?
        <ArrowDown xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" /*stroke="#212121"*/ strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></ArrowDown>
        :
        <ArrowUp xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" /*stroke="#212121"*/ strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-up"><polyline points="18 15 12 9 6 15"></polyline></ArrowUp>
      }
        </div>
   
  </p>
  </div>
  <div className="clearfix"></div>
</div>
</div>
<div className="card-body">
{/*<h5 className="card-title">
{article.title}</h5>*/}
<div style={{ maxHeight: showMore ? "" : 50, overflowY: 'scroll' }}>
          <div className="card-text ql-editor" dangerouslySetInnerHTML={{ __html: "<p>Chers abonnés,</p><p>Nous sommes heureux de vous accueillir au sein de notre communauté.</p><p>Nous comptons sur votre engagement et votre dynamisme pour que cet espace évolue dans les meilleures conditions possibles, dans un esprit de partage, d'échange et de solidarité.</p><p>Nous vous assurons que nous mettrons tout en œuvre pour que les valeurs humaines soient une priorité au sein de notre réseau.</p><p>Nous vous remercions de votre contribution</p><p>Nos meilleures salutations à vous tous</p>" }} />
</div></div>


<div className="card-footer text-muted">
{<Button onClick={() => setShowMore(!showMore)} className="btn btn-primary btn-block">
<i className='fas fa-graduation-cap text-primary' /> {showMore ? t('Dashboard.Show_less') : t('Dashboard.Show_more')}
</Button>}

</div>
</div>
  <br/><br/>
</Fragment>
</div>
:

t('Language.Lang') === "Italiano" ?
<div style={{ overflow: "auto", display: "grid"}} className="mb-1" /*onScroll={handleScroll}*/>
<Fragment>
<div  className="card text-white bg-secondary"/* text-center*/>
<div className="card-header" style={{ maxHeight: 85, overflowY: 'scroll' }}>


<div className="page-header">
  <div className="float-left">
<h3>MESSAGGIO DI BENVENUTO</h3>
  </div>
  <div className="float-right">
  <p className="text-right">

   

    <div onClick={() => setShowMore(!showMore)} style={{cursor: "pointer"}}>
    {   showMore ?
        <ArrowDown xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" /*stroke="#212121"*/ strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-down"><polyline points="6 9 12 15 18 9"></polyline></ArrowDown>
        :
        <ArrowUp xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" /*stroke="#212121"*/ strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-up"><polyline points="18 15 12 9 6 15"></polyline></ArrowUp>
      }
        </div>
   
  </p>
  </div>
  <div className="clearfix"></div>
</div>
</div>
<div className="card-body">
{/*<h5 className="card-title">
{article.title}</h5>*/ /*</p><p><br></p><p>*/}
<div style={{ maxHeight: showMore ? "" : 50, overflowY: 'scroll' }}>
          <div className="card-text ql-editor" dangerouslySetInnerHTML={{ __html: "<p>Cari abbonati,</p><p>Siamo lieti di darvi il benvenuto nella nostra comunità.</p><p>Contiamo sul vostro impegno e sul vostro dinamismo per far sì che questo spazio si evolva nelle migliori condizioni possibili, in uno spirito di condivisione, scambio e solidarietà.</p><p>Vi assicuriamo che faremo del nostro meglio affinché i valori umani siano una priorità all'interno della nostra rete.</p><p>Grazie per il vostro contributo</p><p>I nostri migliori saluti a tutti voi</p>" }} />
</div></div>

<div className="card-footer text-muted">
{<Button onClick={() => setShowMore(!showMore)} className="btn btn-primary btn-block">
<i className='fas fa-graduation-cap text-primary' /> {showMore ? t('Dashboard.Show_less') : t('Dashboard.Show_more')}
</Button>}

</div>
</div>
  <br/><br/>
</Fragment>
</div>
: null

}
                {renderCards}
      {profile !== null ? (
        <Fragment>
          <DashboardActions />
          <Experience experience={profile.experience} />
          <Education education={profile.education} />

          <div className='my-2'>
            <button className='btn btn-danger' onClick={() => deleteAccount(t('Server.server_20_profile'))}>
              <i className='fas fa-user-minus' /> {t('Dashboard.Delete_account')}
            </button>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          {/* <p>{t('Dashboard.Subtitle')}</p>
          <Link to='/create-profile' className='btn btn-primary my-1'>
          {t('Dashboard.Create_profile')}
          </Link>*/}
        </Fragment>
      )}

    </Fragment>
  );
};

Dashboard.propTypes = {
  getSponsorships: PropTypes.func.isRequired,
  getCurrentProfile: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  getArticles: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  article: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  profile: state.profile,
  article: state.article
});

export default connect(
  mapStateToProps,
  { getSponsorships, getCurrentProfile, deleteAccount, getArticles }
)(Dashboard);
