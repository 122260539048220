import {  axios } from '../utils/axios';
import { setAlert } from './alert';
import { login } from './auth';
import {
  ADD_USER_SUCCESS,
  ADD_USER_FAIL,
  UPDATE_USER_SUCCESS,
  USER_LOADED,
  UPDATE_USER_FAIL,
  EDIT_PROFILE_SUCCESS,
  EDIT_PROFILE_FAIL,
  GET_SPONSORSHIPS,
  GET_LEVEL_AFFILIATION,
  GET_LEVEL_AFFILIATION_REPOSITIONED,
  GET_LEVEL_AFFILIATION_OTHER,
  GET_LEVEL_AFFILIATION_REPOSITIONED_OTHER,
  GET_SPONSORSHIPS_OTHER,
  GET_LEVEL_AFFILIATION_OLD,
  GET_LEVEL_AFFILIATION_REPOSITIONED_OLD,
  GET_BONUS_GENEALOGY_OLD,
  GET_DELETED_AFFILIATION,
  GET_FULL_MATRIX,
  GET_OLD_USER,
  GET_CANDIDATE_WARNING,
  GET_BONUS_GENEALOGY,
  GET_BONUS_GENEALOGY_OTHER,
  GET_OLD_USERS,
  UPDATE_STATUS,
  UPDATE_TOKEN,
  SPONSORSHIP_ERROR,
  UPDATE_SPONSORSHIPS,
  GET_GENEALOGIES,
  GET_GENEALOGIES_THIS_MONTH,
  GET_BONUS,
  GET_GENEALOGIES_OTHER,
  GET_GENEALOGIES_THIS_MONTH_OTHER,
  GET_BONUS_OTHER,
  GET_GENEALOGIES_OLD,
  GET_GENEALOGIES_THIS_MONTH_OLD,
  GET_BONUS_OLD,
  GENEALOGY_ERROR,
  GET_PARTNERS,
  PARTNER_ERROR,
  GET_ADMIN_PARTNERS,
  ADMIN_PARTNER_ERROR,
  DELETE_USER,
  DELETE_USER_ERROR,
  ALL_USERS,
  ALL_USERS_ERROR,
  SHOW_LOADING_API,
  HIDE_LOADING_API,
  LOGOUT,
  REPOSITIONING_USER,
  REPOSITIONING_USER_ERROR,
  PAYED,
  PAYED_ERROR,
  ADD_RIB,
  ADD_RIB_ERROR,
  RIBS,
  RIBS_ERROR,
  DELETE_RIB,
  DELETE_RIB_ERROR,
  UPDATE_RIB,
  UPDATE_RIB_ERROR,
  UPDATE_MONTH,
  UPDATE_MONTH_ERROR,
  TRANSACTIONS,
  VALIDATE_INVOICE,
  VALIDATE_INVOICE_ERROR,
  VALIDATE_PAYMENT,
  VALIDATE_PAYMENT_ERROR,
  ACCEPT_MEMBERSHIP,
  ACCEPT_MEMBERSHIP_ERROR,
  REGISTER_USER_MEMBER,
  REGISTER_USER_MEMBER_ERROR,
  PAY_AFFILIATION_PROGRAM,
  PAY_AFFILIATION_PROGRAM_ERROR,
  GET_AFFILIATION_COMMISSION_NUMBER_YEAR,
  GET_AFFILIATION_COMMISSION_NUMBER_YEAR_OTHER,
  GET_CHATS,
} from './types';


// Add User
export const addUser = ({ oldUserId, username, first_name, last_name, country, gender, date_of_birth, email, password, phone_number, address, additional_address, city, postal_code, attachmentType, imgToSendFront, imgToSendBack, history }) => async dispatch => {

  dispatch({
    type: SHOW_LOADING_API
  });
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  //const body = JSON.stringify({ username, first_name, last_name, country, gender, date_of_birth, email, password, nationality, phone_number, address, additional_address, city, postal_code, img });

  const body = new FormData();
  body.append("username", username);
  body.append("first_name", first_name);
  body.append("last_name", last_name);
  body.append("country", country);
  body.append("gender", gender);
  body.append("date_of_birth", date_of_birth);
  body.append("email", email);
  body.append("password", password);
  body.append("phone_number", phone_number);
  body.append("address", address);
  body.append("additional_address", additional_address);
  body.append("city", city);
  body.append("postal_code", postal_code);
  body.append("attachment_type", attachmentType);
  body.append("imgFront", imgToSendFront);
  body.append("imgBack", imgToSendBack);
  try {
    const res = await axios.post(`/api/users/add_user/${oldUserId}`, body, config);

    dispatch({
      type: ADD_USER_SUCCESS,
      payload: res.data
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch(setAlert('server_10_user', 'success'));

    history.push('/my-partners');
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    /*else {
      dispatch(setAlert('server_39_user', 'danger'));
    }*/

    dispatch({
      type: ADD_USER_FAIL
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  }
};


// Update User
export const updateUser = ({ id, first_name, last_name, country, gender, date_of_birth, phone_number, address, additional_address, city, postal_code }) => async dispatch => {
  dispatch({
    type: SHOW_LOADING_API
  });
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = JSON.stringify({ id, first_name, last_name, country, gender,
    date_of_birth, phone_number, address, additional_address, city, postal_code });
  try {
    const res = await axios.post('/api/users/update_user/', body, config);

    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: res.data
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({
      type: USER_LOADED,
      payload: res.data
    });
    dispatch(setAlert('server_40_user', 'success'));
  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }
    dispatch({
      type: UPDATE_USER_FAIL
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  }
};



// Edit Profile
export const editProfile = ({ userId, email, phone_number, address, additional_address, city, postal_code }) => async dispatch => {

  dispatch({
    type: SHOW_LOADING_API
  });
  const config = {
    headers: {
      'Content-Type': 'application/json'
    }
  };

  const body = new FormData();
  body.append("email", email);
  body.append("phone_number", phone_number);
  body.append("address", address);
  body.append("additional_address", additional_address);
  body.append("city", city);
  body.append("postal_code", postal_code);
  try {
    const res = await axios.post(`/api/users/edit_profile/${userId}`, body, config);

    dispatch({
      type: EDIT_PROFILE_SUCCESS,
      payload: res.data
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch(setAlert('server_10_user', 'success'));

  } catch (err) {
    const errors = err.response.data.errors;

    if (errors) {
      errors.forEach(error => dispatch(setAlert(error.msg, 'danger')));
    }

    dispatch({
      type: EDIT_PROFILE_FAIL
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  }
};

  // Get sponsorship
export const getSponsorships = (id, old_user = false, admin_user = false, show_loading = true) => async dispatch => {

  if (show_loading) {
    dispatch({
      type: SHOW_LOADING_API
    });
  }


  dispatch({
    type: GET_LEVEL_AFFILIATION_REPOSITIONED,
    payload: null
  });
  dispatch({
    type: GET_LEVEL_AFFILIATION_REPOSITIONED_OTHER,
    payload: null
  });
  dispatch({
    type: GET_LEVEL_AFFILIATION_REPOSITIONED_OLD,
    payload: null
  });
   
    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
    
      const body = JSON.stringify({ id : id });

    
    try {
      const res = await axios.post('/api/users/sponsorship/', body, config);

     // var arrayObj = JSON.stringify(res.data.descendants);

      var arrayObj = res.data.descendants.map(item => {
          return {
            id: item.user,
            new_id: item.new_id,
            parent: 1,
            name: item.username,
            parent_id: item.parent_id,
            parent_username: item.parent_username,
            first_name: item.first_name,
            last_name: item.last_name,
            country: item.country,
            gender: item.gender,
            date_of_birth: item.date_of_birth,
            status: item.status,
            verticalLevel: item.verticalLevel,
            horizontalLevel: item.horizontalLevel,
            date_creation: item.date_creation,
            date_member: item.date_member,
            date_repositioning: item.date_repositioning,
            payed_status: item.payed_status,
            confirmed: item.confirmed,
            children: item.childs
          };
        });
          if (admin_user) {
          dispatch({
            type: GET_BONUS_GENEALOGY_OTHER,
            payload: res.data.bonus_genealogy
          });
          dispatch({
            type: GET_LEVEL_AFFILIATION_OTHER,
            payload: res.data.levelAffiliation
          });
          dispatch({
            type: GET_LEVEL_AFFILIATION_REPOSITIONED_OTHER,
            payload: res.data.levelAffiliationRepositioned
          });
          dispatch({
            type: GET_SPONSORSHIPS_OTHER,
            payload: arrayObj
          });
          }
          else if (old_user) {
            dispatch({
              type: GET_BONUS_GENEALOGY_OLD,
              payload: res.data.bonus_genealogy
            });
            dispatch({
              type: GET_LEVEL_AFFILIATION_OLD,
              payload: res.data.levelAffiliation
            });
            dispatch({
              type: GET_LEVEL_AFFILIATION_REPOSITIONED_OLD,
              payload: res.data.levelAffiliationRepositioned
            });
          }
          else {
          dispatch({
            type: GET_SPONSORSHIPS,
            payload: arrayObj
          });
        dispatch({
          type: GET_BONUS_GENEALOGY,
          payload: res.data.bonus_genealogy
        });
        dispatch({
          type: GET_LEVEL_AFFILIATION,
          payload: res.data.levelAffiliation
        });
        dispatch({
          type: GET_LEVEL_AFFILIATION_REPOSITIONED,
          payload: res.data.levelAffiliationRepositioned
        });
        dispatch({
          type: GET_DELETED_AFFILIATION,
          payload: res.data.deletedAffiliation
        });
        dispatch({
          type: GET_FULL_MATRIX,
          payload: res.data.fullMatrix
        });
        dispatch({
          type: GET_OLD_USER,
          payload: res.data.oldUser
        });
        dispatch({
          type: UPDATE_STATUS,
          payload: res.data.item
        });
        dispatch({
          type: UPDATE_TOKEN,
          payload: res.data.token
        });
        dispatch({
          type: GET_CANDIDATE_WARNING,
          payload: res.data.candidateWarning
        });
        dispatch({
          type: GET_OLD_USERS,
          payload: res.data.users
        });
        dispatch({
          type: GET_CHATS,
          payload: res.data.chats
        });
          }

    /*  dispatch({
        type: HIDE_LOADING_API
      });*/
    } catch (err) {
      dispatch({
        type: SPONSORSHIP_ERROR,
     //   payload: { msg: err.response.statusText, status: err.response.status }
      }); 
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch({ type: LOGOUT });
    }
  };


  // Add like
export const updateSponsorship = (id, parent, searchData) => async dispatch => {

  dispatch({
    type: SHOW_LOADING_API
  });

    const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
    
      const body = JSON.stringify({ id });
    try {
      const res = await axios.post('/api/users/sponsorship/', body, config);

     // var arrayObj = JSON.stringify(res.data.descendants);

        var arrayObj = res.data.descendants.map(item => {
        return {
          id: item.user,
          new_id: item.new_id,
          parent: parent,
          name: item.username,
          parent_id: item.parent_id,
          parent_username: item.parent_username,
          first_name: item.first_name,
          last_name: item.last_name,
          country: item.country,
          gender: item.gender,
          date_of_birth: item.date_of_birth,
          status: item.status,
          verticalLevel: item.verticalLevel,
          horizontalLevel: item.horizontalLevel,
          children: item.childs
        };
      });

      function find(searchData, target, accum=[]){
        target.forEach((f)=>{
          if(f.children){
            find(searchData, f.children, accum)
          }
          if(f.id.includes(searchData)){
            var obj = {}
            obj.f = f;
            obj.f.children = arrayObj;
            
          accum.push(obj);
          }
        });
        return accum;
      }
      find(id, searchData)
      //DO NOT DELETE THIS console.log
    //  console.log("cc"+JSON.stringify(find(id, searchData)));


    dispatch({
        type: UPDATE_SPONSORSHIPS,
        payload: { id, childs: arrayObj }
      });
      dispatch({
        type: HIDE_LOADING_API
      });
    } catch (err) {
      dispatch({
        type: SPONSORSHIP_ERROR,
      //  payload: { msg: err.response.statusText, status: err.response.status }
      });
      dispatch({
        type: HIDE_LOADING_API
      });
      dispatch({ type: LOGOUT });
    }
  };



    // Get genealogy
export const getGenealogies = (id, old_user = false, admin_user = false) => async dispatch => {
  dispatch({
    type: SHOW_LOADING_API
  });


  dispatch({
    type: GET_BONUS,
    payload: null
  });
  dispatch({
    type: GET_BONUS_OTHER,
    payload: null
  });
  dispatch({
    type: GET_BONUS_OLD,
    payload: null
  });
   

  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    const body = JSON.stringify({ id : id });

    
  
  try {
    const res = await axios.post('/api/users/genealogy/', body, config);

    if (admin_user) {
          dispatch({
      type: GET_GENEALOGIES_OTHER,
      payload: res.data.stack
    });
    dispatch({
      type: GET_GENEALOGIES_THIS_MONTH_OTHER,
      payload: res.data.stackThisMonth
    });
    dispatch({
      type: GET_BONUS_OTHER,
      payload: res.data.bonus
    });
    dispatch({
      type: GET_AFFILIATION_COMMISSION_NUMBER_YEAR_OTHER,
      payload: res.data.affiliations_commissions_number_year
    });
    }
    else if (old_user) {
      dispatch({
        type: GET_GENEALOGIES_OLD,
        payload: res.data.stack
      });
      dispatch({
        type: GET_GENEALOGIES_THIS_MONTH_OLD,
        payload: res.data.stackThisMonth
      });
      dispatch({
        type: GET_BONUS_OLD,
        payload: res.data.bonus
      });
    }
    
    else {
      dispatch({
        type: GET_GENEALOGIES,
        payload: res.data.stack
      });
      dispatch({
        type: GET_GENEALOGIES_THIS_MONTH,
        payload: res.data.stackThisMonth
      });
      dispatch({
        type: GET_BONUS,
        payload: res.data.bonus
      });
      dispatch({
        type: GET_AFFILIATION_COMMISSION_NUMBER_YEAR,
        payload: res.data.affiliations_commissions_number_year
      });
  }
    dispatch({
      type: HIDE_LOADING_API
    });

  } catch (err) {
    dispatch({
      type: GENEALOGY_ERROR,
    //  payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};


// Get partner
export const getPartners = (id) => async dispatch => {

  dispatch({
    type: SHOW_LOADING_API
  });
   
  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    const body = JSON.stringify({ id : id });

    
  
  try {
    const res = await axios.post('/api/users/partner/', body, config);

    dispatch({
      type: GET_PARTNERS,
      payload: res.data
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: PARTNER_ERROR,
   //   payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};




// Get admin partner
export const getAdminPartners = (id) => async dispatch => {

  dispatch({
    type: SHOW_LOADING_API
  });
   
  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    const body = JSON.stringify({ id : id });

    
  
  try {
    const res = await axios.post('/api/users/admin_partners/', body, config);


    dispatch({
      type: GET_ADMIN_PARTNERS,
      payload: res.data
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: ADMIN_PARTNER_ERROR,
   //   payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};


// Delete user
export const deleteUser = (id, admin_id, switchTree, selectedUserId, deletionCause) => async dispatch => {
   
  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    const body = JSON.stringify({ id : id, deletion_cause : deletionCause });

    const body1 = JSON.stringify({ id : selectedUserId });

    const body2 = JSON.stringify({ id : admin_id });
  
  try {
    const res = await axios.post('/api/users/delete_user/', body, config);

    if (res) {
      if (switchTree === 1) {
        const res1 = await axios.post('/api/users/admin_partners/', body1, config);

        dispatch({
          type: GET_ADMIN_PARTNERS,
          payload: res1.data
        });
      }

      else if (switchTree === 3) {

        const res3 = await axios.post('/api/transactions/get_transactions/', body1, config);

        dispatch({
          type: TRANSACTIONS,
          payload: res3.data
        });

      }



        const res2 = await axios.post('/api/users/all_users/', body2, config);

        dispatch({
          type: ALL_USERS,
          payload: res2.data.users
        });


   /* const res3 = await axios.post('/api/users/partner/', body1, config);


    dispatch({
      type: GET_PARTNERS,
      payload: res3.data
    }); */
    }
    dispatch({
      type: DELETE_USER,
      payload: res.data
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: DELETE_USER_ERROR,
   //   payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};


// Get all users
export const getAllUsers = (id) => async dispatch => {
   
  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const body = JSON.stringify({ id : id });
  
  try {
    const res = await axios.post('/api/users/all_users/', body, config);
    dispatch({
      type: ALL_USERS,
      payload: res.data.users
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: ALL_USERS_ERROR,
  //    payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};

// Repositioning user
export const repositioningUser = (id) => async dispatch => {

  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const body = JSON.stringify({ id : id });
  
  try {
    const res = await axios.post('/api/users/repositioning/', body, config);

    dispatch({
      type: REPOSITIONING_USER,
      payload: res.data
    });

    dispatch(login(res.data.email, res.data.password, true));

    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: REPOSITIONING_USER_ERROR,
    //  payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};


// Pay
export const pay = (id) => async dispatch => {
  
  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const body = JSON.stringify({ id : id });
  
  try {
    const res = await axios.post('/api/users/pay/', body, config);
    dispatch({
      type: PAYED,
      payload: res.data.client_secret
    });

    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: PAYED_ERROR,
  //    payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};




// Pay
export const paySucceded = (id) => async dispatch => {
  
  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const body = JSON.stringify({ id : id });
  
  try {
    const res = await axios.post('/api/users/pay_succeded/', body, config);

    dispatch({
      type: UPDATE_STATUS,
      payload: res.data.item
    });

    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};


// Add rib
export const addRib = (card_holder, iban, bic, domiciliation) => async dispatch => {
  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const body = JSON.stringify({ card_holder, iban, bic, domiciliation });
  try {
    const res = await axios.post('/api/ribs/add_rib/', body, config);

    dispatch({
      type: ADD_RIB,
      payload: res.data
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch(setAlert('server_9_rib', 'success'));
  } catch (err) {
      dispatch(setAlert(err.response.data.msg, 'danger'));
    
    dispatch({
      type: ADD_RIB_ERROR
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  }
};


// Get ribs
export const getRibs = (id) => async dispatch => {
   
  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const body = JSON.stringify({ id : id });
  
  try {
    const res = await axios.post('/api/ribs/get_ribs/', body, config);
    dispatch({
      type: RIBS,
      payload: res.data
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: RIBS_ERROR,
 //     payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};


// Delete rib
export const deleteRib = (id, selected_rib_id) => async dispatch => {
   
  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    const body = JSON.stringify({ id : selected_rib_id });

    const body1 = JSON.stringify({ id : id });
  
  try {
    const res = await axios.post('/api/ribs/delete_rib/', body, config);

    if (res) {

        const res1 = await axios.post('/api/ribs/get_ribs/', body1, config);

        dispatch({
          type: RIBS,
          payload: res1.data
        });
    }
    dispatch({
      type: DELETE_RIB,
      payload: res.data
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: DELETE_RIB_ERROR,
 //     payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};


// Update rib
export const updateRib = (id, selected_rib_id, selected_rib_card_holder, selected_rib_iban, selected_rib_bic, selected_rib_domiciliation) => async dispatch => {
  dispatch({
    type: SHOW_LOADING_API
  });
  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const body = JSON.stringify({ id : selected_rib_id, card_holder : selected_rib_card_holder, iban : selected_rib_iban, bic : selected_rib_bic, domiciliation : selected_rib_domiciliation });

    const body1 = JSON.stringify({ id : id });

  try {
    const res = await axios.post('/api/ribs/update_rib/', body, config);

    if (res) {

      const res1 = await axios.post('/api/ribs/get_ribs/', body1, config);

      dispatch({
        type: RIBS,
        payload: res1.data
      });
  }

    dispatch({
      type: UPDATE_RIB,
      payload: res.data
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch(setAlert('server_11_rib', 'success'));
  } catch (err) {
    
    dispatch(setAlert(err.response.data.msg, 'danger'));
    
    dispatch({
      type: UPDATE_RIB_ERROR
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  }
};



// Update month
export const updateMonth = (month) => async dispatch => {
  dispatch({
    type: SHOW_LOADING_API
  });
  try {
      dispatch({
        type: UPDATE_MONTH,
        payload: month
      });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: UPDATE_MONTH_ERROR,
 //     payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};



// Validate invoice
export const validateInvoice = (id, admin_id) => async dispatch => {
   
  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    const body = JSON.stringify({ id : id });

    const body2 = JSON.stringify({ id : admin_id });
  
  try {
    const res = await axios.post('/api/users/validate_invoice/', body, config);

    if (res) {



        const res2 = await axios.post('/api/users/all_users/', body2, config);

        dispatch({
          type: ALL_USERS,
          payload: res2.data.users
        });
    }
    dispatch({
      type: VALIDATE_INVOICE,
      payload: res.data
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: VALIDATE_INVOICE_ERROR,
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};



// Validate payment
export const validatePayment = (id, admin_id) => async dispatch => {
   
  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    const body = JSON.stringify({ id : id });

    const body2 = JSON.stringify({ id : admin_id });
  
  try {
    const res = await axios.post('/api/users/validate_payment/', body, config);

    if (res) {



        const res2 = await axios.post('/api/users/all_users/', body2, config);

        dispatch({
          type: ALL_USERS,
          payload: res2.data.users
        });
    }
    dispatch({
      type: VALIDATE_PAYMENT,
      payload: res.data
    });
    dispatch({
      type: HIDE_LOADING_API
    });
  } catch (err) {
    dispatch({
      type: VALIDATE_PAYMENT_ERROR,
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};


// Pay
export const accept_membership = (id) => async dispatch => {
  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const body = JSON.stringify({ id : id });
  
  try {
    const res = await axios.post('/api/users/accept_membership/', body, config);
    dispatch({
      type: ACCEPT_MEMBERSHIP,
      payload: res.data.item
    });

    dispatch({
      type: USER_LOADED,
      payload: res.data.item
    });
    dispatch({
      type: HIDE_LOADING_API
    });


    dispatch(setAlert('server_39_user', 'success'));
  } catch (err) {
    dispatch({
      type: ACCEPT_MEMBERSHIP_ERROR,
  //    payload: { msg: err.response.statusText, status: err.response.status }
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};



// Register user member
export const registerUserMember = (id) => async dispatch => {
   
  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    const body = JSON.stringify({ id : id });
  
  try {
    const res = await axios.post('/api/users/register_user_member/', body, config);

    dispatch({
      type: REGISTER_USER_MEMBER,
      payload: res.data
    });
    dispatch({
      type: HIDE_LOADING_API
    });

    dispatch(setAlert('server_41_user', 'success'));

  } catch (err) {
    dispatch({
      type: REGISTER_USER_MEMBER_ERROR,
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};




// Pay affiliation program
export const payAffiliationProgram = (parendId, id) => async dispatch => {
   
  dispatch({
    type: SHOW_LOADING_API
  });

  const config = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
  
    const body = JSON.stringify({ parendId : parendId, id : id });
  
  try {
    const res = await axios.post('/api/users/pay_affiliation_program/', body, config);

    dispatch({
      type: PAY_AFFILIATION_PROGRAM,
      payload: res.data.affiliation_program
    });
    dispatch({
      type: USER_LOADED,
      payload: res.data.user
    });
    dispatch({
      type: HIDE_LOADING_API
    });

    dispatch(setAlert('server_42_user', 'success'));

  } catch (err) {
    dispatch({
      type: PAY_AFFILIATION_PROGRAM_ERROR,
    });
    dispatch({
      type: HIDE_LOADING_API
    });
    dispatch({ type: LOGOUT });
  }
};

