import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { logout, loadUser } from '../../actions/auth';
import setAuthToken from '../../utils/setAuthToken';
import Nav from 'react-bootstrap/Nav';
import NavbarBootstrap from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import EnglandFlag from '../../img/england.png';
import FranceFlag from '../../img/france.png';
import ItalyFlag from '../../img/italy.png';
import { useLocation } from "react-router-dom";
import Toggle from './Toggle';

import useWindowDimensions from '../layout/UseWindowDimensions';

import LogoLight from '../../img/icon_blank.png';
import LogoDark from '../../img/icon_dark.png';

import { useHistory } from 'react-router-dom';

import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";

const NavbarLogout = ({ auth: { user, isAuthenticated, loading, theme }, logout, loadUser }) => {


 const { t } = useTranslation();
 let history = useHistory();
const [newTheme] = useState("dark");

const [isFocused, setIsFocused] = useState(false);

const [modalsOpen, setModalIsOpen] = useState(false);
const [modalTitle, setModalTitle] = useState("");
const [modalBody, setModalBody] = useState("");


const { width } = useWindowDimensions();

useEffect(() => {
  
  const handleInvalidToken = e => {
    if (e.key === 'token' && e.oldValue && !e.newValue) {
      if (isFocused) {
        setAuthToken(e.oldValue);
      }
      else {
        logout();
      }
    }
    else if (e.key === 'token' && !e.oldValue && e.newValue){
        setAuthToken(e.newValue);
        loadUser();
      }
    }

    const handleOnFocus = () => {
      setIsFocused(true);
    };

    const handleOnBlur = () => {
      setIsFocused(false);
    };

  window.addEventListener('storage', handleInvalidToken)
  window.addEventListener('focus', handleOnFocus)
  window.addEventListener('blur', handleOnBlur)
  return function cleanup() {
    window.removeEventListener('storage', handleInvalidToken)
    window.removeEventListener('focus', handleOnFocus)
    window.removeEventListener('blur', handleOnBlur)
  }
}, [logout, loadUser, isFocused])

const location = useLocation();


 const handleClick = (lang) => {
  i18next.changeLanguage(lang)
  }
  const authLinks = (

    <NavbarBootstrap collapseOnSelect expand="xl" className={!isAuthenticated && width > 1000 && (location.pathname === "/" || location.pathname === "/login") ? "color-nav-transparent text-primary" : "color-nav text-primary"} variant={newTheme === "light" ? "light" : "dark"}>

 
{ user && user.role === 0 ? 
  <NavbarBootstrap.Brand><Link to='/admin/affiliations'><div className="navbar-brand" style={{width: 150, height: 'auto'}}>
<img src={newTheme === "light" ? LogoDark : LogoLight} alt="Atrakxion icon"  className="img-fluid" /> </div>
</Link></NavbarBootstrap.Brand>
:
<NavbarBootstrap.Brand><Link className="navbar-brand" to='/'>
<div className="navbar-brand" style={{width: 150, height: 'auto'}}>
<img src={newTheme === "light" ? LogoDark : LogoLight} alt="Atrakxion icon"  className="img-fluid" /> </div>
</Link></NavbarBootstrap.Brand>
}


  <NavbarBootstrap.Toggle aria-controls="responsive-navbar-nav" />
  <NavbarBootstrap.Collapse id="responsive-navbar-nav">
     <Nav className="ml-auto">
        <Nav.Link className="px-2 py-2"><Nav style={{ fontWeight: 'bold', textAlign: 'center'}} onClick= { () =>  firstButtonClicked() }>{t('AboutUs.Subtitle1')}</Nav></Nav.Link>
        
        <Nav.Link className="px-2 py-2"><Nav style={{ fontWeight: 'bold', textAlign: 'center'}} onClick= { () =>  secondButtonClicked() }>{t('AboutUs.Subtitle2')}</Nav></Nav.Link>

        <Nav.Link className="px-2 py-2"><Nav style={{ fontWeight: 'bold', textAlign: 'center'}} onClick= { () =>  thirdButtonClicked() }>{t('AboutUs.Subtitle3')}</Nav></Nav.Link>

        <Nav.Link className="px-2 py-2"><Nav style={{ fontWeight: 'bold', textAlign: 'center'}} onClick= { () =>  fourthButtonClicked() }>{t('AboutUs.Subtitle4')}</Nav></Nav.Link>

        <Nav.Link className="px-2 py-2"><Nav style={{ fontWeight: 'bold', textAlign: 'center'}} onClick= { () =>  fifthButtonClicked() }>{t('AboutUs.Subtitle5')}</Nav></Nav.Link>
        
        <Nav.Link className="px-2 py-2"><Nav style={{ fontWeight: 'bold', textAlign: 'center'}} onClick= { () =>  sixthButtonClicked() }>{t('AboutUs.Subtitle6')}</Nav></Nav.Link>

        <Nav.Link className="px-2 py-2"><Nav style={{ fontWeight: 'bold', textAlign: 'center'}} onClick= { () =>  seventhButtonClicked() }>{t('AboutUs.Subtitle7')}</Nav></Nav.Link>
     


      <Nav role="button" className="px-2 py-2" style={{fontWeight: 'bold', color: newTheme === "light" ? '#daa520' : '#bc9f51', display: isAuthenticated? "" : "none"}} onClick={logout} href='#!' /*eventKey={2}*/>
      <i className='fas fa-sign-out-alt' />{' '}{t('NavBar.Logout')}
      </Nav>
    
<Nav role="button" className="px-2 py-2" style={{fontWeight: 'bold'}} href='#!' /*eventKey={2}*/>
  <div style={{display: isAuthenticated? "" : "none"}}>
<Toggle />
    </div>
</Nav>
    <NavDropdown className="px-2 py-2" alignRight title= { 
     <img
    src={t('Language.Lang') === "English" ? EnglandFlag : t('Language.Lang') === "Français" ? FranceFlag : (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? ItalyFlag : EnglandFlag}
    style={{ width: '25px' }}
    alt='Language...'
  /> } id="collasible-nav-dropdown" bsPrefix='popover-body'>
      <NavDropdown.Item style={{fontWeight: 'bold', color: t('Language.Lang') === "English" ? theme === "light" ? 'black' : 'white' : ''}} href="#" onClick={()=>handleClick('en')}>{t('NavBar.English')}</NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item style={{fontWeight: 'bold', color: t('Language.Lang') === "Français" ? theme === "light" ? 'black' : 'white' : ''}} href="#" onClick={()=>handleClick('fr')}>{t('NavBar.French')}</NavDropdown.Item>
      <NavDropdown.Divider />
      <NavDropdown.Item style={{fontWeight: 'bold', color: t('Language.Lang') === "Italiano" ? theme === "light" ? 'black' : 'white' : ''}} href="#" onClick={()=>handleClick('it')}>{t('NavBar.Italian')}</NavDropdown.Item>
    </NavDropdown>

    </Nav>
  </NavbarBootstrap.Collapse>
</NavbarBootstrap>

  );







const showModal =
<Modal className="my-modal" show={modalsOpen} onHide={() => setModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">

<button className="btn btn-primary" onClick={() => setModalIsOpen(false)}>{t('NavBar.Ok')}</button>

</ModalFooter>
</Modal>


function firstButtonClicked() {
    setModalTitle(t('AboutUs.Subtitle1'))
    setModalBody(t('AboutUs.Paragraph1')+"\n"+
    t('AboutUs.Paragraph2')+"\n"+
    t('AboutUs.Paragraph3')+"\n"+
    t('AboutUs.Paragraph4')+"\n"+
    t('AboutUs.Paragraph5'))
    setModalIsOpen(true)
  }


  function secondButtonClicked() {
    setModalTitle(t('AboutUs.Subtitle2'))
    setModalBody(t('AboutUs.Paragraph6')+"\n"+
    t('AboutUs.Paragraph7'))
    setModalIsOpen(true)
  }


  function thirdButtonClicked() {
    setModalTitle(t('AboutUs.Subtitle3'))
    setModalBody(t('AboutUs.Paragraph1')+"\n"+
    t('AboutUs.Paragraph8')+"\n"+
    t('AboutUs.Paragraph9'))
    setModalIsOpen(true)
  }


  function fourthButtonClicked() {
    setModalTitle(t('AboutUs.Subtitle4'))
    setModalBody(t('AboutUs.Paragraph10')+"\n"+
    t('AboutUs.Paragraph11')+"\n"+
    t('AboutUs.Paragraph12'))
    setModalIsOpen(true)
  }


  function fifthButtonClicked() {
    setModalTitle(t('AboutUs.Subtitle5'))
    setModalBody(t('AboutUs.Paragraph13')+"\n"+
    t('AboutUs.Paragraph14')+"\n"+
    t('AboutUs.Paragraph15')+"\n"+
    t('AboutUs.Paragraph16')+"\n"+
    t('AboutUs.Paragraph17')+"\n"+
    t('AboutUs.Paragraph18')+"\n"+
    t('AboutUs.Paragraph19')+"\n"+
    t('AboutUs.Paragraph20')+"\n"+
    t('AboutUs.Paragraph21'))
    setModalIsOpen(true)
  }


  function sixthButtonClicked() {
    setModalTitle(t('AboutUs.Subtitle6'))
    setModalBody(t('AboutUs.Paragraph22')+"\n"+
    t('AboutUs.Paragraph23')+"\n"+
    t('AboutUs.Paragraph24')+"\n"+
    t('AboutUs.Paragraph25')+"\n"+
    t('AboutUs.Paragraph26')+"\n"+
    t('AboutUs.Paragraph27')+"\n"+
    t('AboutUs.Paragraph28'))
    setModalIsOpen(true)
  }


  function seventhButtonClicked() {
    setModalTitle(t('AboutUs.Subtitle7'))
    setModalBody(t('AboutUs.Paragraph29')+"\n"+
    t('AboutUs.Paragraph30')+"\n"+
    t('AboutUs.Paragraph31'))
    setModalIsOpen(true)
  }
  
  return (
    <Fragment>
        {showModal}
    
  

      {!loading && (
        <div>
        
        {authLinks}
        </div>
        
      )}


    

    </Fragment>
  );
  
};

NavbarLogout.propTypes = {
  logout: PropTypes.func.isRequired,
  loadUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logout, loadUser }
)(NavbarLogout);
