import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import profile from './profile';
import post from './post';
import sponsorship from './sponsorship';
import transfer from './transfer';
import transaction from './transaction';
import contact from './contact';
import article from './article';
import deal from './deal';
import purchase from './purchase';
import advertisement from './advertisement';
import notification from './notification';
import chat from './chat';

export default combineReducers({
  alert,
  auth,
  profile,
  post,
  sponsorship,
  contact,
  transfer,
  transaction,
  article,
  deal,
  purchase,
  advertisement,
  notification,
  chat
});
