import React, { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ChatAdmin from './ChatAdmin';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import SponsorshipActionsAdmin from '../SponsorshipActionsAdmin';


const MessagingAdmin = ({ auth: { user, theme } }) => {

    const { t } = useTranslation();

    if ( user.role !== 0 ) {
      return <Redirect to="/dashboard" />;
    }

  return (

    <Fragment>
    <SponsorshipActionsAdmin />
    <ChatAdmin/>
    </Fragment>
    ) ;
}


MessagingAdmin.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(MessagingAdmin);
