import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

const PoliciesProcedures = ({
  auth: { user }
}) => {

  const { t } = useTranslation();
  if (user && (user.status === "C" || user.status === "A")) {
    return <Redirect to="/dashboard" />;
  }
  return (
    <Fragment>
            <section>
      <h1 className='large text-primary'>{t('PoliciesProcedures.Title')}</h1>
  <details>
    <summary>I. {t('PoliciesProcedures.Subtitle1')}</summary>
    <p>
    <strong>1. </strong>
      {t('PoliciesProcedures.Paragraph1')} < br/>
    <strong>2. </strong>
      {t('PoliciesProcedures.Paragraph2')} < br/>
    <strong>3. </strong>
      {t('PoliciesProcedures.Paragraph3')} < br/>
    <strong>4. </strong>
      {t('PoliciesProcedures.Paragraph4')} < br/>
      {t('PoliciesProcedures.Paragraph5')}
    </p>
  </details>
  <details>
    <summary>II. {t('PoliciesProcedures.Subtitle2')}</summary>
    <p>
    <strong>1. </strong>
      {t('PoliciesProcedures.Paragraph6')} < br/>
    <strong>2. </strong>
      {t('PoliciesProcedures.Paragraph7')} < br/>
    <strong>3. </strong>
      {t('PoliciesProcedures.Paragraph8')} < br/>
    <div style={{marginLeft : "2em"}}>
    <strong>a. </strong>
      {t('PoliciesProcedures.Paragraph9')} < br/>
    <strong>b. </strong>
      {t('PoliciesProcedures.Paragraph10')} < br/>
    <strong>c. </strong>
      {t('PoliciesProcedures.Paragraph11')}
      </div>
    <strong>4. </strong>
      {t('PoliciesProcedures.Paragraph12')} < br/>
      <div style={{marginLeft : "2em"}}>
    <strong>a. </strong>
      {t('PoliciesProcedures.Paragraph13')}
      </div>
    </p>
  </details>
  <details>
    <summary>III. {t('PoliciesProcedures.Subtitle3')}</summary>
    <p>
    <strong>1. {t('PoliciesProcedures.Paragraph14')}</strong>< br/>
      {t('PoliciesProcedures.Paragraph15')} < br/>
      <strong>1. {t('PoliciesProcedures.Paragraph16')}</strong>< br/>
      {t('PoliciesProcedures.Paragraph17')} < br/>
      <strong>2. {t('PoliciesProcedures.Paragraph18')}</strong>< br/>
      {t('PoliciesProcedures.Paragraph19')}< br/>
      {t('PoliciesProcedures.Paragraph20')}
    </p>
  </details>
  <details>
    <summary>IV. {t('PoliciesProcedures.Subtitle4')}</summary>
    <p>
    <strong>1. </strong>
      {t('PoliciesProcedures.Paragraph21')} < br/>
    <strong>2. </strong>
      {t('PoliciesProcedures.Paragraph22')} < br/>
    <strong>3. </strong>
      {t('PoliciesProcedures.Paragraph23')} < br/>
    <strong>4. </strong>
      {t('PoliciesProcedures.Paragraph24')}
    </p>
  </details>
  <details>
    <summary>V. {t('PoliciesProcedures.Subtitle5')}</summary>
    <p>
    <strong>1. </strong>
      {t('PoliciesProcedures.Paragraph25')} < br/>
    <strong>2. </strong>
      {t('PoliciesProcedures.Paragraph26')} < br/>
    <strong>3. </strong>
      {t('PoliciesProcedures.Paragraph27')} < br/>
    <strong>4. </strong>
      {t('PoliciesProcedures.Paragraph28')} < br/>
    <strong>5. </strong>
      {t('PoliciesProcedures.Paragraph29')} < br/>
    <strong>6. </strong>
      {t('PoliciesProcedures.Paragraph30')} < br/>
    <strong>7. </strong>
      {t('PoliciesProcedures.Paragraph31')} < br/>
      {t('PoliciesProcedures.Paragraph32')} < br/>
    <strong>8. </strong>
      {t('PoliciesProcedures.Paragraph33')} < br/>
    <strong>9. </strong>
      {t('PoliciesProcedures.Paragraph34')}
    </p>
  </details>
  <details>
    <summary>VI. {t('PoliciesProcedures.Subtitle6')}</summary>
    <p>
    <strong>1. </strong>
      {t('PoliciesProcedures.Paragraph35')} < br/>
    <strong>2. </strong>
      {t('PoliciesProcedures.Paragraph36')} < br/>
    <strong>3. </strong>
      {t('PoliciesProcedures.Paragraph37')} < br/>
    <div style={{marginLeft : "2em"}}>
    <strong>a. </strong>
      {t('PoliciesProcedures.Paragraph38')} < br/>
      {t('PoliciesProcedures.Paragraph39')} < br/>
    <strong>b. </strong>
      {t('PoliciesProcedures.Paragraph40')} < br/>
      {t('PoliciesProcedures.Paragraph41')}
      </div>
    <strong>4. </strong>
      {t('PoliciesProcedures.Paragraph42')}
    </p>
  </details>
  <details>
    <summary>VII. {t('PoliciesProcedures.Subtitle7')}</summary>
    <p>
    <strong>1. </strong>
      {t('PoliciesProcedures.Paragraph43')} < br/>
    <strong>2. </strong>
      {t('PoliciesProcedures.Paragraph44')} < br/>
    <strong>3. </strong>
      {t('PoliciesProcedures.Paragraph45')} < br/>
    <strong>4. </strong>
      {t('PoliciesProcedures.Paragraph46')}
    </p>
  </details>
  <details>
    <summary>VIII. {t('PoliciesProcedures.Subtitle8')}</summary>
    <p>
    <strong>1. </strong>
      {t('PoliciesProcedures.Paragraph47')} < br/>
    <strong>2. </strong>
      {t('PoliciesProcedures.Paragraph48')}
    </p>
  </details>
  <details>
    <summary>IX. {t('PoliciesProcedures.Subtitle9')}</summary>
    <p>
    <strong>1. </strong>
      {t('PoliciesProcedures.Paragraph49')} < br/>
    <strong>2. </strong>
      {t('PoliciesProcedures.Paragraph50')} < br/>
    <strong>3. </strong>
      {t('PoliciesProcedures.Paragraph51')}
    </p>
  </details>
  <details>
  <summary>
    </summary>
  </details>
</section>
    </Fragment>
  );
};

PoliciesProcedures.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(PoliciesProcedures);
