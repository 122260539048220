import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import userImage from '../../img/user.png';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getSponsorships } from './../../actions/sponsorship';
import { updateChat } from './../../actions/chat';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import { useHistory } from 'react-router-dom';


const Conversation = ({ auth: { user, theme }, data, currentUser, updateChat, online, sponsorship: { sponsorships }, chat: { chats } }) => {

    const { t } = useTranslation();


 // const [userData, setUserData] = useState(null);
  const [discussionName, setDiscussionName] = useState("");

    const [modalsReportOpen, setModalReportIsOpen] = useState(false);
    const [modalsDeleteOpen, setModalDeleteIsOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalBody, setModalBody] = useState("");
    const [modalFirstButton, setModalFirstButton] = useState("");
    const [modalSecondButton, setModalSecondButton] = useState("");

    let history = useHistory();

  useEffect(() => {
    setDiscussionName(data?.membersUsername?.filter(element => element !== user.username))
      }, [sponsorships, user, data]);

      useEffect(() => {
        getSponsorships(user._id);
      }, [user._id]);

  /*  useEffect(() => {
    const userId = data?.members?.find((id)=>id!==currentUser)
    const childWithId = sponsorships.find((child) => child.id === userId);
   if(childWithId) {
    setUserData(childWithId);
   }
   else {
    setUserData({"id": user.parent_id, "name": user.parent_username})
   }
      }, [sponsorships, currentUser, user, data]);*/


      function firstDeleteButtonClicked() {
        setModalTitle(t('Messaging.Action_irreversible'));
        setModalBody(
          t('Messaging.Delete_sure_and')+" " + (data?.membersUsername?.filter(element => element !== user.username))
        );
        setModalFirstButton(t('Messaging.Delete'));
        setModalSecondButton("");
        setModalDeleteIsOpen(true);
    }
    
    function deleteButtonClicked() {
        updateChat(data?._id, 2, user._id, history);
        setModalDeleteIsOpen(false);
    }


    function firstReportButtonClicked() {
      setModalTitle(t('Messaging.Action_irreversible'));
      setModalBody(
        t('Messaging.Report_sure_and')+" " + (data?.membersUsername?.filter(element => element !== user.username))
      );
      setModalFirstButton(t('Messaging.Report'));
      setModalSecondButton("");
      setModalReportIsOpen(true);
  }

    function reportButtonClicked() {
      updateChat(data?._id, 3, user._id, history);
      setModalReportIsOpen(false);
  }


  const showModalDelete = 
<Modal className="my-modal" show={modalsDeleteOpen} onHide={() => setModalDeleteIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">
  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-danger" onClick={() => deleteButtonClicked()}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setModalDeleteIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }

</ModalFooter>
</Modal>




const showModalReport = 
<Modal className="my-modal" show={modalsReportOpen} onHide={() => setModalReportIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">
  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-warning" onClick={() => reportButtonClicked()}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setModalReportIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }

</ModalFooter>
</Modal>


  return (
    <Fragment>
      {showModalDelete}
      {showModalReport}
  <div className={`row align-items-center conversation ${theme === 'light' ? 'conversation-light' : 'conversation-dark'}`}>
    <div
      className="col-auto position-relative"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {online && <div className="online-dot"></div>}
      <img
        src={userImage}
        alt="User_image"
        className="img-fluid"
        style={{
          width: "50px",
          height: "50px",
          objectFit: "cover",
          borderRadius: "50%",
        }}
      />
      {(data?.lastSender !== user.email && data?.seen > 0) && (
        <div
          style={{
            position: "absolute",
            top: "5px",
            right: "-10px",
            backgroundColor: "red",
            color: "white",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "0.8rem",
          }}
        >
          {data?.lastSender === user.email ? 0 : data?.seen}
        </div>
      )}
    </div>
    <div className="col">
      <div className="name">
        <span
          className="text-truncate fw-bold"
          style={{ fontSize: "0.9rem" }}
        >
          {discussionName + " (" + data?.title + ")"}
        </span>
        <br />
        <span
          className={`d-block ${
            online ? "text-success" : "text-muted"
          }`}
          style={{ fontSize: "0.8rem" }}
        >
          {online ? t("Messaging.Online") : t("Messaging.Offline")}
        </span>
      </div>
    </div>
    <div className="col-auto d-flex gap-2">
      <button
        onClick={() => firstReportButtonClicked()}
        className="btn btn-warning btn-sm"
        style={{ fontSize: "0.8rem"}}
        disabled={data?.status === 3 ? true : false}
      >
        {t("Messaging.Report")}
      </button>
      {/* Bouton pour supprimer */}
      <button
        onClick={() => firstDeleteButtonClicked()}
        className="btn btn-danger btn-sm"
        style={{ fontSize: "0.8rem" }}
      >
        {t("Messaging.Delete")}
      </button>
    </div>
  </div>
  <hr
    style={{
      width: "85%",
      border: `0.1px solid ${
        theme === "light" ? "#bc9f51" : "#edd99a"
      }`,
    }}
  />
</Fragment>

    ) ;
}


Conversation.propTypes = {
  auth: PropTypes.object.isRequired,
  updateChat: PropTypes.func.isRequired,
  getSponsorships: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  auth: state.auth,
  sponsorship: state.sponsorship,
  chat: state.chat
});

export default connect(
  mapStateToProps,
  { updateChat, getSponsorships }
)(Conversation);
