import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Register from '../auth/Register';
import Login from '../auth/Login';
import ResetPassword from '../auth/ResetPassword';
import Dashboard from '../dashboard/Dashboard';
import ProfileForm from '../profile-forms/ProfileForm';
import AddExperience from '../profile-forms/AddExperience';
import AddEducation from '../profile-forms/AddEducation';
import Profiles from '../profiles/Profiles';
//import Profile from '../profile/Profile';
import Posts from '../posts/Posts';
import Post from '../post/Post';
import SponsorshipTree from '../sponsorship/SponsorshipTree';
import MyPartners from '../sponsorship/MyPartners';
import AddUser from '../sponsorship/AddUser';
import NotFound from '../layout/NotFound';
import PrivateRoute from '../routing/PrivateRoute';
import Matrix from '../sponsorship/Matrix';
import Genealogy from '../sponsorship/Genealogy';
import DigitalWallet from '../sponsorship/DigitalWallet';
import SponsorshipProfile from '../posts/SponsorshipProfile';
import History from '../sponsorship/History';
import MatrixHistory from '../sponsorship/MatrixHistory';
import Contact from '../contact/ContactForm';
import Presentation from '../about/Presentation';
import PrivacyPolicy from '../about/PrivacyPolicy';
import PoliciesProcedures from '../about/PoliciesProcedures';
import AboutUs from '../about/AboutUs';
import LegalNotices from '../about/LegalNotices';
import TermsConditions from '../about/TermsConditions';


import DashboardAdmin from '../admin/dashboardAdmin/DashboardAdmin';
import SponsorshipTreeAdmin from '../admin/dashboardAdmin/SponsorshipTreeAdmin';
import MatrixAdmin from '../admin/dashboardAdmin/MatrixAdmin';
import ChartAdmin from '../admin/dashboardAdmin/ChartAdmin';
import TransferAdmin from '../admin/dashboardAdmin/TransferAdmin';
import TransactionAdmin from '../admin/dashboardAdmin/TransactionAdmin';
import ArticlesAdmin from '../admin/dashboardAdmin/ArticlesAdmin';
import AddArticleAdmin from '../admin/dashboardAdmin/AddArticleAdmin';


import AdminMessaging from '../admin/dashboardAdmin/messaging/MessagingAdmin';
import MessagHistory from '../admin/dashboardAdmin/MessageHistory';
import MessageDetails from '../admin/dashboardAdmin/MessageDetails';
import AdminDeals from '../admin/dashboardAdmin/DealAdmin';
import AdminPurchases from '../admin/dashboardAdmin/PurchaseAdmin';
import AdminAdvertisement from '../admin/dashboardAdmin/AdvertisementAdmin';


import SponsorshipTreeDemo from '../demo/SponsorshipTreeDemo';
import MatrixDemo from '../demo/MatrixDemo';
import GenealogyDemo from '../demo/GenealogyDemo';
import DigitalWalletDemo from '../demo/DigitalWalletDemo';


import Training from '../training/Training';
import InteractiveForecast from '../../components/about/InteractiveForecast';
import QuestionsAnswers from '../../components/about/QuestionsAnswers';


import ArticleDetail from '../dashboard/ArticleDetail';


import AdvertisementCategory from '../advertisements/AdvertisementCategory';
import Advertisements from '../advertisements/Advertisements';
import AddAdvertisement from '../advertisements/AddAdvertisement';
import GcuAdvertisement from '../advertisements/GcuAdvertisement';

import Deals from '../deals/Deals';
import AddDeal from '../deals/AddDeal';
import GcuDeal from '../deals/GcuDeal';

import Purchases from '../purchases/Purchases';
import AddPurchase from '../purchases/AddPurchase';
import GcuPurchase from '../purchases/GcuPurchase';

import Messaging from '../messaging/Messaging';

import CodeConduct from '../posts/CodeConduct';



const Routes = props => {


  return (
    <section>
      <Switch>
      <Route exact path="/register" component={Register} />
        <Route exact path="/login/:confirmation?" component={Login} />
        <Route exact path="/reset-password/:token" component={ResetPassword} />
        <PrivateRoute exact path="/peoples" component={Profiles} />
     {/*   <PrivateRoute exact path="/profile/:id" component={Profile} /> */}
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute exact path="/create-profile" component={ProfileForm} />
  {/*   <PrivateRoute exact path="/edit-profile" component={ProfileForm} /> */}
        <PrivateRoute exact path="/add-experience" component={AddExperience} />
        <PrivateRoute exact path="/add-education" component={AddEducation} />
        <PrivateRoute exact path="/community" component={Posts} />
        <PrivateRoute exact path="/community/:id" component={Post} />
        <PrivateRoute exact path="/code-of-conduct" component={CodeConduct} />
        <PrivateRoute exact path="/affiliations" component={SponsorshipTree} />
        <PrivateRoute exact path="/my-partners" component={MyPartners} />
        <PrivateRoute exact path="/add-subscriber" component={AddUser} />
        <PrivateRoute exact path="/payment-table" component={Genealogy} />
        <PrivateRoute exact path="/matrix" component={Matrix} />
        <PrivateRoute exact path="/digital-wallet" component={DigitalWallet} />
        <PrivateRoute exact path="/profile" component={SponsorshipProfile} />
        <PrivateRoute exact path="/full-matrices" component={History} />
        <PrivateRoute exact path="/full-matrices-matrix" component={MatrixHistory} />
        <PrivateRoute exact path="/contact" component={Contact} />
        <PrivateRoute exact path="/presentation" component={Presentation} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/legal-notices" component={LegalNotices} />
        <Route exact path="/terms-conditions" component={TermsConditions} />
        <Route exact path="/privacy-policy" component={PrivacyPolicy} />
        <PrivateRoute exact path="/policies-procedures" component={PoliciesProcedures} />
        

        <PrivateRoute exact path="/admin/dashboard" component={DashboardAdmin} />
        <PrivateRoute exact path="/admin/affiliations" component={SponsorshipTreeAdmin} />
        <PrivateRoute exact path="/admin/matrix" component={MatrixAdmin} />
        <PrivateRoute exact path="/admin/charts" component={ChartAdmin} />
        <PrivateRoute exact path="/admin/transfers" component={TransferAdmin} />
        <PrivateRoute exact path="/admin/activities" component={TransactionAdmin} />

        <PrivateRoute exact path="/admin/messaging" component={AdminMessaging} />
        <PrivateRoute exact path="/admin/message-history" component={MessagHistory} />
        <PrivateRoute exact path="/admin/message-details" component={MessageDetails} />
        <PrivateRoute exact path="/admin/deals" component={AdminDeals} />
        <PrivateRoute exact path="/admin/purchases" component={AdminPurchases} />
        <PrivateRoute exact path="/admin/advertisements" component={AdminAdvertisement} />


        <PrivateRoute exact path="/demo/affiliations" component={SponsorshipTreeDemo} />
        <PrivateRoute exact path="/demo/matrix" component={MatrixDemo} />
        <PrivateRoute exact path="/demo/payment-table" component={GenealogyDemo} />
        <PrivateRoute exact path="/demo/digital-wallet" component={DigitalWalletDemo} />

        <PrivateRoute exact path="/admin/articles" component={ArticlesAdmin} />
        <PrivateRoute exact path="/admin/add-article" component={AddArticleAdmin} />

        
        <PrivateRoute exact path="/training" component={Training} />
        <PrivateRoute exact path="/interactive-forecast" component={InteractiveForecast} />
        <PrivateRoute exact path="/questions-answers" component={QuestionsAnswers} />


        <PrivateRoute exact path="/article-detail/:id" component={ArticleDetail} />


        <PrivateRoute exact path="/advertisement-categories" component={AdvertisementCategory} />
        <PrivateRoute exact path="/all-advertisements" component={Advertisements} />
        <PrivateRoute exact path="/add-advertisement" component={AddAdvertisement} />
        <PrivateRoute exact path="/gcu-advertisement" component={GcuAdvertisement} />

        <PrivateRoute exact path="/all-deals" component={Deals} />
        <PrivateRoute exact path="/add-deal" component={AddDeal} />
        <PrivateRoute exact path="/gcu-deal" component={GcuDeal} />

        <PrivateRoute exact path="/all-purchases" component={Purchases} />
        <PrivateRoute exact path="/add-purchase" component={AddPurchase} />
        <PrivateRoute exact path="/gcu-purchase" component={GcuPurchase} />

        <PrivateRoute exact path="/messaging" component={Messaging} />
        
        <Route component={NotFound} />
      </Switch>
    </section>
  );
};

export default Routes;
