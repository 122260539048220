import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AdvertisementsActions = () => {

  const { t } = useTranslation();

  return (
    <div className='dash-buttons'>
    <Link to='/advertisement-categories' className='btn btn-dark text-button-color mb-2'>
      <i className='fab fa-black-tie text-primary' /> {t('Advertisements.Classified_ads')}
    </Link>
      <Link to='/add-advertisement' className='btn btn-dark text-button-color mb-2'>
        <i className='fab fa-black-tie text-primary' /> {t('Advertisements.Add_classified_ad')}
      </Link>
      <Link to='/gcu-advertisement' className='btn btn-dark text-button-color mb-2'>
        <i className='fab fa-black-tie text-primary' /> {t('GcuAdvertisement.Title')}
      </Link>
    </div>
  );
};

export default AdvertisementsActions;
