import {
    GET_PURCHASES,
    PURCHASE_ERROR,
    DELETE_PURCHASE,
    ADD_PURCHASE,
    GET_PURCHASE,
    GET_USERS_PURCHASE,
    PURCHASES_SUCCESS,
    UPDATE_PURCHASE,
    UPDATE_PURCHASE_ERROR,
  } from '../actions/types';
  
  const initialState = {
    purchases: [],
    purchase: null,
    loading: true,
    error: {}
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case GET_PURCHASES:
        return {
          ...state,
          purchases: payload,
          loading: false
        };
      case GET_PURCHASE:
        return {
          ...state,
          purchase: payload,
          loading: false
        };
        case GET_USERS_PURCHASE:
          return {
            ...state,
            users_purchases: payload,
            loading: false
          };
      case ADD_PURCHASE:
        return {
          ...state,
          purchases: [payload, ...state.purchases],
          loading: false
        };
      case DELETE_PURCHASE:
        return {
          ...state,
          purchases: state.purchases.filter(purchase => purchase._id !== payload),
          loading: false
        };
      case PURCHASE_ERROR:
        return {
          ...state,
          error: payload,
          loading: false
        };
        case UPDATE_PURCHASE:
                return {
                    ...state,
                    ...payload,
                    loading: false,
                    updated_purchase: payload
                };
                case UPDATE_PURCHASE_ERROR:
                return {
                    ...state,
                    error: payload,
                    loading: false
                };

            case PURCHASES_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: false,
                purchases: payload
            };
      default:
        return state;
    }
  }
  