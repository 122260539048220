import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from './../layout/Spinner';
import PurchasesActions from './PurchasesActions';
import { getSponsorships } from './../../actions/sponsorship';
import { getPurchaseForClients, updatePurchase, publishPurchases, participatePurchase, withdrawPurchase } from './../../actions/purchase';
import { addAdvertMessage } from './../../actions/chat';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";
import Pagination from './../layout/Pagination';
import { useTranslation } from 'react-i18next';
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom';


const Purchases = ({ getPurchaseForClients, getSponsorships, updatePurchase, publishPurchases, addAdvertMessage, participatePurchase, withdrawPurchase, auth: { user, theme }, purchase: { users_purchases }, sponsorship: { loading } }) => {

  const location = useLocation();
    let history = useHistory();

    const [modalsSecondOpen, setModalSecondIsOpen] = useState(false);
    const [modalsThirdOpen, setModalThirdIsOpen] = useState(false);
    const [modalsFourthOpen, setModalFourthIsOpen] = useState(false);
    const [modalsSendMessageOpen, setModalSendMessageIsOpen] = useState(false);
    const [modalsParticipatePurchaseOpen, setModalParticipatePurchaseIsOpen] = useState(false);
    const [modalsWithdrawPurchaseOpen, setModalWithdrawPurchaseIsOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalBody, setModalBody] = useState("");
    const [modalFirstButton, setModalFirstButton] = useState("");
    const [modalSecondButton, setModalSecondButton] = useState("");
    const [purchaseId, setPurchaseId] = useState("");
    const [myPurchase, setMyPurchase] = useState("");
    const [modalsDetailOpen, setModalDetailIsOpen] = useState(false);
    const [modalsPublishApprovedPurchasesOpen, setModalPublishApprovedPurchasesIsOpen] = useState(false);
  
    const [currentPage, setCurrentPage] = useState(1);
    const [purchasesPerPage] = useState(10);
  
    const [criteria, setCriteria] = useState("All");
    const [searchCriteria, setSearchCriteria] = useState("Presenter");
    const [searchField, setSearchField] = useState("");  
  
    const [myPurchasesChange, setMyPurchasesChange] = useState(false);
    const [myParticipationsChange, setMyParticipationsChange] = useState(false);

    const [purchaseImages, setPurchaseImages] = useState([]);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isZooming, setIsZooming] = useState(false);
    const [zoomStyle, setZoomStyle] = useState({});

    const [myMessage, setMyMessage] = useState("");
  

  useEffect(() => {
    getPurchaseForClients(user._id);
  }, [getPurchaseForClients, user._id]);

  useEffect(() => {
    getSponsorships(user._id);
  }, [getSponsorships, user._id]);



  const { t } = useTranslation();


  var numberCounter = 0;

  const indexOfLastPurchase = currentPage * purchasesPerPage;
  const indexOfFirstPurchase = indexOfLastPurchase - purchasesPerPage;
  var currentPurchases = [];
  var sortedPurchases = [];
  if (users_purchases && users_purchases.length > 0) {
    let tempData = [...users_purchases];
    for (var i = 0; i < tempData.length; i++) {

      if (i > 0 && (tempData[i - 1].date_creation !== tempData[i].date_creation)) {
        numberCounter = 0;
      }
      else {
        if (i > 0 && ((criteria === "All") || (criteria === "Pending" && tempData[i - 1].state === 1)
          || (criteria === "Approved" && tempData[i - 1].state === 2)
          || (criteria === "Online" && tempData[i - 1].state === 3)
          || (criteria === "Suspended" && tempData[i - 1].state === 4)
          || (criteria === "Deleted" && tempData[i - 1].state === 5)
          )) {
            numberCounter ++;
        }
      }
      tempData[i].number = numberCounter + 1;

      if (!myPurchasesChange || (myPurchasesChange === true && tempData[i].user === user._id)) {
        if (!myParticipationsChange || (myParticipationsChange === true && tempData[i].participants.includes(user._id))) {

        if ((criteria === "All") || (criteria === "Pending" && tempData[i].status === 1)
          || (criteria === "Approved" && tempData[i].status === 2)
          || (criteria === "Online" && tempData[i].status === 3)
          || (criteria === "Suspended" && tempData[i].status === 4)
          || (criteria === "Deleted" && tempData[i].status === 5))
      {
      if (searchField) {
           if (searchCriteria === "Presenter") {
        if (tempData[i].presenter.toUpperCase().startsWith(searchField.toUpperCase())) {
            sortedPurchases.push(tempData[i]);
        }
      }
        else if (searchCriteria === "Title") {
          if (tempData[i].title.toUpperCase() === "") {
            if (t('Language.Lang') === "Français") {
              if ("Aucun".toUpperCase().startsWith(searchField.toUpperCase())) {
                sortedPurchases.push(tempData[i]);
            }
            }
            else if (t('Language.Lang') === "Italiano") {
              if ("Nessuna".toUpperCase().startsWith(searchField.toUpperCase())) {
                sortedPurchases.push(tempData[i]);
            }
            }
            else {
              if ("None".toUpperCase().startsWith(searchField.toUpperCase())) {
                sortedPurchases.push(tempData[i]);
            }
            }
          }
          else {
            if (tempData[i].title.toUpperCase().startsWith(searchField.toUpperCase())) {
              sortedPurchases.push(tempData[i]);
          }
          }
        }
      else if (searchCriteria === "D") {
        var dateDay = tempData[i].date_creation.substring(0, 10).split("-")
        var finalDateDay = dateDay[1] + "-" + dateDay[2] + "-" + dateDay[0]
          if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
            finalDateDay = dateDay[2] + "-" + dateDay[1] + "-" + dateDay[0];
          }

      if (finalDateDay.startsWith(searchField)) {
        sortedPurchases.push(tempData[i]);
      }
    }
    else if (searchCriteria === "M") {
      var dateMonth = tempData[i].date_creation.substring(0, 10).split("-")
      
        dateMonth = dateMonth[1] + "-" + dateMonth[0]
     

    if (dateMonth.startsWith(searchField)) {
        sortedPurchases.push(tempData[i]);
    }
  }
  else if (searchCriteria === "Y") {

    var dateYear = tempData[i].date_creation.substring(0, 10).split("-")[0]


  if (dateYear.startsWith(searchField)) {
    sortedPurchases.push(tempData[i]);
  }
}
      }
      else {
        sortedPurchases.push(tempData[i]);
      }
    }
    }
    }
  }

    currentPurchases = sortedPurchases.reverse().slice(indexOfFirstPurchase, indexOfLastPurchase);
  }


  const paginate = pageNum => setCurrentPage(pageNum);

  const nextPage = () => setCurrentPage(currentPage === Math.ceil(sortedPurchases.length / purchasesPerPage) ? currentPage : currentPage + 1);

  const prevPage = () => setCurrentPage(currentPage > 1 ? currentPage - 1 : currentPage);

  const firstPage = () => setCurrentPage(1);

  const lastPage = () => setCurrentPage(Math.ceil(sortedPurchases.length / purchasesPerPage));



  function handleCriteriaChange (changeEvent) {
    if(changeEvent.currentTarget.value === "All") {
      setMyPurchasesChange(false)
      setMyParticipationsChange(false)
  }
  else {
      setMyPurchasesChange(true)
      setMyParticipationsChange(true)
  }
    setCriteria(changeEvent.currentTarget.value);
    setCurrentPage(1);
}

function handleSearchCriteriaChange (changeEvent) {

  if ((changeEvent.currentTarget.value === "D" || changeEvent.currentTarget.value === "M" || changeEvent.currentTarget.value === "Y") && isNaN(searchField)) {
    setSearchField("");
  }
  else if ((changeEvent.currentTarget.value === "Presenter" || changeEvent.currentTarget.value === "Title") && !isNaN(searchField)) {
    setSearchField("");
  }

  setSearchCriteria(changeEvent.currentTarget.value);

  setCurrentPage(1);
}

const handleNext = () => {
  setCurrentIndex((prevIndex) => (prevIndex + 1) % purchaseImages.length);
};

const handlePrev = () => {
  setCurrentIndex((prevIndex) =>
    prevIndex === 0 ? purchaseImages.length - 1 : prevIndex - 1
  );
};

const handleZoom = (e) => {
  const rect = e.target.getBoundingClientRect();
  const x = ((e.clientX - rect.left) / rect.width) * 100;
  const y = ((e.clientY - rect.top) / rect.height) * 100;

  setZoomStyle({
    transformOrigin: `${x}% ${y}%`,
    transform: "scale(2)", // Ajustez la valeur du zoom ici
  });
  setIsZooming(true);
};

const resetZoom = () => {
  setIsZooming(false);
  setZoomStyle({});
};


function handleMyPurchasesChange () {
  setMyPurchasesChange(!myPurchasesChange);
  setCurrentPage(1);
}

function handleMyParticipationsChange () {
  setMyParticipationsChange(!myParticipationsChange);
  setCurrentPage(1);
}
      


      const showTable =
      
      
      <Fragment>
      <br/><br/>
      
<h1 className='large text-primary'>{t('Purchases.Purchase_list')}</h1>


  
<div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>

<div className="scrollme">

<div className='form'>
<div className="form-group">
          <input
            type='text'
            placeholder={t('Purchases.Search_by') + " " + (searchCriteria === "Presenter" ? t('Purchases.Presenter').toLowerCase() : searchCriteria === "Title" ? t('Purchases.Title').toLowerCase() : searchCriteria === "D" ? t('Purchases.Day_text').toLowerCase() : searchCriteria === "M" ? t('Purchases.Month_text').toLowerCase() : searchCriteria === "Y" ? t('Purchases.Year_text').toLowerCase() : "")}
            name='searchField'
            value={searchField}
            onChange={e => findByCriteria(e.target.value)}
          />
        </div>
</div>


<div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
         <h4>
    {t('Purchases.Search_by')}:
          </h4>
        <label style={{marginRight:"20px", marginTop: "10px"}}>

      </label>



      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Presenter" 
         id="presenter"
         className="custom-control-input"
                      checked={searchCriteria === 'Presenter'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="presenter">
          {t('Purchases.Presenter')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Title" 
         id="title"
         className="custom-control-input"
                      checked={searchCriteria === 'Title'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="title">
          {t('Purchases.Title')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="D" 
         id="day"
         className="custom-control-input"
                      checked={searchCriteria === 'D'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="day">
          {t('Purchases.Day')}
      </label>
      </div>
      
      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="M" 
         id="month"
         className="custom-control-input"
                      checked={searchCriteria === 'M'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="month">
          {t('Purchases.Month')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Y" 
         id="year"
         className="custom-control-input"
                      checked={searchCriteria === 'Y'} 
                      onChange={handleSearchCriteriaChange} />
                      <label className="custom-control-label" for="year">
          {t('Purchases.Year')}
      </label>
      </div>

    </div>

<div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
         <h4>
    {t('Purchases.State')}:
          </h4>
        <label style={{marginRight:"20px", marginTop: "10px"}}>

      </label>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="All" 
         id="all"
         className="custom-control-input"
                      checked={criteria === 'All'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="all">
          {t('Purchases.All')}
      </label>
      </div>


      <div className="custom-control custom-radio custom-control-inline">
          <input type="radio" value="Pending" 
           id="pending"
           className="custom-control-input"
                        checked={criteria === 'Pending'} 
                        onChange={handleCriteriaChange} />
                        <label className="custom-control-label" for="pending">
            {t('Purchases.Pending')}
        </label>
        </div>
  
    
        <div className="custom-control custom-radio custom-control-inline">
          <input type="radio" value="Approved" 
           id="approved"
           className="custom-control-input"
                        checked={criteria === 'Approved'} 
                        onChange={handleCriteriaChange} />
                        <label className="custom-control-label" for="approved">
            {t('Purchases.Approved')}
        </label>
        </div>

        <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Online" 
         id="online"
         className="custom-control-input"
                      checked={criteria === 'Online'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="online">
          {t('Purchases.Online')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Suspended" 
         id="suspended"
         className="custom-control-input"
                      checked={criteria === 'Suspended'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="suspended">
          {t('Purchases.Suspended')}
      </label>
      </div>

      <div className="custom-control custom-radio custom-control-inline">
        <input type="radio" value="Deleted" 
         id="deleted"
         className="custom-control-input"
                      checked={criteria === 'Deleted'} 
                      onChange={handleCriteriaChange} />
                      <label className="custom-control-label" for="deleted">
          {t('Purchases.Deleted')}
      </label>
      </div>

    </div>

    <div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>
         <h4>
    {t('Purchases.Other_criteria')}:
          </h4>
        <label style={{marginRight:"20px", marginTop: "10px"}}>

      </label>


      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="my_purchases"
         className="custom-control-input"
                      checked={myPurchasesChange === true} 
                      onChange={handleMyPurchasesChange} />
                      <label className="custom-control-label" for="my_purchases">
          {t('Purchases.My_purchases')}
      </label>
      </div>

      <div className="custom-control custom-checkbox custom-control-inline">
        <input type="checkbox"
         id="my_participations"
         className="custom-control-input"
                      checked={myParticipationsChange === true} 
                      onChange={handleMyParticipationsChange} />
                      <label className="custom-control-label" for="my_participations">
          {t('Purchases.My_participations')}
      </label>
      </div>
    </div>

  
<table id="tbl" className={`table table-bordered  table-hover table-striped " ${theme === "light" ? "" : "table-dark"}`}>
  <thead  className={theme === "light" ? "thead-dark" : "thead-light"} >
  <tr>
      <th scope="col">{t('Purchases.Number')}</th>
      <th scope="col">{t('Purchases.Reference')}</th>
      <th scope="col">{t('Purchases.Date')}</th>
      <th scope="col">{t('Purchases.State')}</th>
      <th scope="col">{t('Purchases.Presenter')}</th>
      <th scope="col">{t('Purchases.Title')}</th>
      <th scope="col">{t('Purchases.Amount')}</th>
      <th scope="col">{t('Purchases.Actions')}</th>
    </tr>
  </thead>
  <tbody>


  {!users_purchases  ? 
          <tr>
          <th colSpan={8}>{t('Purchases.Calculating') + " ..."}</th>
        </tr>
      :
      sortedPurchases.length === 0 ?
      <tr>
          <th colSpan={8}>{t('Purchases.No_purchases')}</th>
        </tr>
       :
      currentPurchases.map(( get_purchase, index ) => {
   // console.log("sponsorships"+JSON.stringify(sponsorship))

     return (
    <tr key={index}>
      
      <td>{sortedPurchases.length - index}</td>

      <td>{get_purchase.new_id}</td>

    <td>{(t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ? get_purchase.date_creation.substring(0, 10).split("-")[2] + '/' + get_purchase.date_creation.substring(0, 10).split("-")[1] + '/' + get_purchase.date_creation.substring(0, 10).split("-")[0] : get_purchase.date_creation.substring(0, 10).split("-")[1] + '/' + get_purchase.date_creation.substring(0, 10).split("-")[2] + '/' + get_purchase.date_creation.substring(0, 10).split("-")[0]}</td>
    <td>
    {(get_purchase.status === 1 ? t('Purchases.Pending') :
      get_purchase.status === 2 ? t('Purchases.Approved') :
      get_purchase.status === 3 ? t('Purchases.Online') :
      get_purchase.status === 4 ? t('Purchases.Suspended') :
      get_purchase.status === 5 ? t('Purchases.Deleted') : "" )}</td>
      <td>{get_purchase.presenter}</td>
      <td>{get_purchase.title}</td>
      <td><s>{get_purchase.initial_selling_price.toFixed(2) + "€ "}</s>{get_purchase.negotiated_selling_price.toFixed(2) + "€"}</td>
      <td>
      {get_purchase.status === 3 && get_purchase.user === user._id ? 
               <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
               <option value="">{t('Purchases.Select_action')}</option>
               <option value= {'{"index": "1", "purchase": {"_id": "' + get_purchase._id + '", "new_id": "' + get_purchase.new_id + '", "presenter": "' + get_purchase.presenter + '", "title": "' + get_purchase.title + '", "description": "' + get_purchase.description + '", "initial_selling_price": "' + get_purchase.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_purchase.negotiated_selling_price.toFixed(2) + '", "offer_expiry_date": "' + get_purchase.offer_expiry_date + '", "max_participants": "' + get_purchase.max_participants + '", "participants": "' + get_purchase.participants + '", "participantsUsername": "' + get_purchase.participantsUsername + '", "delivery_method": "' + get_purchase.delivery_method + '", "further_information": "' + get_purchase.further_information + '", "city": "' + get_purchase.city + '", "postal_code": "' + get_purchase.postal_code + '", "country": "' + get_purchase.country + '", "attachment_name_first": "' + get_purchase.attachment_name_first + '", "attachment_name_second": "' + get_purchase.attachment_name_second + '", "attachment_name_third": "' + get_purchase.attachment_name_third + '", "status": "' + get_purchase.status + '", "date_creation": "' + get_purchase.date_creation +'"}}'}>{t('Purchases.Details')}</option>
               <option value= {'{"index": "4", "purchase": {"_id": "' + get_purchase._id + '", "new_id": "' + get_purchase.new_id + '", "presenter": "' + get_purchase.presenter + '", "title": "' + get_purchase.title + '", "description": "' + get_purchase.description + '", "initial_selling_price": "' + get_purchase.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_purchase.negotiated_selling_price.toFixed(2) + '", "offer_expiry_date": "' + get_purchase.offer_expiry_date + '", "max_participants": "' + get_purchase.max_participants + '", "participants": "' + get_purchase.participants + '", "participantsUsername": "' + get_purchase.participantsUsername + '", "delivery_method": "' + get_purchase.delivery_method + '", "further_information": "' + get_purchase.further_information + '", "city": "' + get_purchase.city + '", "postal_code": "' + get_purchase.postal_code + '", "country": "' + get_purchase.country + '", "attachment_name_first": "' + get_purchase.attachment_name_first + '", "attachment_name_second": "' + get_purchase.attachment_name_second + '", "attachment_name_third": "' + get_purchase.attachment_name_third + '", "status": "' + get_purchase.status + '", "date_creation": "' + get_purchase.date_creation +'"}}'}>{t('Purchases.Suspend')}</option>
               <option value= {'{"index": "5", "purchase": {"_id": "' + get_purchase._id + '", "new_id": "' + get_purchase.new_id + '", "presenter": "' + get_purchase.presenter + '", "title": "' + get_purchase.title + '", "description": "' + get_purchase.description + '", "initial_selling_price": "' + get_purchase.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_purchase.negotiated_selling_price.toFixed(2) + '", "offer_expiry_date": "' + get_purchase.offer_expiry_date + '", "max_participants": "' + get_purchase.max_participants + '", "participants": "' + get_purchase.participants + '", "participantsUsername": "' + get_purchase.participantsUsername + '", "delivery_method": "' + get_purchase.delivery_method + '", "further_information": "' + get_purchase.further_information + '", "city": "' + get_purchase.city + '", "postal_code": "' + get_purchase.postal_code + '", "country": "' + get_purchase.country + '", "attachment_name_first": "' + get_purchase.attachment_name_first + '", "attachment_name_second": "' + get_purchase.attachment_name_second + '", "attachment_name_third": "' + get_purchase.attachment_name_third + '", "status": "' + get_purchase.status + '", "date_creation": "' + get_purchase.date_creation +'"}}'}>{t('Purchases.Delete')}</option>
             </select>
             :
             get_purchase.status === 4 && get_purchase.user === user._id ? 
               <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
               <option value="">{t('Purchases.Select_action')}</option>
               <option value= {'{"index": "1", "purchase": {"_id": "' + get_purchase._id + '", "new_id": "' + get_purchase.new_id + '", "presenter": "' + get_purchase.presenter + '", "title": "' + get_purchase.title + '", "description": "' + get_purchase.description + '", "initial_selling_price": "' + get_purchase.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_purchase.negotiated_selling_price.toFixed(2) + '", "offer_expiry_date": "' + get_purchase.offer_expiry_date + '", "max_participants": "' + get_purchase.max_participants + '", "participants": "' + get_purchase.participants + '", "participantsUsername": "' + get_purchase.participantsUsername + '", "delivery_method": "' + get_purchase.delivery_method + '", "further_information": "' + get_purchase.further_information + '", "city": "' + get_purchase.city + '", "postal_code": "' + get_purchase.postal_code + '", "country": "' + get_purchase.country + '", "attachment_name_first": "' + get_purchase.attachment_name_first + '", "attachment_name_second": "' + get_purchase.attachment_name_second + '", "attachment_name_third": "' + get_purchase.attachment_name_third + '", "status": "' + get_purchase.status + '", "date_creation": "' + get_purchase.date_creation +'"}}'}>{t('Purchases.Details')}</option>
               <option value= {'{"index": "3", "purchase": {"_id": "' + get_purchase._id + '", "new_id": "' + get_purchase.new_id + '", "presenter": "' + get_purchase.presenter + '", "title": "' + get_purchase.title + '", "description": "' + get_purchase.description + '", "initial_selling_price": "' + get_purchase.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_purchase.negotiated_selling_price.toFixed(2) + '", "offer_expiry_date": "' + get_purchase.offer_expiry_date + '", "max_participants": "' + get_purchase.max_participants + '", "participants": "' + get_purchase.participants + '", "participantsUsername": "' + get_purchase.participantsUsername + '", "delivery_method": "' + get_purchase.delivery_method + '", "further_information": "' + get_purchase.further_information + '", "city": "' + get_purchase.city + '", "postal_code": "' + get_purchase.postal_code + '", "country": "' + get_purchase.country + '", "attachment_name_first": "' + get_purchase.attachment_name_first + '", "attachment_name_second": "' + get_purchase.attachment_name_second + '", "attachment_name_third": "' + get_purchase.attachment_name_third + '", "status": "' + get_purchase.status + '", "date_creation": "' + get_purchase.date_creation +'"}}'}>{t('Purchases.Publish')}</option>
               <option value= {'{"index": "5", "purchase": {"_id": "' + get_purchase._id + '", "new_id": "' + get_purchase.new_id + '", "presenter": "' + get_purchase.presenter + '", "title": "' + get_purchase.title + '", "description": "' + get_purchase.description + '", "initial_selling_price": "' + get_purchase.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_purchase.negotiated_selling_price.toFixed(2) + '", "offer_expiry_date": "' + get_purchase.offer_expiry_date + '", "max_participants": "' + get_purchase.max_participants + '", "participants": "' + get_purchase.participants + '", "participantsUsername": "' + get_purchase.participantsUsername + '", "delivery_method": "' + get_purchase.delivery_method + '", "further_information": "' + get_purchase.further_information + '", "city": "' + get_purchase.city + '", "postal_code": "' + get_purchase.postal_code + '", "country": "' + get_purchase.country + '", "attachment_name_first": "' + get_purchase.attachment_name_first + '", "attachment_name_second": "' + get_purchase.attachment_name_second + '", "attachment_name_third": "' + get_purchase.attachment_name_third + '", "status": "' + get_purchase.status + '", "date_creation": "' + get_purchase.date_creation +'"}}'}>{t('Purchases.Delete')}</option>
             </select>
             : get_purchase.user === user._id && get_purchase.status !== 5 ?
             <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
               <option value="">{t('Purchases.Select_action')}</option>
               <option value= {'{"index": "1", "purchase": {"_id": "' + get_purchase._id + '", "new_id": "' + get_purchase.new_id + '", "presenter": "' + get_purchase.presenter + '", "title": "' + get_purchase.title + '", "description": "' + get_purchase.description + '", "initial_selling_price": "' + get_purchase.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_purchase.negotiated_selling_price.toFixed(2) + '", "offer_expiry_date": "' + get_purchase.offer_expiry_date + '", "max_participants": "' + get_purchase.max_participants + '", "participants": "' + get_purchase.participants + '", "participantsUsername": "' + get_purchase.participantsUsername + '", "delivery_method": "' + get_purchase.delivery_method + '", "further_information": "' + get_purchase.further_information + '", "city": "' + get_purchase.city + '", "postal_code": "' + get_purchase.postal_code + '", "country": "' + get_purchase.country + '", "attachment_name_first": "' + get_purchase.attachment_name_first + '", "attachment_name_second": "' + get_purchase.attachment_name_second + '", "attachment_name_third": "' + get_purchase.attachment_name_third + '", "status": "' + get_purchase.status + '", "date_creation": "' + get_purchase.date_creation +'"}}'}>{t('Purchases.Details')}</option>
               <option value= {'{"index": "5", "purchase": {"_id": "' + get_purchase._id + '", "new_id": "' + get_purchase.new_id + '", "presenter": "' + get_purchase.presenter + '", "title": "' + get_purchase.title + '", "description": "' + get_purchase.description + '", "initial_selling_price": "' + get_purchase.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_purchase.negotiated_selling_price.toFixed(2) + '", "offer_expiry_date": "' + get_purchase.offer_expiry_date + '", "max_participants": "' + get_purchase.max_participants + '", "participants": "' + get_purchase.participants + '", "participantsUsername": "' + get_purchase.participantsUsername + '", "delivery_method": "' + get_purchase.delivery_method + '", "further_information": "' + get_purchase.further_information + '", "city": "' + get_purchase.city + '", "postal_code": "' + get_purchase.postal_code + '", "country": "' + get_purchase.country + '", "attachment_name_first": "' + get_purchase.attachment_name_first + '", "attachment_name_second": "' + get_purchase.attachment_name_second + '", "attachment_name_third": "' + get_purchase.attachment_name_third + '", "status": "' + get_purchase.status + '", "date_creation": "' + get_purchase.date_creation +'"}}'}>{t('Purchases.Delete')}</option>
             </select>
             : get_purchase.participants.includes(user._id) ?
             <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
               <option value="">{t('Purchases.Select_action')}</option>
               <option value= {'{"index": "1", "purchase": {"_id": "' + get_purchase._id + '", "new_id": "' + get_purchase.new_id + '", "presenter": "' + get_purchase.presenter + '", "title": "' + get_purchase.title + '", "description": "' + get_purchase.description + '", "initial_selling_price": "' + get_purchase.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_purchase.negotiated_selling_price.toFixed(2) + '", "offer_expiry_date": "' + get_purchase.offer_expiry_date + '", "max_participants": "' + get_purchase.max_participants + '", "participants": "' + get_purchase.participants + '", "participantsUsername": "' + get_purchase.participantsUsername + '", "delivery_method": "' + get_purchase.delivery_method + '", "further_information": "' + get_purchase.further_information + '", "city": "' + get_purchase.city + '", "postal_code": "' + get_purchase.postal_code + '", "country": "' + get_purchase.country + '", "attachment_name_first": "' + get_purchase.attachment_name_first + '", "attachment_name_second": "' + get_purchase.attachment_name_second + '", "attachment_name_third": "' + get_purchase.attachment_name_third + '", "status": "' + get_purchase.status + '", "date_creation": "' + get_purchase.date_creation +'"}}'}>{t('Purchases.Details')}</option>
               <option value= {'{"index": "8", "purchase": {"_id": "' + get_purchase._id + '", "new_id": "' + get_purchase.new_id + '", "presenter": "' + get_purchase.presenter + '", "title": "' + get_purchase.title + '", "description": "' + get_purchase.description + '", "initial_selling_price": "' + get_purchase.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_purchase.negotiated_selling_price.toFixed(2) + '", "offer_expiry_date": "' + get_purchase.offer_expiry_date + '", "max_participants": "' + get_purchase.max_participants + '", "participants": "' + get_purchase.participants + '", "participantsUsername": "' + get_purchase.participantsUsername + '", "delivery_method": "' + get_purchase.delivery_method + '", "further_information": "' + get_purchase.further_information + '", "city": "' + get_purchase.city + '", "postal_code": "' + get_purchase.postal_code + '", "country": "' + get_purchase.country + '", "attachment_name_first": "' + get_purchase.attachment_name_first + '", "attachment_name_second": "' + get_purchase.attachment_name_second + '", "attachment_name_third": "' + get_purchase.attachment_name_third + '", "status": "' + get_purchase.status + '", "date_creation": "' + get_purchase.date_creation +'"}}'}>{t('Purchases.Withdraw')}</option>
               <option value= {'{"index": "6", "purchase": {"_id": "' + get_purchase._id + '", "new_id": "' + get_purchase.new_id + '", "presenter": "' + get_purchase.presenter + '", "title": "' + get_purchase.title + '", "description": "' + get_purchase.description + '", "initial_selling_price": "' + get_purchase.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_purchase.negotiated_selling_price.toFixed(2) + '", "offer_expiry_date": "' + get_purchase.offer_expiry_date + '", "max_participants": "' + get_purchase.max_participants + '", "participants": "' + get_purchase.participants + '", "participantsUsername": "' + get_purchase.participantsUsername + '", "delivery_method": "' + get_purchase.delivery_method + '", "further_information": "' + get_purchase.further_information + '", "city": "' + get_purchase.city + '", "postal_code": "' + get_purchase.postal_code + '", "country": "' + get_purchase.country + '", "attachment_name_first": "' + get_purchase.attachment_name_first + '", "attachment_name_second": "' + get_purchase.attachment_name_second + '", "attachment_name_third": "' + get_purchase.attachment_name_third + '", "status": "' + get_purchase.status + '", "date_creation": "' + get_purchase.date_creation +'"}}'}>{t('Purchases.Send_message')}</option>
             </select>
             : (((Number(get_purchase.max_participants)) - get_purchase.participants.length) <= 0) ?
             <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
               <option value="">{t('Purchases.Select_action')}</option>
               <option value= {'{"index": "1", "purchase": {"_id": "' + get_purchase._id + '", "new_id": "' + get_purchase.new_id + '", "presenter": "' + get_purchase.presenter + '", "title": "' + get_purchase.title + '", "description": "' + get_purchase.description + '", "initial_selling_price": "' + get_purchase.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_purchase.negotiated_selling_price.toFixed(2) + '", "offer_expiry_date": "' + get_purchase.offer_expiry_date + '", "max_participants": "' + get_purchase.max_participants + '", "participants": "' + get_purchase.participants + '", "participantsUsername": "' + get_purchase.participantsUsername + '", "delivery_method": "' + get_purchase.delivery_method + '", "further_information": "' + get_purchase.further_information + '", "city": "' + get_purchase.city + '", "postal_code": "' + get_purchase.postal_code + '", "country": "' + get_purchase.country + '", "attachment_name_first": "' + get_purchase.attachment_name_first + '", "attachment_name_second": "' + get_purchase.attachment_name_second + '", "attachment_name_third": "' + get_purchase.attachment_name_third + '", "status": "' + get_purchase.status + '", "date_creation": "' + get_purchase.date_creation +'"}}'}>{t('Purchases.Details')}</option>
               <option value= {'{"index": "6", "purchase": {"_id": "' + get_purchase._id + '", "new_id": "' + get_purchase.new_id + '", "presenter": "' + get_purchase.presenter + '", "title": "' + get_purchase.title + '", "description": "' + get_purchase.description + '", "initial_selling_price": "' + get_purchase.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_purchase.negotiated_selling_price.toFixed(2) + '", "offer_expiry_date": "' + get_purchase.offer_expiry_date + '", "max_participants": "' + get_purchase.max_participants + '", "participants": "' + get_purchase.participants + '", "participantsUsername": "' + get_purchase.participantsUsername + '", "delivery_method": "' + get_purchase.delivery_method + '", "further_information": "' + get_purchase.further_information + '", "city": "' + get_purchase.city + '", "postal_code": "' + get_purchase.postal_code + '", "country": "' + get_purchase.country + '", "attachment_name_first": "' + get_purchase.attachment_name_first + '", "attachment_name_second": "' + get_purchase.attachment_name_second + '", "attachment_name_third": "' + get_purchase.attachment_name_third + '", "status": "' + get_purchase.status + '", "date_creation": "' + get_purchase.date_creation +'"}}'}>{t('Purchases.Send_message')}</option>
             </select>
             :
             <select className="form-control" name="action" value={""} onChange={e => dropDownSelected(e.target.value)}>
               <option value="">{t('Purchases.Select_action')}</option>
               <option value= {'{"index": "1", "purchase": {"_id": "' + get_purchase._id + '", "new_id": "' + get_purchase.new_id + '", "presenter": "' + get_purchase.presenter + '", "title": "' + get_purchase.title + '", "description": "' + get_purchase.description + '", "initial_selling_price": "' + get_purchase.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_purchase.negotiated_selling_price.toFixed(2) + '", "offer_expiry_date": "' + get_purchase.offer_expiry_date + '", "max_participants": "' + get_purchase.max_participants + '", "participants": "' + get_purchase.participants + '", "participantsUsername": "' + get_purchase.participantsUsername + '", "delivery_method": "' + get_purchase.delivery_method + '", "further_information": "' + get_purchase.further_information + '", "city": "' + get_purchase.city + '", "postal_code": "' + get_purchase.postal_code + '", "country": "' + get_purchase.country + '", "attachment_name_first": "' + get_purchase.attachment_name_first + '", "attachment_name_second": "' + get_purchase.attachment_name_second + '", "attachment_name_third": "' + get_purchase.attachment_name_third + '", "status": "' + get_purchase.status + '", "date_creation": "' + get_purchase.date_creation +'"}}'}>{t('Purchases.Details')}</option>
               <option value= {'{"index": "7", "purchase": {"_id": "' + get_purchase._id + '", "new_id": "' + get_purchase.new_id + '", "presenter": "' + get_purchase.presenter + '", "title": "' + get_purchase.title + '", "description": "' + get_purchase.description + '", "initial_selling_price": "' + get_purchase.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_purchase.negotiated_selling_price.toFixed(2) + '", "offer_expiry_date": "' + get_purchase.offer_expiry_date + '", "max_participants": "' + get_purchase.max_participants + '", "participants": "' + get_purchase.participants + '", "participantsUsername": "' + get_purchase.participantsUsername + '", "delivery_method": "' + get_purchase.delivery_method + '", "further_information": "' + get_purchase.further_information + '", "city": "' + get_purchase.city + '", "postal_code": "' + get_purchase.postal_code + '", "country": "' + get_purchase.country + '", "attachment_name_first": "' + get_purchase.attachment_name_first + '", "attachment_name_second": "' + get_purchase.attachment_name_second + '", "attachment_name_third": "' + get_purchase.attachment_name_third + '", "status": "' + get_purchase.status + '", "date_creation": "' + get_purchase.date_creation +'"}}'}>{t('Purchases.Participate')}</option>
               <option value= {'{"index": "6", "purchase": {"_id": "' + get_purchase._id + '", "new_id": "' + get_purchase.new_id + '", "presenter": "' + get_purchase.presenter + '", "title": "' + get_purchase.title + '", "description": "' + get_purchase.description + '", "initial_selling_price": "' + get_purchase.initial_selling_price.toFixed(2) + '", "negotiated_selling_price": "' + get_purchase.negotiated_selling_price.toFixed(2) + '", "offer_expiry_date": "' + get_purchase.offer_expiry_date + '", "max_participants": "' + get_purchase.max_participants + '", "participants": "' + get_purchase.participants + '", "participantsUsername": "' + get_purchase.participantsUsername + '", "delivery_method": "' + get_purchase.delivery_method + '", "further_information": "' + get_purchase.further_information + '", "city": "' + get_purchase.city + '", "postal_code": "' + get_purchase.postal_code + '", "country": "' + get_purchase.country + '", "attachment_name_first": "' + get_purchase.attachment_name_first + '", "attachment_name_second": "' + get_purchase.attachment_name_second + '", "attachment_name_third": "' + get_purchase.attachment_name_third + '", "status": "' + get_purchase.status + '", "date_creation": "' + get_purchase.date_creation +'"}}'}>{t('Purchases.Send_message')}</option>
             </select>
             }
      </td>
    </tr>
  );
})}

  </tbody>
</table>
</div>
</div>


<div
 style={{
   display: "flex",
   justifyContent: "center",
   alignItems: "center"
 }}>
<div className="scrollme"> 
<table>
{ sortedPurchases.length > 0 ? <Pagination usersPerPage={purchasesPerPage} totalUsers={users_purchases && sortedPurchases.length > 0 ? sortedPurchases.length : 0} paginate={paginate} nextPage={nextPage} prevPage={prevPage} firstPage={firstPage} lastPage={lastPage} currentPage={currentPage} /> : null }
</table>
</div>
</div>
  </Fragment>;



const showModalPublish = 
<Modal className="my-modal" show={modalsSecondOpen} onHide={() => setModalSecondIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}</ModalBody>
<ModalFooter as="footer">

  {modalFirstButton !== "" ? 
  <>
<button className="btn btn-primary" onClick={() => publishButtonClicked()}>{modalFirstButton}</button>
</> :
null
  }
    {modalSecondButton !== "" ? 
    
<button className="btn btn-primary" onClick={() => setModalSecondIsOpen(false)}>{modalSecondButton}</button>

 :
 null
  }

</ModalFooter>
</Modal>



const showModalSuspend = 
<Modal className="my-modal" show={modalsThirdOpen} onHide={() => setModalThirdIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
<ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
style={{
whiteSpace: "pre-line"
}}>{modalBody}</ModalBody>
<ModalFooter as="footer">

{modalFirstButton !== "" ? 
<>
<button className="btn btn-primary" onClick={() => suspendButtonClicked()}>{modalFirstButton}</button>
</> :
null
}
  {modalSecondButton !== "" ? 
  
<button className="btn btn-primary" onClick={() => setModalThirdIsOpen(false)}>{modalSecondButton}</button>

:
null
}

</ModalFooter>
</Modal>



const showModalDelete = 
<Modal className="my-modal" show={modalsFourthOpen} onHide={() => setModalFourthIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
<ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
style={{
whiteSpace: "pre-line"
}}>{modalBody}</ModalBody>
<ModalFooter as="footer">

{modalFirstButton !== "" ? 
<>
<button className="btn btn-danger" onClick={() => deleteButtonClicked()}>{modalFirstButton}</button>
</> :
null
}
  {modalSecondButton !== "" ? 
  
<button className="btn btn-primary" onClick={() => setModalFourthIsOpen(false)}>{modalSecondButton}</button>

:
null
}

</ModalFooter>
</Modal>



const showModalDetail = 
<Modal className="my-modal" show={modalsDetailOpen} onHide={() => setModalDetailIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
<ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
style={{
whiteSpace: "pre-line"
}}>  {purchaseImages.length > 0 ? (
<div className="carousel-container">
  <div className="carousel-wrapper">
    <div className="carousel-slides">
    <div
                      className="carousel-item-wrapper"
                      onMouseMove={handleZoom}
                      onMouseLeave={resetZoom}
                    >
                      <img
                        src={purchaseImages[currentIndex]}
                        alt={`Image ${currentIndex + 1}`}
                        onClick={() => window.open(purchaseImages[currentIndex], "_blank")}
                        className={`carousel-image ${isZooming ? "zoomed" : ""}`}
                        style={zoomStyle}
                      />
                    </div>
    </div>
  <button
    className="carousel-control prev"
    onClick={handlePrev}
  >
    &#10094;
  </button>
  <button
    className="carousel-control next"
    onClick={handleNext}
  >
    &#10095;
  </button>
</div>
</div>
) : (
<></>
)}
<br/>
{modalBody}<div/></ModalBody>
<ModalFooter as="footer">

{modalFirstButton !== "" ? 
<>
<button className="btn btn-primary" onClick={() => setModalDetailIsOpen(false)}>{modalFirstButton}</button>
</> :
null
}
  {modalSecondButton !== "" ? 
  
<button className="btn btn-primary" onClick={() => setModalDetailIsOpen(false)}>{modalSecondButton}</button>

:
null
}

</ModalFooter>
</Modal>


const showModalPublishApprovedPurchases = 
<Modal className="my-modal" show={modalsPublishApprovedPurchasesOpen} onHide={() => setModalPublishApprovedPurchasesIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
<ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
style={{
whiteSpace: "pre-line"
}}>{modalBody}</ModalBody>
<ModalFooter as="footer">

{modalFirstButton !== "" ? 
<>
<button className="btn btn-primary" onClick={() => publishPurchasesClicked()}>{modalFirstButton}</button>
</> :
null
}
  {modalSecondButton !== "" ? 
  
<button className="btn btn-primary" onClick={() => setModalPublishApprovedPurchasesIsOpen(false)}>{modalSecondButton}</button>

:
null
}

</ModalFooter>
</Modal>



const showSendMessageModal = 
<Modal className="my-modal" show={modalsSendMessageOpen} onHide={() => setModalSendMessageIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
<ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
style={{
whiteSpace: "pre-line"
}}>{modalBody}
    <form className="form">
      <div className="form-group">
        <input
          type="text"
          placeholder={t('Purchases.My_message')}
          name="myMessage"
          value={myMessage}
          onChange={e => setMyMessage(e.target.value)}
          minLength="1"
        />
      </div>
    </form>
    </ModalBody>
<ModalFooter as="footer">

{modalFirstButton !== "" ? 
<>
<button className="btn btn-primary" onClick={() => sendMessageButtonClicked()}>{modalFirstButton}</button>
</> :
null
}
  {modalSecondButton !== "" ? 
  
<button className="btn btn-primary" onClick={() => setModalSendMessageIsOpen(false)}>{modalSecondButton}</button>

:
null
}

</ModalFooter>
</Modal>


const showParticipatePurchaseModal = 
<Modal className="my-modal" show={modalsParticipatePurchaseOpen} onHide={() => setModalParticipatePurchaseIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
<ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
style={{
whiteSpace: "pre-line"
}}>{modalBody}</ModalBody>
<ModalFooter as="footer">
{modalFirstButton !== "" ? 
<>
<button className="btn btn-primary" onClick={() => participatePurchaseClicked()}>{modalFirstButton}</button>
</> :
null
}
  {modalSecondButton !== "" ? 
<button className="btn btn-primary" onClick={() => setModalParticipatePurchaseIsOpen(false)}>{modalSecondButton}</button>
:
null
}
</ModalFooter>
</Modal>


const showWithdrawPurchaseModal = 
<Modal className="my-modal" show={modalsWithdrawPurchaseOpen} onHide={() => setModalWithdrawPurchaseIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
<ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
style={{
whiteSpace: "pre-line"
}}>{modalBody}</ModalBody>
<ModalFooter as="footer">
{modalFirstButton !== "" ? 
<>
<button className="btn btn-primary" onClick={() => withdrawPurchaseClicked()}>{modalFirstButton}</button>
</> :
null
}
  {modalSecondButton !== "" ? 
<button className="btn btn-primary" onClick={() => setModalWithdrawPurchaseIsOpen(false)}>{modalSecondButton}</button>
:
null
}
</ModalFooter>
</Modal>


      const tables =
      
      <Fragment>
      <PurchasesActions />
      
     
    { users_purchases !== null ? (
 
<div>

{showTable}

</div>
    ) : (
<Fragment>
</Fragment>
    )}
  </Fragment>;

      


function findByCriteria (name) {

  if ( searchCriteria === "Y") {
    setSearchField(name.replace(/\D/,''));
  }
  else if (searchCriteria === "D" || searchCriteria === "M") {
    setSearchField(name.replace(/(?!-)[^0-9.]/,''));
  }
  else {
    setSearchField(name);
  }


  if (searchCriteria === "D" && ((name.length === 2 && searchField.length === 1) || (name.length === 5 && searchField.length === 4))){
    setSearchField(name + "-")
}
else if (searchCriteria === "M" && ((name.length === 2 && searchField.length === 1))){
  setSearchField(name + "-")
}


  setCurrentPage(1);
}

function convertDate(dateCreation) {
  var finalDateCreation = ""
  if (dateCreation !== "") {
    const newDateCreation = dateCreation.substring(0, 10).split("-")
    finalDateCreation = newDateCreation[1] + '/' + newDateCreation[2] + '/' + newDateCreation[0];
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      finalDateCreation = newDateCreation[2] + '/' + newDateCreation[1] + '/' + newDateCreation[0];
    }
  }
  return finalDateCreation;
}

function onClickPublishItem(newPurchase) {
  setModalTitle(t('Purchases.Publish_sure')+ ': "' + JSON.parse(JSON.stringify(newPurchase.title))+'"?');
  setModalBody(
    t('Purchases.Title')+" : " + (JSON.parse(JSON.stringify(newPurchase.title)) !== '' ?
    JSON.parse(JSON.stringify(newPurchase.title)) : t('Purchases.None'))  +
   "\n"+t('Purchases.Initial_selling_price')+" : " + JSON.parse(JSON.stringify(newPurchase.initial_selling_price)) + "€"  +
   "\n"+t('Purchases.Negotiated_selling_price')+" : " + JSON.parse(JSON.stringify(newPurchase.negotiated_selling_price)) + "€"  +
   "\n"+t('Purchases.Offer_expiry_date')+" : " + convertDate(JSON.parse(JSON.stringify(newPurchase.offer_expiry_date))) +
   "\n"+t('Purchases.Max_participants')+" : " + JSON.parse(JSON.stringify(newPurchase.max_participants)) +
   "\n"+t('Purchases.Available_places')+" : " + (Number(JSON.parse(JSON.stringify(newPurchase.max_participants))) - (JSON.parse(JSON.stringify(newPurchase.participants)) !== "" ? JSON.parse(JSON.stringify(newPurchase.participants)).split(",").length : 0)) +
   "\n"+t('Purchases.Participants_list')+" : " + (JSON.parse(JSON.stringify(newPurchase.participantsUsername)) !== '' ? JSON.parse(JSON.stringify(newPurchase.participantsUsername)) : t('Purchases.None'))
  );
  setModalFirstButton(t('Purchases.Publish'));
  setModalSecondButton("");
 setModalSecondIsOpen(true);
}


function onClickSuspendItem(newPurchase) {
setModalTitle(t('Purchases.Suspend_sure')+ ': "' + JSON.parse(JSON.stringify(newPurchase.title))+'"?');
setModalBody(
  t('Purchases.Title')+" : " + (JSON.parse(JSON.stringify(newPurchase.title)) !== '' ?
  JSON.parse(JSON.stringify(newPurchase.title)) : t('Purchases.None'))  +
  "\n"+t('Purchases.Initial_selling_price')+" : " + JSON.parse(JSON.stringify(newPurchase.initial_selling_price)) + "€"  +
  "\n"+t('Purchases.Negotiated_selling_price')+" : " + JSON.parse(JSON.stringify(newPurchase.negotiated_selling_price)) + "€"  +
  "\n"+t('Purchases.Offer_expiry_date')+" : " + convertDate(JSON.parse(JSON.stringify(newPurchase.offer_expiry_date))) +
  "\n"+t('Purchases.Max_participants')+" : " + JSON.parse(JSON.stringify(newPurchase.max_participants)) +
  "\n"+t('Purchases.Available_places')+" : " + (Number(JSON.parse(JSON.stringify(newPurchase.max_participants))) - (JSON.parse(JSON.stringify(newPurchase.participants)) !== "" ? JSON.parse(JSON.stringify(newPurchase.participants)).split(",").length : 0)) +
  "\n"+t('Purchases.Participants_list')+" : " + (JSON.parse(JSON.stringify(newPurchase.participantsUsername)) !== '' ? JSON.parse(JSON.stringify(newPurchase.participantsUsername)) : t('Purchases.None'))
);
setModalFirstButton(t('Purchases.Suspend'));
setModalSecondButton("");
setModalThirdIsOpen(true);
}

function onClickDeleteItem(newPurchase) {
  setModalTitle(t('Purchases.Delete_sure')+ ': "' + JSON.parse(JSON.stringify(newPurchase.title))+'"?');
  setModalBody(
    t('Purchases.Title')+" : " + (JSON.parse(JSON.stringify(newPurchase.title)) !== '' ?
    JSON.parse(JSON.stringify(newPurchase.title)) : t('Purchases.None'))  +
    "\n"+t('Purchases.Initial_selling_price')+" : " + JSON.parse(JSON.stringify(newPurchase.initial_selling_price)) + "€"  +
    "\n"+t('Purchases.Negotiated_selling_price')+" : " + JSON.parse(JSON.stringify(newPurchase.negotiated_selling_price)) + "€"  +
    "\n"+t('Purchases.Offer_expiry_date')+" : " + convertDate(JSON.parse(JSON.stringify(newPurchase.offer_expiry_date))) +
    "\n"+t('Purchases.Max_participants')+" : " + JSON.parse(JSON.stringify(newPurchase.max_participants)) +
    "\n"+t('Purchases.Available_places')+" : " + (Number(JSON.parse(JSON.stringify(newPurchase.max_participants))) - (JSON.parse(JSON.stringify(newPurchase.participants)) !== "" ? JSON.parse(JSON.stringify(newPurchase.participants)).split(",").length : 0)) +
    "\n"+t('Purchases.Participants_list')+" : " + (JSON.parse(JSON.stringify(newPurchase.participantsUsername)) !== '' ? JSON.parse(JSON.stringify(newPurchase.participantsUsername)) : t('Purchases.None'))
  );
  setModalFirstButton(t('Purchases.Delete'));
  setModalSecondButton("");
 setModalFourthIsOpen(true);
}

function onClickSendMessageItem(newPurchase) {
  setMyPurchase({user: JSON.parse(JSON.stringify(newPurchase.user)),
      advertId: JSON.parse(JSON.stringify(newPurchase._id)),
          title: JSON.parse(JSON.stringify(newPurchase.title)),
          receiverUsername: JSON.parse(JSON.stringify(newPurchase.presenter)),
  })
  setModalTitle(t('Purchases.Interested_article')+ ': "' + JSON.parse(JSON.stringify(newPurchase.title))+'"?');
  setModalBody(t('Purchases.Enter_message'));
  setModalFirstButton(t('Purchases.Send'));
  setModalSecondButton("");
  setModalSendMessageIsOpen(true);
}

function onClickParticipateItem(newPurchase) {
  setModalTitle(t('Purchases.Participate_sure')+ ': "' + JSON.parse(JSON.stringify(newPurchase.title))+'"?');
  setModalBody(
    t('Purchases.Title')+" : " + (JSON.parse(JSON.stringify(newPurchase.title)) !== '' ?
    JSON.parse(JSON.stringify(newPurchase.title)) : t('Purchases.None'))  +
    "\n"+t('Purchases.Initial_selling_price')+" : " + JSON.parse(JSON.stringify(newPurchase.initial_selling_price)) + "€"  +
    "\n"+t('Purchases.Negotiated_selling_price')+" : " + JSON.parse(JSON.stringify(newPurchase.negotiated_selling_price)) + "€"  +
    "\n"+t('Purchases.Offer_expiry_date')+" : " + convertDate(JSON.parse(JSON.stringify(newPurchase.offer_expiry_date))) +
    "\n"+t('Purchases.Max_participants')+" : " + JSON.parse(JSON.stringify(newPurchase.max_participants)) +
    "\n"+t('Purchases.Available_places')+" : " + (Number(JSON.parse(JSON.stringify(newPurchase.max_participants))) - (JSON.parse(JSON.stringify(newPurchase.participants)) !== "" ? JSON.parse(JSON.stringify(newPurchase.participants)).split(",").length : 0)) +
    "\n"+t('Purchases.Participants_list')+" : " + (JSON.parse(JSON.stringify(newPurchase.participantsUsername)) !== '' ? JSON.parse(JSON.stringify(newPurchase.participantsUsername)) : t('Purchases.None'))
  );
  setModalFirstButton(t('Purchases.Participate'));
  setModalSecondButton("");
  setModalParticipatePurchaseIsOpen(true);
  }

  function onClickWithdrawItem(newPurchase) {
    setModalTitle(t('Purchases.Withdraw_sure')+ ': "' + JSON.parse(JSON.stringify(newPurchase.title))+'"?');
    setModalBody(
      t('Purchases.Title')+" : " + (JSON.parse(JSON.stringify(newPurchase.title)) !== '' ?
      JSON.parse(JSON.stringify(newPurchase.title)) : t('Purchases.None'))  +
      "\n"+t('Purchases.Initial_selling_price')+" : " + JSON.parse(JSON.stringify(newPurchase.initial_selling_price)) + "€"  +
      "\n"+t('Purchases.Negotiated_selling_price')+" : " + JSON.parse(JSON.stringify(newPurchase.negotiated_selling_price)) + "€"  +
      "\n"+t('Purchases.Offer_expiry_date')+" : " + convertDate(JSON.parse(JSON.stringify(newPurchase.offer_expiry_date))) +
      "\n"+t('Purchases.Max_participants')+" : " + JSON.parse(JSON.stringify(newPurchase.max_participants)) +
      "\n"+t('Purchases.Available_places')+" : " + (Number(JSON.parse(JSON.stringify(newPurchase.max_participants))) - (JSON.parse(JSON.stringify(newPurchase.participants)) !== "" ? JSON.parse(JSON.stringify(newPurchase.participants)).split(",").length : 0)) +
      "\n"+t('Purchases.Participants_list')+" : " + (JSON.parse(JSON.stringify(newPurchase.participantsUsername)) !== '' ? JSON.parse(JSON.stringify(newPurchase.participantsUsername)) : t('Purchases.None'))
    );
    setModalFirstButton(t('Purchases.Withdraw'));
    setModalSecondButton("");
    setModalWithdrawPurchaseIsOpen(true);
    }

function showDetailsTable(purchase) {
  var dateCreation = JSON.parse(JSON.stringify(purchase.date_creation))
  var finalDateCreation = ""
  if (dateCreation !== "") {
    dateCreation = JSON.parse(JSON.stringify(purchase.date_creation)).substring(0, 10).split("-")
    finalDateCreation = dateCreation[1] + '/' + dateCreation[2] + '/' + dateCreation[0];
  
    if (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") {
      finalDateCreation = dateCreation[2] + '/' + dateCreation[1] + '/' + dateCreation[0];
    }
  }
  setCurrentIndex(0);
  setPurchaseImages([purchase.attachment_name_first, purchase.attachment_name_second, purchase.attachment_name_third].filter(image => image !== ""))
  
  setModalTitle(JSON.parse(JSON.stringify(purchase.title)));
  setModalBody(
    t('Purchases.Reference')+" : " + JSON.parse(JSON.stringify(purchase.new_id))  +
    "\n"+t('Purchases.Presenter')+" : " + JSON.parse(JSON.stringify(purchase.presenter))  +
    "\n"+t('Purchases.Description')+" : " + JSON.parse(JSON.stringify(purchase.description))  +
    "\n"+t('Purchases.Further_information')+" : " + (JSON.parse(JSON.stringify(purchase.further_information)) !== '' ?
    JSON.parse(JSON.stringify(purchase.further_information)) : t('Purchases.None')) +
    "\n"+t('Purchases.Initial_selling_price')+" : " + JSON.parse(JSON.stringify(purchase.initial_selling_price)) + "€"  +
    "\n"+t('Purchases.Negotiated_selling_price')+" : " + JSON.parse(JSON.stringify(purchase.negotiated_selling_price)) + "€"  +
    "\n"+t('Purchases.Offer_expiry_date')+" : " + convertDate(JSON.parse(JSON.stringify(purchase.offer_expiry_date))) +
    "\n"+t('Purchases.Max_participants')+" : " + JSON.parse(JSON.stringify(purchase.max_participants)) +
    "\n"+t('Purchases.Available_places')+" : " + (Number(JSON.parse(JSON.stringify(purchase.max_participants))) - (JSON.parse(JSON.stringify(purchase.participants)) !== "" ? JSON.parse(JSON.stringify(purchase.participants)).split(",").length : 0)) +
    "\n"+t('Purchases.Participants_list')+" : " + (JSON.parse(JSON.stringify(purchase.participantsUsername)) !== '' ? JSON.parse(JSON.stringify(purchase.participantsUsername)) : t('Purchases.None')) +
  "\n"+t('Purchases.Delivery_method')+" : " + t('Purchases.'+JSON.parse(JSON.stringify(purchase.delivery_method)))  +
  "\n"+t('Purchases.City')+" : " + (JSON.parse(JSON.stringify(purchase.city)) !== '' ?
  JSON.parse(JSON.stringify(purchase.city)) : t('Purchases.None')) +
  "\n"+t('Purchases.Postal_code')+" : " + (JSON.parse(JSON.stringify(purchase.postal_code)) !== '' ?
  JSON.parse(JSON.stringify(purchase.postal_code)) : t('Purchases.None')) +
  "\n"+t('Purchases.Country')+" : " + t('Country.'+JSON.parse(JSON.stringify(purchase.country)))  +
  "\n"+t('Purchases.State')+" : " + (JSON.parse(JSON.stringify(purchase.status)) === "1" ? t('Purchases.Pending') :
  JSON.parse(JSON.stringify(purchase.status)) === "2" ? t('Purchases.Approved') :
  JSON.parse(JSON.stringify(purchase.status)) === "3" ? t('Purchases.Online') :
  JSON.parse(JSON.stringify(purchase.status)) === "4" ? t('Purchases.Suspended') :
  JSON.parse(JSON.stringify(purchase.status)) === "5" ? t('Purchases.Deleted') : t('Purchases.None')) +
  (purchase.date_creation !== "" ? ("\n"+t('Purchases.Date')+" : "  + finalDateCreation) : "")
  );
    setModalFirstButton("");
    setModalSecondButton(t('Purchases.Ok'));
   setModalDetailIsOpen(true);
  }


  function publishButtonClicked() {
      updatePurchase(purchaseId, 3, user._id, false);
      setModalSecondIsOpen(false);
  }
  
  function suspendButtonClicked() {
      updatePurchase(purchaseId, 4, user._id, false);
      setModalThirdIsOpen(false);
  }
  
  function deleteButtonClicked() {
      updatePurchase(purchaseId, 5, user._id, false);
      setModalFourthIsOpen(false);
  }
  
  function publishPurchasesClicked() {
    publishPurchases(user._id);
    setModalPublishApprovedPurchasesIsOpen(false);
  }

  function participatePurchaseClicked() {
    participatePurchase(purchaseId, user._id);
    setModalParticipatePurchaseIsOpen(false);
  }

  function withdrawPurchaseClicked() {
    withdrawPurchase(purchaseId, user._id);
    setModalWithdrawPurchaseIsOpen(false);
  }

  function sendMessageButtonClicked() {
      addAdvertMessage({senderId: user._id,
          receiverId: myPurchase.user,
              advertId: myPurchase.advertId,
                  category: "Purchase",
                      title: myPurchase.title,
                          senderUsername: user.username,
                          senderAdminUsername: "",
                              receiverUsername: myPurchase.receiverUsername,
                                  text: myMessage,
                                  history : history
      })
      setModalSendMessageIsOpen(false);
    }


const dropDownSelected = event => {
  if (JSON.parse(event).index === "1") {
    showDetailsTable(JSON.parse(event).purchase);
  }
  else if (JSON.parse(event).index === "3") {
    setPurchaseId(JSON.parse(event).purchase._id)
    onClickPublishItem(JSON.parse(event).purchase);
  }
  else if (JSON.parse(event).index === "4") {
    setPurchaseId(JSON.parse(event).purchase._id)
    onClickSuspendItem(JSON.parse(event).purchase);
  }
  else if (JSON.parse(event).index === "5") {
    setPurchaseId(JSON.parse(event).purchase._id)
    onClickDeleteItem(JSON.parse(event).purchase);
  }
  else if (JSON.parse(event).index === "6") {
    setPurchaseId(JSON.parse(event).purchase._id)
    onClickSendMessageItem(JSON.parse(event).purchase);
  }
  else if (JSON.parse(event).index === "7") {
    setPurchaseId(JSON.parse(event).purchase._id)
    onClickParticipateItem(JSON.parse(event).purchase);
  }
  else if (JSON.parse(event).index === "8") {
    setPurchaseId(JSON.parse(event).purchase._id)
    onClickWithdrawItem(JSON.parse(event).purchase);
  }
}

return loading && users_purchases === null ? (
  <Spinner />
) : (
  
  <Fragment>

{showModalPublish}
    {showModalSuspend}
    {showModalDelete}
    {showModalDetail}
    {showModalPublishApprovedPurchases}
    {showSendMessageModal}
    {showParticipatePurchaseModal}
    {showWithdrawPurchaseModal}
  {tables}



</Fragment>
);
};


Purchases.propTypes = {
  getPurchaseForClients: PropTypes.func.isRequired,
  updatePurchase: PropTypes.func.isRequired,
  publishPurchases: PropTypes.func.isRequired,
  participatePurchase: PropTypes.func.isRequired,
  withdrawPurchase: PropTypes.func.isRequired,
  addAdvertMessage: PropTypes.func.isRequired,
  sponsorship: PropTypes.object.isRequired,
  purchase: PropTypes.object.isRequired,
  getSponsorships: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  sponsorship: state.sponsorship,
  purchase: state.purchase,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getPurchaseForClients, updatePurchase, publishPurchases, participatePurchase, withdrawPurchase, addAdvertMessage, getSponsorships }
)(Purchases);