import {
    GET_NOTIFICATIONS,
    NOTIFICATION_ERROR,
    DELETE_NOTIFICATION,
    ADD_NOTIFICATION,
    GET_NOTIFICATION,
    NOTIFICATIONS_SUCCESS,
    UPDATE_NOTIFICATION,
    UPDATE_NOTIFICATION_ERROR,
  } from '../actions/types';
  
  const initialState = {
    notifications: [],
    notification: null,
    loading: true,
    error: {}
  };
  
  export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
      case GET_NOTIFICATIONS:
        return {
          ...state,
          notifications: payload,
          loading: false
        };
      case GET_NOTIFICATION:
        return {
          ...state,
          notification: payload,
          loading: false
        };
      case ADD_NOTIFICATION:
        return {
          ...state,
          notifications: [payload, ...state.notifications],
          loading: false
        };
      case DELETE_NOTIFICATION:
        return {
          ...state,
          notifications: state.notifications.filter(notification => notification._id !== payload),
          loading: false
        };
      case NOTIFICATION_ERROR:
        return {
          ...state,
          error: payload,
          loading: false
        };
        case UPDATE_NOTIFICATION:
                return {
                    ...state,
                    ...payload,
                    loading: false,
                    updated_notification: payload
                };
                case UPDATE_NOTIFICATION_ERROR:
                return {
                    ...state,
                    error: payload,
                    loading: false
                };

            case NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                ...payload,
                loading: false,
                notifications: payload
            };
      default:
        return state;
    }
  }
  