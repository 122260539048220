import React, { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { addDeal } from '../../actions/deal';
import DealsActions from './DealsActions';
import Spinner from '../layout/Spinner';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import { getSponsorships } from '../../actions/sponsorship';
import noImage from '../../img/no-image.png';
import pdfIcon from '../../img/pdf.png';
import DateButton from '../layout/DateButton';
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import ModalTitle from "react-bootstrap/ModalTitle";

import { useHistory } from 'react-router-dom';

 

const AddDeal = ({ getSponsorships, setAlert, addDeal, 
    auth: { user, loading }, sponsorship: { sponsorships }}) => {
  const [formData, setFormData] = useState({
    userId: user._id,
    presenter: user.username,
    title: '',
    description: '',
    initial_selling_price: '',
    negotiated_selling_price: '',
    further_information: '',
    city: '',
    postal_code: ''
  });

  let history = useHistory();

  const imgRefFirst = useRef(null);
  const imgRefSecond = useRef(null);
  const imgRefThird = useRef(null);

  useEffect(() => {
    getSponsorships(user._id, false, false, false);
  }, [getSponsorships, user._id]);

  const { t } = useTranslation();


  const { userId, presenter, title, description, initial_selling_price, negotiated_selling_price, /*offer_expiry_date, delivery_method,*/ further_information, city, postal_code } = formData;

  const [country, setCountry] = useState("");
  const [countryLabel, setCountryLabel] = useState();

  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [deliveryMethodLabel, setDeliveryMethodLabel] = useState();

  const [imgFirst, setImgFirst] = useState(noImage);
  const [imgSecond, setImgSecond] = useState(noImage);
  const [imgThird, setImgThird] = useState(noImage);
  const [imgToSendFirst, setImgToSendFirst] = useState(false);
  const [imgToSendSecond, setImgToSendSecond] = useState(false);
  const [imgToSendThird, setImgToSendThird] = useState(false);
  const [imgTemp, setImgTemp] = useState(false);



  const [modalsOpen, setModalIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalFirstButton, setModalFirstButton] = useState("");


  const thisDate = new Date()
  thisDate.setFullYear(thisDate.getFullYear())
  const [startDate, setStartDate] = useState(thisDate);
  const [startDateChanged, setStartDateChanged] = useState(false);

  if (user && user.status === "C") {
    return <Redirect to="/dashboard" />;
  }
  
const showModal = 
<Modal className="my-modal" show={modalsOpen} onHide={() => setModalIsOpen(false)} as="section">
<ModalHeader closeButton as="span">
  <ModalTitle as="h4">{modalTitle}</ModalTitle>
</ModalHeader>
<ModalBody as="section" 
 style={{
  whiteSpace: "pre-line"
 }}>{modalBody}

{imgTemp === "pdf" ? (<p>{t('Deals.Compress_pdf')} <a href={`https://shrinkpdf.com/${t('Language.Lang') === "Français" ? "fr" : t('Language.Lang') === "Italiano" ? "it" : "" }`} target="_blank"  rel="noopener noreferrer">{t('Deals.Here')}</a></p>)
: (imgTemp === "jpeg" || imgTemp === "jpg") ? (<p>{t('Deals.Compress_jpeg')} <a href={`https://compressjpeg.com/${t('Language.Lang') === "Français" ? "fr" : t('Language.Lang') === "Italiano" ? "it" : "" }`} target="_blank"  rel="noopener noreferrer">{t('Deals.Here')}</a></p>)
: imgTemp === "png" ? (<p>{t('Deals.Compress_png')} <a href={`https://compresspng.com/${t('Language.Lang') === "Français" ? "fr" : t('Language.Lang') === "Italiano" ? "it" : "" }`} target="_blank"  rel="noopener noreferrer">{t('Deals.Here')}</a></p>)
: ""}
</ModalBody>
<ModalFooter as="footer">


    {modalFirstButton !== "" ? 
<button className="btn btn-primary" onClick={() => setModalIsOpen(false)}>{modalFirstButton}</button> :
null
  }
</ModalFooter>
</Modal>



  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const onSubmit = async e => {
    var offer_expiry_date = startDate
    if (!startDateChanged) {
      offer_expiry_date = ""
    }
    e.preventDefault();
   /* if (!imgToSend) {
      setAlert('server_37_user', 'danger');
    } */
    /*if (!signature) {
      setAlert('server_38_user', 'danger');
    }*/
    addDeal({ userId, presenter, title, description, initial_selling_price, negotiated_selling_price, offer_expiry_date, "delivery_method": deliveryMethod, further_information, city, postal_code, country, imgToSendFirst, imgToSendSecond, imgToSendThird, history });

    window.scrollTo({top: 0, left: 0, behavior: 'smooth' });
  };

 /* function uploadFile ({ target: { files } }) {
    console.log(files[0])
    let data = new FormData();
    data.append('file', files[0])
  } */

  function selectCountry (val) {
    setCountry(JSON.parse(val).index);
    setCountryLabel(val);
  }

  const dropDownSelected = event => {
    if (JSON.parse(event).index) {
    selectCountry(event)
    }
  }

  function selectDeliveryMethod (val) {
    setDeliveryMethod(JSON.parse(val).index);
    setDeliveryMethodLabel(val);
  }

  const dropDownSelectedDeliveryMethod = event => {
    if (JSON.parse(event).index) {
    selectDeliveryMethod(event)
    }
  }

function handleAttachmentImgFirstChange  (changeEvent) {
  if (changeEvent.currentTarget.files[0]) {
  if (changeEvent.currentTarget.files[0].size > 300300) {
    setImgTemp(changeEvent.currentTarget.files[0].name.split('.').pop());
    setModalTitle(t('Deals.Warning'));
    setModalBody(t('Deals.File_too_large_0_3_MB') + "\n");
    setModalFirstButton(t('Deals.Ok'));
    setModalIsOpen(true);
    imgRefFirst.current.value = null;
  }
  else{
    if (changeEvent.currentTarget.files[0].name.split('.').pop() === "pdf") {
      setImgFirst(pdfIcon);
    }
    else {
      setImgFirst(URL.createObjectURL(changeEvent.currentTarget.files[0]));
    }
    setImgToSendFirst(changeEvent.currentTarget.files[0]);
  } 
}
}

function handleAttachmentImgSecondChange  (changeEvent) {
  if (changeEvent.currentTarget.files[0]) {
  if (imgToSendFirst && imgToSendSecond && changeEvent.currentTarget.files[0].size > 300300) {
    setImgTemp(changeEvent.currentTarget.files[0].name.split('.').pop());
    setModalTitle(t('Deals.Warning'));
    setModalBody(t('Deals.File_too_large_0_3_MB') + "\n");
    setModalFirstButton(t('Deals.Ok'));
    setModalIsOpen(true);
    imgRefSecond.current.value = null;
  }
  else{
  if (changeEvent.currentTarget.files[0].name.split('.').pop() === "pdf") {
    setImgSecond(pdfIcon);
  }
  else {
    setImgSecond(URL.createObjectURL(changeEvent.currentTarget.files[0]));
  }
  setImgToSendSecond(changeEvent.currentTarget.files[0]);
}
  }
}

function handleAttachmentImgThirdChange  (changeEvent) {
    if (changeEvent.currentTarget.files[0]) {
    if (changeEvent.currentTarget.files[0].size > 300300) {
      setImgTemp(changeEvent.currentTarget.files[0].name.split('.').pop());
      setModalTitle(t('Deals.Warning'));
      setModalBody(t('Deals.File_too_large_0_3_MB') + "\n");
      setModalFirstButton(t('Deals.Ok'));
      setModalIsOpen(true);
      imgRefThird.current.value = null;
    }
    else{
    if (changeEvent.currentTarget.files[0].name.split('.').pop() === "pdf") {
      setImgThird(pdfIcon);
    }
    else {
      setImgThird(URL.createObjectURL(changeEvent.currentTarget.files[0]));
    }
    setImgToSendThird(changeEvent.currentTarget.files[0]);
  }
    }
  }

function range(size, startAt) {
  return [...Array(size).keys()].map(i => startAt - i);
}


const maxThisDate = new Date()
maxThisDate.setFullYear(maxThisDate.getFullYear() + 1)
const minThisDate = new Date()
minThisDate.setFullYear(minThisDate.getFullYear())
minThisDate.getMonth(minThisDate.getMonth())


const years = range(2, thisDate.getYear() + 1901);
const months = [
  t('Sponsorship.January'),
  t('Sponsorship.February'),
  t('Sponsorship.March'),
  t('Sponsorship.April'),
  t('Sponsorship.May'),
  t('Sponsorship.June'),
  t('Sponsorship.July'),
  t('Sponsorship.August'),
  t('Sponsorship.September'),
  t('Sponsorship.October'),
  t('Sponsorship.November'),
  t('Sponsorship.December')
];

    return loading && user === null ? (
        <Spinner />
      ) : (

    <Fragment>

<DealsActions />
<br/><br/>
<div className="scrollme"> 
      <h1 className='large text-primary'>{t('Deals.Add_new_deal')}</h1>
      <p className='lead'>
        <i className='fas fa-user' /> {t('Deals.Create_new_deal')}
      </p>
      <form className='form' onSubmit={e => onSubmit(e)}>
      <p className="h1">I. {t('Deals.General_information')}</p>
        <div className='form-group'>
          <input
            type='text'
            readOnly = "readOnly"
            placeholder={t('Deals.Presenter')}
            name='presenter'
        //    value={username.replace(/\*/g, '')}
            value={presenter.replace(/[^a-zA-Z ]/g, '').trim()}
            onChange={e => onChange(e)}
          />
        </div>
        <div className='form-group'>
            <input
              type='text'
              placeholder={t('Deals.Title')+"*"}
              name='title'
              value={title}
              onPaste={e=>{
                e.preventDefault();
                return false
              }}
              onChange={e => onChange(e)}
            />
            </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder={t('Deals.Description')+"*"}
            name='description'
            value={description}
            onPaste={e=>{
              e.preventDefault();
              return false
            }}
            onChange={e => onChange(e)}
          />
          <small className='form-text'>
          </small>
          </div>
        <div className='form-group'>
            <input
              type='text'
              placeholder={t('Deals.Initial_selling_price')+"*"}
              name='initial_selling_price'
              value={initial_selling_price.replace(/[^0-9,.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(,.*?),/g, '$1').replace(/,/g, '.')}
              onPaste={e=>{
                e.preventDefault();
                return false
              }}
              onChange={e => onChange(e)}
            />
            </div>
        <div className='form-group'>
          <input
            type='text'
            placeholder={t('Deals.Negotiated_selling_price')+"*"}
            name='negotiated_selling_price'
            value={negotiated_selling_price.replace(/[^0-9,.]/g, '').replace(/(\..*?)\..*/g, '$1').replace(/(,.*?),/g, '$1').replace(/,/g, '.')}
            onPaste={e=>{
              e.preventDefault();
              return false
            }}
            onChange={e => onChange(e)}
          />
        </div>

        <div className='form-group'>
          <input
            type='text'
            placeholder={t('Deals.Further_information')}
            name='further_information'
            value={further_information.replace(/[^a-zA-Z ]/g, '')}
            onChange={e => onChange(e)}
          />
        </div>


        <div className='form-group' style= {{
   display: "flex",
   alignItems: "center" }}>

        <h4 style={{marginRight:"20px"}}>
    {t('Deals.Offer_expiry_date')} :
    </h4>
      <DatePicker
      formatWeekDay={nameOfDay => 
       (
        (t('Language.Lang') === "Français" || t('Language.Lang') === "Italiano") ?
        nameOfDay.slice(0,3) === "Sun" ?
        t('Register.Sunday').slice(0,3) :
        nameOfDay.slice(0,3) === "Mon" ?
        t('Register.Monday').slice(0,3) :
        nameOfDay.slice(0,3) === "Tue" ?
        t('Register.Tuesday').slice(0,3) :
        nameOfDay.slice(0,3) === "Wed" ?
        t('Register.Wednesday').slice(0,3) :
        nameOfDay.slice(0,3) === "Thu" ?
        t('Register.Thursday').slice(0,3) :
        nameOfDay.slice(0,3) === "Fri" ?
        t('Register.Friday').slice(0,3) :
        nameOfDay.slice(0,3) === "Sat" ?
        t('Register.Saturday').slice(0,3)
        : nameOfDay.slice(0,3) : nameOfDay.slice(0,3)
       ) 
       /* nameOfDay.substr(0,3) */}
      renderCustomHeader={({
        date,
        changeYear,
        changeMonth,
        decreaseMonth,
        increaseMonth,
        prevMonthButtonDisabled,
        nextMonthButtonDisabled
      }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center"
          }}
        >
          <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
            {"<"}
          </button>
          <select className="form-control"
            value={date.getYear() + 1900}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select className="form-control"
            value={months[date.getMonth()]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map(option => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
            {">"}
          </button>
        </div>
      )}
      selected={startDate}
      maxDate={maxThisDate}
      minDate={minThisDate}
      customInput={<DateButton />}
      onChange={date => {
        setStartDate(date);
        setStartDateChanged(true)
      }}
      
    />
    </div>
        <p className="h1">II. {t('Deals.Delivery')}</p>

        <div className='form-group'>
<select className="form-control" name="action" value={deliveryMethodLabel} onChange={e => dropDownSelectedDeliveryMethod(e.target.value)}>
<option value="">{t('Deals.Delivery_method')}</option>
             <option value= {'{"index": "Home_delivery"}'}>{t('Deals.Home_delivery')}</option>
             <option value= {'{"index": "Express_delivery"}'}>{t('Deals.Express_delivery')}</option>
             <option value= {'{"index": "Collection_in_store"}'}>{t('Deals.Collection_in_store')}</option>
             <option value= {'{"index": "Delivery_by_appointment"}'}>{t('Deals.Delivery_by_appointment')}</option>
           </select>
        </div>

        <div className='form-group'>
          <input
            type='text'
            placeholder={t('Deals.City')+"*"}
            name='city'
            value={city.replace(/[^a-zA-Z0-9 ]/g, '')}
            onChange={e => onChange(e)}
          />
        </div>

        <div className='form-group'>
          <input
            type='text'
            placeholder={t('Deals.Postal_code')+"*"}
            name='postal_code'
            value={postal_code.replace(/[^a-zA-Z0-9 ]/g, '')}
            onChange={e => onChange(e)}
          />
        </div>



    <div className='form-group'>



<select className="form-control" name="action" value={countryLabel} onChange={e => dropDownSelected(e.target.value)}>
<option value="">{t('Deals.Select_country')}</option>
             <option value= {'{"index": "BE"}'}>{t('Country.BE')}</option>
             <option value= {'{"index": "FR"}'}>{t('Country.FR')}</option>
             <option value= {'{"index": "IT"}'}>{t('Country.IT')}</option>
             <option value= {'{"index": "LU"}'}>{t('Country.LU')}</option>
             <option value= {'{"index": "CH"}'}>{t('Country.CH')}</option>
             <option value= {'{"index": "GB"}'}>{t('Country.GB')}</option>
           </select>
        </div>

        <p className="h1">III. {t('Deals.Product_picture')}</p>
        <div className="form-group" style={{
   display: "flex",
   alignItems: "center" }}>


 
      <img src={imgFirst} alt="" className="rounded-lg" style = {{ marginRight:"5px", display: 'block',
  maxWidth:'75px',
  maxHeight:'75px',
  width: 'auto',
  height: 'auto'}}/>
      <label htmlFor= "file_first" className="btn btn-secondary">{t('Deals.Choose_image') + " (1)"}</label>
          <input
           ref={imgRefFirst}
            type='file'
            id='file_first'
            name='file_first'
            accept='image/jpeg,image/jpg,image/png,application/pdf'
            style={{display: 'none'}}
            onChange={handleAttachmentImgFirstChange}
          />   

<p>
       {imgToSendFirst.name}
      </p>
          <br/>
<img src={imgSecond} alt="" className="rounded-lg" style = {{ marginRight:"5px", display: "block",
  maxWidth:'75px',
  maxHeight:'75px',
  width: 'auto',
  height: 'auto'}}/>
      <label htmlFor="file_second" className="btn btn-secondary">{t('Sponsorship.Choose_image') + " (2)"}</label>
          <input
           ref={imgRefSecond}
            type='file'
            id='file_second'
            name='file_second'
            accept='image/jpeg,image/jpg,image/png,application/pdf'
            style={{display: 'none'}}
            onChange={handleAttachmentImgSecondChange}
          /> 
          <p>
          {imgToSendSecond.name}
         </p>
          <br/>
         <img src={imgThird} alt="" className="rounded-lg" style = {{ marginRight:"5px", display: "block",
  maxWidth:'75px',
  maxHeight:'75px',
  width: 'auto',
  height: 'auto'}}/>
      <label htmlFor="file_third" className="btn btn-secondary">{t('Sponsorship.Choose_image') + " (3)"}</label>
          <input
           ref={imgRefThird}
            type='file'
            id='file_third'
            name='file_third'
            accept='image/jpeg,image/jpg,image/png,application/pdf'
            style={{display: 'none'}}
            onChange={handleAttachmentImgThirdChange}
          /> 
          <p>
          {imgToSendThird.name}
         </p>

    </div>
    
    <br/>
        <input type='submit' className='btn btn-block btn-primary' value={t('Sponsorship.Confirm')} />
      </form>

      {showModal}
      </div>
    </Fragment>
  );
};



      /*
        <CountryDropdown
          defaultOptionLabel= {t('Sponsorship.Select_country')}
          value={country}
          onChange={(val) => selectCountry(val)} />
           */

AddDeal.propTypes = {
  getSponsorships: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
  addDeal: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  sponsorship: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    sponsorship: state.sponsorship,
    getSponsorships: PropTypes.func.isRequired
});

export default connect(
  mapStateToProps,
  { getSponsorships, setAlert, addDeal }
)(AddDeal);

