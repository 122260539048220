import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PurchasesActions from './PurchasesActions';

const GcuPurchase = ({
  auth: { user }
}) => {

  const { t } = useTranslation();

  return (
    <Fragment>
    <PurchasesActions />
    <br/><br/>
         <section>
      <h1 className='large text-primary'>{t('GcuPurchase.Title')}</h1>
  <details>
    <summary>I. {t('GcuPurchase.Subtitle1')}</summary>
    <p>
    <strong>1. </strong>
      {t('GcuPurchase.Paragraph1')} < br/>
    <strong>2. </strong>
      {t('GcuPurchase.Paragraph2')} < br/>
    <strong>3. </strong>
      {t('GcuPurchase.Paragraph3')} < br/>
    <div style={{marginLeft : "2em"}}>
      a. {t('GcuPurchase.Paragraph4')} < br/>
      b. {t('GcuPurchase.Paragraph5')} < br/>
      c. {t('GcuPurchase.Paragraph6')} < br/>
    </div>
    <strong>4. </strong>
      {t('GcuPurchase.Paragraph7')} < br/>
    <strong>5. </strong>
      {t('GcuPurchase.Paragraph8')}
    </p>
  </details>

  <details>
    <summary>II. {t('GcuPurchase.Subtitle2')}</summary>
    <p>
    <strong>1. {t('GcuPurchase.Paragraph9')}</strong>< br/>
      {t('GcuPurchase.Paragraph10')} < br/>
      <strong>2. {t('GcuPurchase.Paragraph11')}</strong>< br/>
      {t('GcuPurchase.Paragraph12')} < br/>
    <strong>3. {t('GcuPurchase.Paragraph13')}</strong>< br/>
      {t('GcuPurchase.Paragraph14')}
    </p>
  </details>

  <details>
    <summary>III. {t('GcuPurchase.Subtitle3')}</summary>
    <p>
    <strong>1. </strong>
      {t('GcuPurchase.Paragraph15')} < br/>
    <strong>2. </strong>
      {t('GcuPurchase.Paragraph16')} < br/>
    <strong>3. </strong>
      {t('GcuPurchase.Paragraph17')} < br/>
    <strong>4. </strong>
      {t('GcuPurchase.Paragraph18')} < br/>
    <strong>5. </strong>
      {t('GcuPurchase.Paragraph19')}
    </p>
  </details>

  <details>
    <summary>IV. {t('GcuPurchase.Subtitle4')}</summary>
    <p>
    <strong>1. </strong>{t('GcuPurchase.Paragraph20')}<br />
    <div style={{marginLeft : "2em"}}>
      a. {t('GcuPurchase.Paragraph21')} < br/>
      b. {t('GcuPurchase.Paragraph22')}
    </div>
    <strong>2. </strong>
      {t('GcuPurchase.Paragraph23')} < br/>
    <strong>3. </strong>
      {t('GcuPurchase.Paragraph24')} < br/>
    <strong>4. </strong>
      {t('GcuPurchase.Paragraph25')} < br/>
    <strong>5. </strong>
      {t('GcuPurchase.Paragraph26')} < br/>
    <strong>6. </strong>
      {t('GcuPurchase.Paragraph27')} < br/>
    <strong>7. </strong>
      {t('GcuPurchase.Paragraph28')} < br/>
      {t('GcuPurchase.Paragraph29')}
    </p>
  </details>
  
  <details>
    <summary>V. {t('GcuPurchase.Subtitle5')}</summary>
    <p>
    <strong>1. </strong>{t('GcuPurchase.Paragraph30')}<br />
    <div style={{marginLeft : "2em"}}>
      a. {t('GcuPurchase.Paragraph31')} < br/>
      b. {t('GcuPurchase.Paragraph32')} < br/>
      c. {t('GcuPurchase.Paragraph33')} < br/>
      d. {t('GcuPurchase.Paragraph34')} < br/>
      e. {t('GcuPurchase.Paragraph35')}
    </div>
    <strong>2. </strong>{t('GcuPurchase.Paragraph36')}<br />
    <div style={{marginLeft : "2em"}}>
      a. {t('GcuPurchase.Paragraph37')} < br/>
      b. {t('GcuPurchase.Paragraph38')} < br/>
      c. {t('GcuPurchase.Paragraph39')} < br/>
      d. {t('GcuPurchase.Paragraph40')} < br/>
      e. {t('GcuPurchase.Paragraph41')}
    </div>
    <strong>3. </strong>{t('GcuPurchase.Paragraph42')} < br/>
    {t('GcuPurchase.Paragraph43')} < br/>
    <div style={{marginLeft : "2em"}}>
      a. {t('GcuPurchase.Paragraph44')} < br/>
      b. {t('GcuPurchase.Paragraph45')}
    </div>
    </p>
  </details>

  <details>
    <summary>VI. {t('GcuPurchase.Subtitle6')}</summary>
    <p>
    <strong>1. </strong>
      {t('GcuPurchase.Paragraph46')} < br/>
    <strong>2. </strong>
      {t('GcuPurchase.Paragraph47')} < br/>
    <strong>3. </strong>
      {t('GcuPurchase.Paragraph48')}
    </p>
  </details>

  <details>
    <summary>VII. {t('GcuPurchase.Subtitle7')}</summary>
    <p>
    {t('GcuPurchase.Paragraph49')} < br/>
    <strong>1. </strong>
      {t('GcuPurchase.Paragraph50')} < br/>
    <strong>2. </strong>
      {t('GcuPurchase.Paragraph51')} < br/>
    <strong>3. </strong>
      {t('GcuPurchase.Paragraph52')} < br/>
    <strong>4. </strong>
      {t('GcuPurchase.Paragraph53')}
    </p>
  </details>

  <details>
  <summary>
    </summary>
  </details>
</section>
    </Fragment>
  );
};

GcuPurchase.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(GcuPurchase);
