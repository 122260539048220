import React, { Fragment/*, useState*/ } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Redirect } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

const InteractiveForecast = ({
  auth: { user }
}) => {

  const { t } = useTranslation();
  let history = useHistory();
  if (user && (user.status === "C" || user.status === "A")) {
    return <Redirect to="/dashboard" />;
  }

  return (
    <Fragment>
            <section>
      <h1 className='large text-primary'>{t('InteractiveForecast.Title')}</h1>
      <p>
    {t('InteractiveForecast.Paragraph1')}<br />
    </p>
      <p>
    {t('InteractiveForecast.Paragraph2')}<br />
    </p>
      <p>
    {t('InteractiveForecast.Paragraph3')}<br />{t('InteractiveForecast.Paragraph4')}<br /><br />
    </p>
    <div className="d-flex justify-content-center">
    <button type="button" className="btn btn-primary btn-block" onClick={() => history.push({
      pathname: '/demo/affiliations'
    })}>{t('InteractiveForecast.Continue')}</button>
    </div>
</section>
    </Fragment>
  );
};

InteractiveForecast.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  {  }
)(InteractiveForecast);
